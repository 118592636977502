import { React, useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import AuthLayout from "./Layouts/AuthLayout";
import authContext from "./Contexts/authContext";

export default function ProtectedRoutes() {
    let { isAuthorized } = useContext(authContext);
    if (isAuthorized === 'authorized') {
        return (
            <>
                <Outlet/>
            </>);
    } else if (isAuthorized === 'not-authorized') {
        return (
            <>
                <Navigate to="/login" replace />
                <AuthLayout />
            </>);
    } else {
        return <>
        </>
    }

}