import { https } from './https';
import { admin_url } from './../Configuration/config';

//get services list
export async function getServices(page){
    return await https.get(`${admin_url}/services?page=${page}`);
}
//add new services 
export async function addService(data){
    return await https.post(`${admin_url}/services`,data);
}
//get single Departments 
export async function getSingleService(slug){
    return await https.get(`${admin_url}/services/${slug}`);
}
//add new services 
export async function updateService(id,data){
    return await https.post(`${admin_url}/services/${id}?_method=PUT`,data);
}