import * as React from 'react';
import ListTable from '../../Components/listPages/list';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { getClinics } from '../../Api/clinics';

const columns = [
  { id: 'image', label: 'الصورة', minWidth: 170 },
  { id: 'name_ar', label: 'الاسم', minWidth: 170 },
  {
    id: 'desc_ar',
    label: 'الوصف',
   
  },
  {
    id: 'status',
    label: 'الحالة',
   
  },
  {
    id: 'rank',
    label: 'الترتيب',
   
  },
  {
    id: 'dept_id',
    label: 'الادارة',
   
  },
  {
    id: 'slug',
  },
  {id:'id'}

];

function createData(image, name_ar, desc_ar, status, rank,dept_id,slug,id) {
  return { image, name_ar, desc_ar, status, rank,dept_id,slug,id };
}



export default function ClinicsList() {
  let [rows, setRows] = React.useState([])
  let editLink='/dashboard/clinics'
  let [itemLength, setitemLength] = React.useState()
  let deleteLink='clinics'
  
  function getallClinics(page){
    let clinicList = []
    getClinics(page).then((res) => {
      let clinicss = res.data.data
      setitemLength(res.data.links.totalObjects)
      clinicss.map((x) => {
        clinicList.push(createData(x.image, x.name_ar, x.desc_ar, x.status, x.rank, x.dept_id, x.slug,x.id))
        return ''
      })
      setRows(clinicList)
    }).catch((err) => {
      console.log(err);
    })
  }
  React.useEffect(() => {
    getallClinics(1)
    //eslint-disable-next-line
  }, [])

  return (
    <>
      <div className='flex justify-between	'>
        <h1 className='pagesTitle'>العيادات</h1>
        <Link to='/dashboard/clinics/add' className='addBtn'>
          <span>اضافة عيادة</span>
          <PlusCircleIcon className='navBtn' />
        </Link>
      </div>
      <div className='tableContainer'>
        {rows && rows.length > 0 &&
          <ListTable rows={rows} setRows={setRows} columns={columns} getItems={getallClinics} itemLength={itemLength} editLink={editLink} deleteLink={deleteLink}/>
        }
      </div>
    </>
  );
}
