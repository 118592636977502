import * as React from 'react';
import ListTable from '../../Components/listPages/list';
import { getCategories } from '../../Api/categories';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';

const columns = [
  { id: 'image', label: 'الصورة', minWidth: 170 },
  { id: 'name_ar', label: 'الاسم', minWidth: 170 },
  {
    id: 'status',
    label: 'الحالة',
    minWidth: 170,
  },
  {
    id: 'type',
    label: 'النوع',
    minWidth: 170,
  },
  {
    id: 'rank',
    label: 'الترتيب',
    minWidth: 170,
  },
  {
    id: 'slug',
    minWidth: 170,
  },
  {id:'id'}

];

function createData(image,name_ar, status, type, rank,slug,id) {
  return { image,name_ar, status, type, rank,slug,id };
}



export default function CategoriesList() {
  let [rows,setRows]=React.useState([])
  let editLink='/dashboard/categories'
  let [itemLength, setitemLength] = React.useState()
  let deleteLink='categories'
  
  function getAllCategories(page){
    let categoryList=[]
    getCategories(page).then((res) => {
      let cats=res.data.data
      setitemLength(res.data.links.totalObjects)
      cats.map((x)=>{
        // console.log(x);
        // console.log(x.name_ar);
        categoryList.push(createData(x.image,x.name_ar,x.status,x.type,x.rank,x.slug,x.id))
        return ''
      })
      setRows(categoryList)
    }).catch((err) => {
      console.log(err);
    })
  }

  React.useEffect(() => {
    getAllCategories(1)
    //eslint-disable-next-line
  }, [])

  return (
    <>
      <div className='flex justify-between	'>
        <h1 className='pagesTitle'>الاقسام</h1>
        <Link to='/dashboard/categories/add' className='addBtn'>
          <span>اضافة قسم</span>
          <PlusCircleIcon className='navBtn' />
        </Link>
      </div>
      <div className='tableContainer'>
        {rows && rows.length > 0 &&
        <ListTable rows={rows} setRows={setRows}  columns={columns} getItems={getAllCategories} editLink={editLink} deleteLink={deleteLink} itemLength={itemLength}/>
}
      </div>
    </>
  );
}
