import React from 'react';
import { Fragment } from 'react';
import { Dialog, Transition, Disclosure } from '@headlessui/react';
import logo from '../../Assets/images/logo-white.png';
import {
    Cog6ToothIcon,
    DocumentDuplicateIcon,
    HomeIcon,
    UsersIcon,
    XMarkIcon,
    WrenchScrewdriverIcon
} from '@heroicons/react/24/outline';
import { BiClinic } from 'react-icons/bi'
import { BiSolidOffer ,BiCategory} from 'react-icons/bi'
import { RiSurgicalMaskLine, RiPagesLine } from 'react-icons/ri'
import { TbMessageDots } from 'react-icons/tb'
import { BsImages } from 'react-icons/bs'
import { HiOutlineClipboardDocumentList, HiOutlineDocumentText, HiOutlineNewspaper } from 'react-icons/hi2'
import { FaUserNurse,FaHandshake } from 'react-icons/fa'
import { MdOutlineSchedule } from 'react-icons/md'
import { NavLink } from 'react-router-dom';
import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid'


const navigation = [
    { name: 'لوحة التحكم', href: '/dashboard/overview', icon: HomeIcon, current: false },
    { name: 'التصنيفات', href: '/dashboard/categories', icon: BiCategory, current: false },
    { name: 'الأقسام', href: '/dashboard/departments', icon: DocumentDuplicateIcon, current: false },
    { name: 'العيادات', href: '/dashboard/clinics', icon: BiClinic, current: false },
    { name: 'العمليات', href: '/dashboard/surgeries', icon: RiSurgicalMaskLine, current: false },
    { name: 'الدكاترة', href: '/dashboard/doctors', icon: FaUserNurse, current: false },
    { name: 'مواعيد الدكاترة', href: '/dashboard/appointments', icon: MdOutlineSchedule, current: false },
    { name: 'العروض', href: '/dashboard/offers', icon: BiSolidOffer, current: false },
    { name: 'الحجوزات', href: '/dashboard/reservations', icon: HiOutlineClipboardDocumentList, current: false },
    { name: 'الخدمات', href: '/dashboard/services', icon: WrenchScrewdriverIcon, current: false },
    { name: 'التواصل', href: '/dashboard/contacts', icon: TbMessageDots, current: false },
    { name: 'الشركاء', href: '/dashboard/partners', icon: FaHandshake, current: false },
    { name: 'الزيارات المنزلية', href: '/dashboard/home-visits', icon: UsersIcon, current: false },
    { name: 'المقالات', href: '/dashboard/blogs', icon: HiOutlineNewspaper, current: false },
    { name: 'الاخبار', href: '/dashboard/news', icon: HiOutlineDocumentText, current: false },
    { name: 'الوسائط', href: '/dashboard/media', icon: BsImages, current: false },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
};

function dropdown() {
    return <li>
        <Disclosure as="div" className=" p-2 text-sm leading-6  ">
            {({ open }) => (
                <>
                    <h3 className=" flow-root">
                        <Disclosure.Button className="group flex  text-indigo-200 font-semibold  gap-x-3 rounded-md text-sm leading-6">
                            <RiPagesLine className='text-indigo-200 flex  group-hover:text-white h-6 w-6 shrink-0' />
                            الصفحات
                            <div className="mr-10 flex items-center">
                                {open ? (
                                    <MinusIcon className="h-5 w-5" aria-hidden="true" />
                                ) : (
                                    <PlusIcon className="h-5 w-5" aria-hidden="true" />
                                )}
                            </div>
                        </Disclosure.Button>
                    </h3>
                    <Disclosure.Panel className="pt-3">
                        <div className="space-y-1">
                            <NavLink
                                to='/dashboard/home'
                                className='text-indigo-200  group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold navLink'

                            >
                                الرئيسية
                            </NavLink>
                            <NavLink
                                to='/dashboard/pages/terms-and-conditions'
                                className='text-indigo-200  group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold navLink'

                            >
                                الشروط و الاحكام
                            </NavLink>
                            <NavLink
                                to='/dashboard/pages/privacy-policy'
                                className='text-indigo-200  group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold navLink'

                            >
                                سياسة الخصوصية
                            </NavLink>
                            <NavLink
                                to='/dashboard/pages/about-us'
                                className='text-indigo-200  group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold navLink'

                            >
                                عن المجمع
                            </NavLink>
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    </li>
}
export default function Sidebar({ sidebarOpen, setSidebarOpen }) {
    return (
        <div>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-900/80" />
                    </Transition.Child>

                    <div className="fixed inset-0 flex ">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative sideBar ml-16 flex w-full max-w-xs flex-1">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute right-full top-0 flex w-16 justify-center pt-5">
                                        <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                                            <span className="sr-only">Close sidebar</span>
                                            <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                        </button>
                                    </div>
                                </Transition.Child>
                                {/* Sidebar component, swap this element with another sidebar if you like */}
                                <div className="flex grow flex-col gap-y-5 overflow-y-auto sideBar px-6 pb-4">
                                    <div className="flex h-16 shrink-0 items-center">
                                        <img
                                            className="h-16 w-auto"
                                            src={logo}
                                            alt="Your Company"
                                        />
                                    </div>
                                    <nav className="flex flex-1 flex-col">
                                        <ul className="flex flex-1 flex-col gap-y-7">
                                            <li>
                                                <ul className="-mx-2 space-y-1">
                                                    {navigation.map((item) => (
                                                        <li key={item.name}>
                                                            <NavLink
                                                                to={item.href}
                                                                className={classNames(
                                                                    item.current
                                                                        ? 'bg-indigo-500 text-white'
                                                                        : 'text-indigo-200 ',
                                                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold navLink'
                                                                )}
                                                            >
                                                                <item.icon
                                                                    className={classNames(
                                                                        item.current
                                                                            ? 'text-white'
                                                                            : 'text-indigo-200 group-hover:text-white',
                                                                        'h-6 w-6 shrink-0'
                                                                    )}
                                                                    aria-hidden="true"
                                                                />
                                                                {item.name}
                                                            </NavLink>
                                                        </li>
                                                    ))}
                                                    {dropdown()}
                                                </ul>
                                            </li>
                                            <li className="mt-auto">
                                                <NavLink
                                                    to='/dashboard/settings'
                                                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-indigo-200 hover:bg-indigo-500 navLink hover:text-white"
                                                >
                                                    <Cog6ToothIcon
                                                        className="h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white"
                                                        aria-hidden="true"
                                                    />
                                                    اعدادات الموقع
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto  px-6 pb-4 sideBar">
                    <div className="flex h-16 shrink-0 items-center">
                        <img className="h-16 w-auto" src={logo} alt="Your Company" />
                    </div>
                    <nav className="flex flex-1 flex-col">
                        <ul className="flex flex-1 flex-col gap-y-7">
                            <li>
                                <ul className="-mx-2 space-y-1">
                                    {navigation.map((item) => (
                                        <li key={item.name}>
                                            <NavLink
                                                to={item.href}
                                                className={classNames(
                                                    item.current
                                                        ? ' text-white'
                                                        : 'text-indigo-200 ',
                                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold navLink'
                                                )}
                                            >
                                                <item.icon
                                                    className={classNames(
                                                        item.current ? 'text-white' : 'text-indigo-200 group-hover:text-white',
                                                        'h-6 w-6 shrink-0'
                                                    )}
                                                    aria-hidden="true"
                                                />
                                                {item.name}
                                            </NavLink>
                                        </li>
                                    ))}
                                  {dropdown()}
                                </ul>
                            </li>

                            <li className="mt-auto">
                                <NavLink
                                    to='/dashboard/settings'
                                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-indigo-200 hover:bg-indigo-500 navLink hover:text-white"
                                >
                                    <Cog6ToothIcon
                                        className="h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white"
                                        aria-hidden="true"
                                    />
                                    اعدادات الموقع
                                </NavLink>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    )
}
