import { https } from './https';
import { admin_url } from './../Configuration/config';

//get blogs list
export async function getBlogs(){
    return await https.get(`${admin_url}/blogs`);
}
//add new blogs 
export async function addBlogs(data){
    return await https.post(`${admin_url}/blogs`,data);
}
//get single Blogs 
export async function getSingleBlog(slug){
    return await https.get(`${admin_url}/blogs/${slug}`);
}
//add new blogs 
export async function updateBlog(id,data){
    return await https.post(`${admin_url}/blogs/${id}?_method=PUT`,data);
}