import React from 'react'
import Form from './Form';
import { addReservation } from '../../../Api/reservations';

export default function AddNewReservation() {
    const onSubmit = async (e, data) => {
        e.preventDefault();
        //to send request
        return await addReservation(data).then(res => {
            return 'done';          
        }).catch(err =>{
            return err.response.data.data;
        })
    };
  return (
    <div ><Form title='اضافة حجز' onSubmit={onSubmit}/></div>
  )
}
