// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sideBar {
  background-color: #224086;
}
.sideBar .navLink.active, .sideBar .navLink:hover {
  background-color: rgba(255, 255, 255, 0.46);
  color: #fff;
}
.sideBar .navLink.active svg, .sideBar .navLink:hover svg {
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/styles/Layouts/dashboard.scss","webpack://./src/styles/abstracts/variables.scss"],"names":[],"mappings":"AAEA;EACI,yBCFW;ADCf;AAIQ;EACI,2CAAA;EACA,WAAA;AAFZ;AAGY;EACI,WAAA;AADhB","sourcesContent":["@import '../abstracts/variables.scss';\n\n.sideBar{\n    background-color: $primaryColor;\n    .navLink{\n\n        &.active,  &:hover{\n            background-color: rgb(255 255 255 / 46%);\n            color: #fff;\n            svg{\n                color: #fff;\n            }\n        }\n      \n    }\n}","$BgColor: #F7F9FF;\n$primaryColor: #224086;\n$redColor: #CE2030;\n$grey:#B8B8B8;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
