import { https } from './https';
import { admin_url } from './../Configuration/config';

//get Single Seo
export async function getSingleSeo(type,typeObj){
    return await https.get(`${admin_url}/seo/single/${type}/${typeObj}`);
}
//update seo
export async function updateSingleSeo(data){
    return await https.post(`${admin_url}/seo/update?_method=PUT`,data);
}