import React, { useEffect, useState } from 'react'
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertContentToHTML } from '../../helpers/convertTohtml';
import { onChangeHandler } from '../../helpers/handleChange';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { PhotoIcon } from '@heroicons/react/24/solid'
import { XCircleIcon } from '@heroicons/react/24/solid'
import { getSingleBlog } from '../../Api/blogs';
import Seo from '../seo/seo';
import { CgSpinner } from 'react-icons/cg';

export default function BlogNewsForm({ itemId, blog, setblog, title, confirmHandler, errors, setErrors,loading }) {
    let [editorStateAr, setEditorStateAr] = useState(EditorState.createEmpty())
    let [editorStateEn, setEditorStateEn] = useState(EditorState.createEmpty())
    let [uploadedImg, setuploadedImg] = useState([])

    const [images, setImages] = useState([]);

    useEffect(() => {
        if (itemId) {
            getSingleBlog(itemId).then((res) => {
                let x = res.data.data
                console.log(x);
                let blog = { ...x }
                setImages(blog.images)
                blog.image = ''
                if (x.type === 'News') {
                    blog.type = 1
                } else {
                    blog.type = 2
                }
                console.log(blog);
                const qualifiesState = ContentState.createFromBlockArray(convertFromHTML(`${x.desc_ar}`));
                const qualifiesEditorState = EditorState.createWithContent(qualifiesState);
                setEditorStateAr(qualifiesEditorState)
                const studiesState = ContentState.createFromBlockArray(convertFromHTML(`${x.desc_en}`));
                const studiesEditorState = EditorState.createWithContent(studiesState);
                setEditorStateEn(studiesEditorState)
                setblog(blog)
            }).catch((err) => {
                console.log(err);
            })
        }
        //eslint-disable-next-line
    }, [itemId])

    function onEditorChange(x, type) {
        let blogClone = { ...blog }
        if (type === 'ar') {
            blogClone['desc_ar'] = convertContentToHTML(x)
            setEditorStateAr(x)
        } else {
            blogClone['desc_en'] = convertContentToHTML(x)
            setEditorStateEn(x)
        }
        setblog(blogClone)
        console.log(blogClone);
    }
    function removeImg(i) {
        console.log(i);
        const newImages = [...images];
        newImages.splice(i, 1);
        setImages(newImages);

        let imgs = [...uploadedImg]
        imgs.splice(i, 1)
        setuploadedImg(imgs)

        let blogClone = { ...blog }
        blogClone['images[]'] = imgs
        console.log(blogClone);
        setblog(blogClone)
        
        // let err = { ...errors }
        // err[`images[].${i}`] = ''
        // console.log(err);
        // setErrors(err)
    }

    const handleImageUpload = (event) => {
        let imgs = [...uploadedImg]
        const file = event.target.files[0];
        console.log(file)
        let imgsArr = imgs.concat(file);
        setuploadedImg(imgsArr);
        console.log(images)

        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        
        fileReader.onload = (event) => {
            setImages([...images, event.target.result]);
        };


        let blogClone = { ...blog }
        blogClone['images[]'] = imgsArr
        setblog(blogClone)
    };

    return (
        // onSubmit={(e) => confirmHandler(e, blog, props, setErrors, navigate)}
        <div>
            <form onSubmit={(e) => confirmHandler(e)}>
                <div className="space-y-12">
                    <h2 className="formTitle">{title} </h2>
                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-2">
                            <label htmlFor="title_ar" className="block text-sm font-medium leading-6 text-gray-900">
                                الاسم (العربي)
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, blog, setblog, errors, setErrors)}
                                    
                                    type="text"
                                    name="title_ar"
                                    id="title_ar"
                                    autoComplete="given-name"
                                    value={blog.title_ar}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.title_ar}</div>
                        </div>

                        <div className="sm:col-span-2">
                            <label htmlFor="title_en" className="block text-sm font-medium leading-6 text-gray-900">
                                الاسم (الانجليزية)
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, blog, setblog, errors, setErrors)}
                                    
                                    type="text"
                                    name="title_en"
                                    id="title_en"
                                    autoComplete="family-name"
                                    value={blog.title_en}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.title_en}</div>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="slug" className="block text-sm font-medium leading-6 text-gray-900">
                                الرابط
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, blog, setblog, errors, setErrors)}
                                    required
                                    id="slug"
                                    name="slug"
                                    type="slug"
                                    value={blog.slug}
                                    className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.slug}</div>
                        </div>
                        <div className="col-span-full">
                            <label htmlFor="desc_ar" className="block text-sm font-medium leading-6 text-gray-900">
                                الوصف (عربي)
                            </label>
                            <div className="mt-2">
                                <Editor
                                    editorState={editorStateAr}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={(x) => onEditorChange(x, 'ar')}
                                />
                            </div>
                            <div className='errorText'>{errors?.desc_ar}</div>
                        </div>
                        <div className="col-span-full">
                            <label htmlFor="desc_en" className="block text-sm font-medium leading-6 text-gray-900">
                                الوصف (الانجليزية)
                            </label>
                            <div className="mt-2">
                                <Editor
                                    editorState={editorStateEn}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={(x) => onEditorChange(x, 'en')}
                                />
                            </div>
                            <div className='errorText'>{errors?.desc_en}</div>
                        </div>
                        <div className="sm:col-span-1">
                            <label htmlFor="rank" className="block text-sm font-medium leading-6 text-gray-900">
                                الترتيب
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, blog, setblog, errors, setErrors, errors, setErrors)}
                                    id="rank"
                                    name="rank"
                                    type="number"
                                    value={blog.rank}
                                    className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                            </div>
                            <div className='errorText	'>{errors?.rank}</div>

                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="rank" className="block text-sm font-medium leading-6 text-gray-900">
                                الصفحة الرئيسية
                            </label>
                            <FormControlLabel control={<Switch name='featured' checked={blog?.featured === 1 ? true : false} onChange={(e) => onChangeHandler(e, blog, setblog, errors, setErrors, errors, setErrors)} />} label="متميز" />
                            <div className='errorText	'>{errors?.featured}</div>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="rank" className="block text-sm font-medium leading-6 text-gray-900">
                                الحالة
                            </label>
                            <FormControlLabel control={<Switch name='status' checked={blog?.status === 1 ? true : false} onChange={(e) => onChangeHandler(e, blog, setblog, errors, setErrors, errors, setErrors)} />} label="فعال" />
                            <div className='errorText	'>{errors?.status}</div>
                        </div>
                        <div className='sm:col-span-1'>
                            <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                                الصورة
                            </label>

                            <div className=' flex'>

                                <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10 test">
                                    <div className="text-center">
                                        <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                                        <div className="mt-4 flex justify-center text-sm leading-6 text-gray-600">
                                            <label
                                                htmlFor="image"
                                                className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                            >
                                                <span>حمل الصورة</span>
                                                {/* <input id="image_1" name="image_1" type="file" className="sr-only"
                                                 onChange={(e) => onChangeHandler(e, blog, setblog, errors, setErrors, setImg)}
                                             /> */}
                                                <input id="image" type="file" className="sr-only" multiple onChange={handleImageUpload} />
                                            </label>
                                        </div>
                                        <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
                                    </div>
                                </div>
                                {images && images.length ? images.map((image, index) => (
                                 <div>
                                       <div key={index} className="mt-2 test uploadedImg flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                           <XCircleIcon onClick={() => removeImg(index)} />
                                           <img src={image} alt='' />
                                       </div>
                                    
                                       {/* <div className='errorText'>{errors.images && errors[`images.${index}`]}</div> */}
                                 </div>
                                )) : ''}

                            </div>

                        </div>
                    </div>

                </div>
                <div className="mt-6 flex items-center justify-end ">
                {!loading ?
                        <button type="submit" className="submitBtn">
                            حفظ
                        </button>
                        :
                        <button type="button" className="submitBtn flex" disabled>
                            <CgSpinner className='animate-spin' />
                            جاري الحفظ
                        </button>
                    }
                </div>
            </form>
            {itemId && blog && blog.id ?
                <Seo objectType={6} objectId={blog?.id} />
                : ''}
        </div>
    )
}
