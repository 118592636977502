import * as React from 'react';
import ListTable from '../../Components/listPages/list';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { getPartners } from '../../Api/partners';

const columns = [
  { id: 'logo', label: 'الصورة', minWidth: 170 },
  { id: 'name_ar', label: 'الاسم', minWidth: 170 },
  { id: 'website', label: 'الموقع الالكتروني', minWidth: 170 },
  {id: 'slug'},
  {id:'id'}

];

function createData(logo, name_ar,website,id) {
  return { logo, name_ar,website,id };
}



export default function PartnersList() {
  let [rows, setRows] = React.useState([])
  let [itemLength, setitemLength] = React.useState([])
  let editLink='/dashboard/partners'
  let deleteLink='partners'
  function getallPartners(page){
    let itemList = []
    getPartners(page).then((res) => {
      let items = res.data.data
      setitemLength(res.data.links.totalObjects)
      items.map((x) => {
        itemList.push(createData(x.logo, x.name_ar,x.website, x.id))
        return ''
      })
      setRows(itemList)
    }).catch((err) => {
      console.log(err);
    })
  }
  React.useEffect(() => {
    getallPartners(1)
    //eslint-disable-next-line
  }, [])

  return (
    <>
      <div className='flex justify-between	'>
        <h1 className='pagesTitle'>الشركاء</h1>
        <Link to='/dashboard/partners/add' className='addBtn'>
          <span>اضافة شريك</span>
          <PlusCircleIcon className='navBtn' />
        </Link>
      </div>
      <div className='tableContainer'>
        {rows && rows.length > 0 &&
          <ListTable rows={rows} setRows={setRows} columns={columns} editLink={editLink} deleteLink={deleteLink} getItems={getallPartners} itemLength={itemLength}/>
        }
      </div>
    </>
  );
}
