import logo from '../../Assets/images/logo.png';
import openEye from '../../Assets/icons/closeeye.svg';
import closedEye from '../../Assets/icons/openeye.svg';
import TextField from '@mui/material/TextField';
import { Alert, Stack } from '@mui/material';
import authContext from '../../Contexts/authContext';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { adminLogin } from '../../Api/auth';
import { https } from '../../Api/https';
import { CgSpinner } from 'react-icons/cg';

export default function Login() {
    let { setAuthorization,setauthUser } = useContext(authContext);
    let navigate = useNavigate();
    // console.log(isAuthorized);
    let [user, setUser] = useState({})
    let [passType, setpassType] = useState('password')
    let [error, setError] = useState()
    let [loading, setloading] = useState()
    function changeHandler(e) {
        let userClone = { ...user }
        userClone[e.target.name] = e.target.value;
        setError('')
        setUser(userClone)
    }
    function showPasswordHandler() {
        if (passType === 'password') {
            setpassType('text')
        } else {
            setpassType('password')
        }
    }

    function login(e) {
        e.preventDefault()
        setloading(true)
        adminLogin(user).then((res) => {
            setloading(false)
            setauthUser(res.data.data)
            let token = `Bearer ${res.data.data.accessToken}`
            localStorage.setItem('apc_admin_token', res.data.data.accessToken)
            https.defaults.headers.Authorization=token
            navigate(`/dashboard/overview`);
            setAuthorization('authorized')
        }).catch((err) => {
            setloading(false)
            setError(err.response.data.message)
        })
    }


    return (
        <div className='formSection'>
            <img className='loginLogo' src={logo} alt='' />
            {error &&
            <Alert severity="error" className='w-full mb-5'>{error}</Alert>
            }
            <h2>تسجيل الدخول</h2>
            <form className='w-full' onSubmit={login}>
                <Stack>
                    <TextField
                        label="البريد الالكتروني"
                        defaultValue="hdhdhd"
                        mt={3}
                        onChange={changeHandler}
                        type='email'
                        name='email'
                    />
                    <div className='passwordContainer'>
                        <TextField  className='w-full' label="كلمة المرور " type={passType} name='password' onChange={changeHandler}/>
                        <span
                            onClick={showPasswordHandler}
                            className={`eyeIcon`}
                        >
                            {passType === "password" ? (
                                <img src={openEye} alt='eye icon' />
                            )
                                :
                                (
                                    <img src={closedEye} alt="eye icon" />
                                )
                            }
                        </span>
                    </div>
                    <div></div>
                    {!loading ?
                         <button className='mainBtns' >دخول</button>
                        :
                        <button type="button" class="mainBtns flex" disabled>
                            <CgSpinner className='animate-spin' />
                            جاري الدخول
                        </button>
                    }
                </Stack>
            </form>
        </div>
    )
}