import React from 'react'
import Form from './Form'
import { useParams } from 'react-router-dom';
import { updateAppointments } from '../../../Api/appointments';

export default function EditAppointment() {
    const { id } = useParams();
    // console.log(id);
    const onSubmit = async (e, id,data) => {
        e.preventDefault();
        //to send request
        return await updateAppointments(id,data).then(res => {
            // props.getAll();
            return 'done';          
        }).catch(err =>{
            console.log(err)
            return err.response.data.data;
        })
    };
  return (
    <div ><Form title='تعديل الموعد' itemId={id} onSubmit={onSubmit}/></div>
  )
}
