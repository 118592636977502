import React, { useEffect, useState } from 'react'
import { onChangeHandler } from '../../helpers/handleChange'
import { getSettings, updateSettings } from '../../Api/pages'
import { useJsApiLoader, GoogleMap, MarkerF, Autocomplete } from '@react-google-maps/api';
import { mapKey, libraries } from '../../Configuration/config';
import { CgSpinner } from 'react-icons/cg';
import { PhotoIcon } from '@heroicons/react/24/solid'
import { XCircleIcon } from '@heroicons/react/24/solid'
import Swal from 'sweetalert2';
export default function Settings() {
    let [obj, setobj] = useState({
        site_name_ar: "",
        site_name_en: '',
        email: "",
        phone: '',
        address: '',
        fax: "",
        copyrights: ""

    })
    let [errors, setErrors] = useState()
    let [loading, setloading] = useState()
    let [arImage, setarImage] = useState()
    let [enImage, setenImage] = useState()
    let [favicon, setfavicon] = useState()
    let [coords, setCoords] = useState({ lat: 1.33, lng: 344 });
    const [map, setMap] = useState(null)
    let [autocomplete, setAutocomplete] = useState(null);
    let [placeholderImg, setplaceholderImg] = useState(null);

    useEffect(() => {
        getSettings().then((res) => {
            let data = res.data.data
            setarImage(data?.site_logo_ar)
            setenImage(data?.site_logo_en)
            setfavicon(data?.site_icon)
            setplaceholderImg(data?.placeholder)
            setCoords({ lat: Number(data?.latitude), lng: Number(data?.longitude) })
            delete data?.site_logo_en
            delete data?.site_logo_ar
            delete data?.site_icon
            delete data?.placeholder
            setobj(data)
        })
    }, [])
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: mapKey,
        libraries: libraries,
    })

    function onLoad(autocomplete) {
        setAutocomplete(autocomplete);
    }


    // const onLoad = React.useCallback(function callback(map) {
    //     const bounds = new window.google.maps.LatLngBounds(coords);
    //     setMap(map)
    //     //eslint-disable-next-line
    // }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])
    const containerStyle = {
        width: "100%",
        height: "100%",
        borderRadius: 'inherit'
    };
    function onMarkDragged(e) {
        let newCoords = { ...coords };
        newCoords = {
            lng: e.latLng.lng(),
            lat: e.latLng.lat()
        }
        setCoords(newCoords);
    }

    function onPlaceChanged() {
        if (autocomplete !== null) {
            let newCoords = { ...coords };
            if (autocomplete.getPlace().geometry) {
                newCoords = {
                    lng: autocomplete.getPlace().geometry.location.lng(),
                    lat: autocomplete.getPlace().geometry.location.lat()
                }
            }
            setCoords(newCoords);
        }
    }
    function removeImg(type) {

        if (type === 'site_logo_ar') {
            setarImage('')
        } else if (type === 'site_logo_en') {
            setenImage('')
        } else if (type === 'site_icon') {
            setfavicon('')
        } else if (type === 'placeholder') {
            setplaceholderImg('')
        }
        let thisObj = { ...obj }
        thisObj[type] = ''
        setobj(thisObj)
    }

    function confirmHandler(e) {
        e.preventDefault();
        setloading(true)
        let x = { ...obj }
        x.latitude = coords?.lat.toString();
        x.longitude = coords.lng.toString();
        let formData = new FormData()
        for (const key in x) {
            formData.append(key, x[key]);
        }
     
        // for (var pair of formData.entries()) {
        //     console.log(pair[0] + ', ' + pair[1]);
        // }
    
        updateSettings(formData).then((res) => {
            setloading(false)
          
            Swal.fire({
                icon: 'success',
                title:  'تم التعديل بنجاح',
                showConfirmButton:false,
                timer: 1500
              })
        }).catch((err) => {
            setErrors(err.response.data.data)
            setloading(false)
        })
    }

    return (
        <div>
            <form onSubmit={confirmHandler}>
                <div className="space-y-12">
                    <h2 className="formTitle"> اعدادات الموقع</h2>
                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-3">
                            <label htmlFor="site_name_ar" className="block text-sm font-medium leading-6 text-gray-900">
                                اسم الموقع (العربي)
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, obj, setobj, errors, setErrors)}
                                    required
                                    type="text"
                                    name="site_name_ar"
                                    id="site_name_ar"
                                    value={obj?.site_name_ar ?? ''}
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.site_name_ar}</div>
                        </div>

                        <div className="sm:col-span-3">
                            <label htmlFor="site_name_en" className="block text-sm font-medium leading-6 text-gray-900">
                                اسم الموقع (الانجليزية)
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, obj, setobj, errors, setErrors)}
                                    required
                                    type="text"
                                    name="site_name_en"
                                    id="site_name_en"
                                    value={obj?.site_name_en ?? ''}
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.site_name_en}</div>
                        </div>
                        <div className="sm:col-span-4">
                            <label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900">
                                رقم الهاتف
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, obj, setobj, errors, setErrors)}
                                    required
                                    type="text"
                                    name="phone"
                                    id="phone"
                                    value={obj?.phone ?? ''}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.phone}</div>
                        </div>
                        <div className="sm:col-span-4">
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                البريد الالكتروني
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, obj, setobj, errors, setErrors)}
                                    required
                                    type="email"
                                    name="email"
                                    id="email"
                                    value={obj?.email ?? ''}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.email}</div>
                        </div>

                        <div className="sm:col-span-4">
                            <label htmlFor="fax" className="block text-sm font-medium leading-6 text-gray-900">
                                فاكس
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, obj, setobj, errors, setErrors)}
                                    required
                                    type="text"
                                    name="fax"
                                    id="fax"
                                    value={obj?.fax ?? ''}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.fax}</div>
                        </div>
                        <div className="sm:col-span-4">
                            <label htmlFor="copyrights" className="block text-sm font-medium leading-6 text-gray-900">
                                حقوق النشر
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, obj, setobj, errors, setErrors)}
                                    required
                                    type="text"
                                    name="copyrights"
                                    id="copyrights"
                                    value={obj?.copyrights ?? ''}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.copyrights}</div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor="address" className="block text-sm font-medium leading-6 text-gray-900">
                                العنوان
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, obj, setobj, errors, setErrors)}
                                    required
                                    type="text"
                                    name="address"
                                    id="address"
                                    value={obj?.address}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.address}</div>
                        </div>
                        {/* map */}
                        {coords?.lat ?
                            <div className="sm:col-span-3">

                                {isLoaded ?
                                    <div className='mapContainer'>
                                        <GoogleMap
                                            mapContainerStyle={containerStyle}
                                            center={coords}
                                            zoom={15}
                                            onLoad={onLoad}
                                            onUnmount={onUnmount}
                                        >
                                            <MarkerF position={coords} draggable={true} onDragEnd={onMarkDragged} />
                                            <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                                                <input type="text" placeholder="ابحث" className='inputMapStyle' />
                                            </Autocomplete>
                                        </GoogleMap>
                                    </div>
                                    : ''}
                                <div className='errorText'>{errors?.latitude}</div>

                            </div>
                            : ''}
                        <div className='sm:col-span-2'>
                            <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                                شعار الموقع النسخة العربية
                            </label>
                            {arImage ?
                                <div className="mt-2 uploadedImg flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                    <XCircleIcon onClick={() => removeImg('site_logo_ar')} />
                                    <img src={arImage} alt='' />
                                </div>
                                :
                                <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                    <div className="text-center">
                                        <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                                        <div className="mt-4 flex justify-center text-sm leading-6 text-gray-600">
                                            <label
                                                htmlFor="site_logo_ar"
                                                className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                            >
                                                <span>حمل الصورة</span>
                                                <input id="site_logo_ar" name="site_logo_ar" type="file" className="sr-only"
                                                    onChange={(e) => onChangeHandler(e, obj, setobj, errors, setErrors, setarImage)}
                                                />
                                            </label>
                                        </div>
                                        <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
                                    </div>
                                </div>
                            }
                            <div className='errorText'>{errors?.site_logo_ar}</div>

                        </div>
                        <div className='sm:col-span-2'>
                            <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                                شعار الموقع النسخة الانجليزية
                            </label>
                            {enImage ?
                                <div className="mt-2 uploadedImg flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                    <XCircleIcon onClick={() => removeImg('site_logo_en')} />
                                    <img src={enImage} alt='' />
                                </div>
                                :
                                <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                    <div className="text-center">
                                        <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                                        <div className="mt-4 flex justify-center text-sm leading-6 text-gray-600">
                                            <label
                                                htmlFor="site_logo_en"
                                                className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                            >
                                                <span>حمل الصورة</span>
                                                <input id="site_logo_en" name="site_logo_en" type="file" className="sr-only"
                                                    onChange={(e) => onChangeHandler(e, obj, setobj, errors, setErrors, setenImage)}
                                                />
                                            </label>
                                        </div>
                                        <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
                                    </div>
                                </div>
                            }
                            <div className='errorText'>{errors?.site_logo_en}</div>

                        </div>
                        <div className='sm:col-span-1'>
                            <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                                رمز الموقع
                            </label>
                            {favicon ?
                                <div className="mt-2 uploadedImg flex justify-center rounded-lg border border-dashed border-gray-900/25 px-3 py-5">
                                    <XCircleIcon onClick={() => removeImg('site_icon')} />
                                    <img src={favicon} alt='' />
                                </div>
                                :
                                <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-3 py-5">
                                    <div className="text-center">
                                        <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                                        <div className="mt-4 flex justify-center text-sm leading-6 text-gray-600">
                                            <label
                                                htmlFor="site_icon"
                                                className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                            >
                                                <span>حمل الصورة</span>
                                                <input id="site_icon" name="site_icon" type="file" className="sr-only"
                                                    onChange={(e) => onChangeHandler(e, obj, setobj, errors, setErrors, setfavicon)}
                                                />
                                            </label>
                                        </div>
                                        <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF,ICO up to 10MB</p>
                                    </div>
                                </div>
                            }
                            <div className='errorText'>{errors?.site_icon}</div>

                        </div>
                        <div className='sm:col-span-2'>
                            <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                                الصورة الافتراضية
                            </label>
                            {placeholderImg ?
                                <div className="mt-2 uploadedImg flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                    <XCircleIcon onClick={() => removeImg('placeholder')} />
                                    <img src={placeholderImg} alt='' />
                                </div>
                                :
                                <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                    <div className="text-center">
                                        <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                                        <div className="mt-4 flex justify-center text-sm leading-6 text-gray-600">
                                            <label
                                                htmlFor="placeholder"
                                                className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                            >
                                                <span>حمل الصورة</span>
                                                <input id="placeholder" name="placeholder" type="file" className="sr-only"
                                                    onChange={(e) => onChangeHandler(e, obj, setobj, errors, setErrors, setplaceholderImg)}
                                                />
                                            </label>
                                        </div>
                                        <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
                                    </div>
                                </div>
                            }
                            <div className='errorText'>{errors?.placeholder}</div>

                        </div>
                    </div>

                </div>
                <div className="mt-6 flex items-center justify-end ">
                    {!loading ?
                        <button type="submit" className="submitBtn">
                            حفظ
                        </button>
                        :
                        <button type="button" class="submitBtn flex" disabled>
                            <CgSpinner className='animate-spin' />
                            جاري الحفظ
                        </button>
                    }
                </div>
            </form>
        </div>
    )
}
