import * as React from 'react';
import ListTable from '../../Components/listPages/list';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { getvisits } from '../../Api/visits';

const columns = [
  { id: 'cat_id', label: 'القسم', minWidth: 170 },
  { id: 'name_ar', label: 'الاسم', minWidth: 170 },
  { id: 'price', label: 'السعر', minWidth: 170 },
  {id: 'slug'},
  {id: 'id'},
];

function createData(cat_id, name_ar,price,id) {
  return { cat_id, name_ar,price,id };
}



export default function VisitsList() {
  let [rows, setRows] = React.useState([])
  let [itemLength, setitemLength] = React.useState([])
  let editLink='/dashboard/home-visits'
  let deleteLink='home-visits'

  function getallvisits(page){
    let itemList = []
    getvisits(page).then((res) => {
      let items = res.data.data
      setitemLength(res.data.links.totalObjects)
      items.map((x) => {
        itemList.push(createData(x.cat_id, x.name_ar,x.price, x.id))
        return ''
      })
      setRows(itemList)
    }).catch((err) => {
      console.log(err);
    })
  }
  React.useEffect(() => {
    getallvisits(1)
    //eslint-disable-next-line
  }, [])

  return (
    <>
      <div className='flex justify-between	'>
        <h1 className='pagesTitle'>الزيارات المنزلية</h1>
        <Link to='/dashboard/home-visits/add' className='addBtn'>
          <span>اضافة </span>
          <PlusCircleIcon className='navBtn' />
        </Link>
      </div>
      <div className='tableContainer'>
        {rows && rows.length > 0 &&
          <ListTable rows={rows} setRows={setRows} columns={columns} editLink={editLink} deleteLink={deleteLink} getItems={getallvisits} itemLength={itemLength}/>
        }
      </div>
    </>
  );
}
