import React from 'react'
import { RiDeleteBin6Fill, RiEditBoxFill } from 'react-icons/ri'
import { Link } from 'react-router-dom'
export default function BlogCard({ blog, type, setOpen, setDeletedId }) {
    return (
        <div className='blogCard' style={{ background: `linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${blog?.image_1 ? blog?.image_1 : blog?.image})` }}>
            <h2>{blog?.title_ar}</h2>
            <p>
                {blog?.desc_ar}
            </p>
            <Link to={`/dashboard/${type}/${blog.slug}`}>
                <RiEditBoxFill className='blogIcon blogEditIcon' />
            </Link>
                <div className='blogIcon blogDeleteIcon' onClick={() => { setDeletedId(blog?.id); setOpen(true) }}> <RiDeleteBin6Fill /></div>
        </div>
    )
}
