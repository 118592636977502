import React from 'react'
import Form from './Form'
import {  editDepartment } from '../../../Api/departments';
import { useParams } from 'react-router-dom';

export default function EditDepartment() {
    const { id } = useParams();
    const onSubmit = async (e, id,data) => {
        e.preventDefault();
        //to send request
        return await editDepartment(id,data).then(res => {
            return 'done';          
        }).catch(err =>{
            return err.response.data.data;
        })
    };
  return (
    <div ><Form title='اضافة ادارة' itemId={id} onSubmit={onSubmit}/></div>
  )
}
