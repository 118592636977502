import { https } from './https';
import { admin_url } from './../Configuration/config';

//get partners list
export async function getPartners(page){
    return await https.get(`${admin_url}/partners?page=${page}`);
}
//get partners list
export async function getSinglePartner(id){
    return await https.get(`${admin_url}/partners/${id}`);
}
//add new partners 
export async function addPartner(data){
    return await https.post(`${admin_url}/partners`,data);
}
//update single partner
export async function updatePartner(id,data){
    return await https.post(`${admin_url}/partners/${id}?_method=PUT`,data);
}