import { Route, Routes } from "react-router-dom";
import ProtectedRoutes from "../protectedRoutes";
import AuthLayouts from "../Layouts/AuthLayout";
import DashboardLayout from "../Layouts/DashboardLayout";


export default function Index(props) {
    return (
        <div>
            <Routes>
                {/* ----- Admin Login ------*/}
                    <Route path={`/*`} element={<AuthLayouts />} />
             
                    <Route element={<ProtectedRoutes />}>
                        <Route path={`/dashboard/*`} element={<DashboardLayout />} />
                    </Route>
            </Routes>
        </div>
    )
}