import * as React from 'react';
import ListTable from '../../Components/listPages/list';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { getServices } from '../../Api/services';

const columns = [
  { id: 'image', label: 'الصورة', minWidth: 170 },
  { id: 'name_ar', label: 'الاسم', minWidth: 170 },
  {
    id: 'desc_ar',
    label: 'الوصف',
   
  },
  {
    id: 'status',
    label: 'الحالة',
   
  },
  {
    id: 'rank',
    label: 'الترتيب',
   
  },
  {
    id: 'price',
    label: 'السعر',
   
  },
  {
    id: 'slug',
  },
  {id:'id'}

];

function createData(image, name_ar, desc_ar, status, rank,price,slug,id) {
  return { image, name_ar, desc_ar, status, rank,price,slug,id };
}



export default function ServicesList() {
  let [rows, setRows] = React.useState([])
  let [itemLength, setitemLength] = React.useState([])
  let editLink='/dashboard/services'
  let deleteLink='services'

  function getServicesList(page){
    let  serviceList = []
    getServices(page).then((res) => {
      let cats = res.data.data
      setitemLength(res.data.links.totalObjects)
      cats.map((x) => {
         serviceList.push(createData(x.image, x.name_ar, x.desc_ar, x.status, x.rank, x.price, x.slug,x.id))
        return ''
      })
      setRows( serviceList)
    }).catch((err) => {
      console.log(err);
    })
  }
  React.useEffect(() => {
    getServicesList(1)
    //eslint-disable-next-line
  }, [])

  return (
    <>
      <div className='flex justify-between	'>
        <h1 className='pagesTitle'>الخدمات</h1>
        <Link to='/dashboard/services/add' className='addBtn'>
          <span>اضافة خدمة</span>
          <PlusCircleIcon className='navBtn' />
        </Link>
      </div>
      <div className='tableContainer'>
        {rows && rows.length > 0 &&
          <ListTable rows={rows} setRows={setRows} columns={columns} editLink={editLink} deleteLink={deleteLink} getItems={getServicesList} itemLength={itemLength}/>
        }
      </div>
    </>
  );
}
