import axios from "axios";
/* import i18n from './../i18n'; */
const AdminToken = `Bearer ` + localStorage.getItem('apc_admin_token');

export let http = axios.create({
    headers : {
        AccessControlAllowOrigin: '*',
        // lang: localStorage.getItem('i18nextLng')
    }
})
export let https = axios.create({
    headers : {
        Authorization: AdminToken,
        // AccessControlAllowOrigin: '*',
        Accept:"*/*",
        // lang: localStorage.getItem('i18nextLng')
    }
})