import React from 'react'
import Form from './Form'
import {editCategory } from '../../../Api/categories';
import { useParams } from 'react-router-dom';

export default function EditCategory() {
  const { id } = useParams();

    const onSubmit = async (e,id, data) => {
        e.preventDefault();
        //to send request
        return await editCategory(id,data).then(res => {
            // props.getAll();
            return 'done';          
        }).catch(err =>{
            return err.response.data.errors;
        })
    };
  return (
    <div ><Form title='تعديل القسم' itemId={id} onSubmit={onSubmit}/></div>
  )
}
