import React, { useEffect, useState } from 'react'
import { PhotoIcon } from '@heroicons/react/24/solid'
import { XCircleIcon } from '@heroicons/react/24/solid'
import { onChangeHandler } from '../../helpers/handleChange'
import { editAboutus, getAboutus } from '../../Api/pages'
import { CgSpinner } from 'react-icons/cg'
import Swal from 'sweetalert2'
export default function AboutUs() {
    let [about, setabout] = useState({})
    let [errors, setErrors] = useState({})
    let [visionImg, setvisionImg] = useState(null)
    let [managerImg, setmanagerImg] = useState(null)
    let [reasonsImg, setreasonsImg] = useState(null)
    let [loading, setloading] = useState(null)

    useEffect(()=>{
        getAboutus().then((res)=>{
            let data=res.data.data
            setvisionImg(data?.image_ar)
            setmanagerImg(data?.image_sec_ar)
            setreasonsImg(data?.image_final_ar)
            // delete data?.image_ar
            setabout(data)
        })
    },[])
    function confirmHandler(e) {
        e.preventDefault()
        setloading(true)
        editAboutus(about).then(()=>{
            setloading(false)
            Swal.fire({
                icon: 'success',
                title:  'تم التعديل بنجاح',
                showConfirmButton:false,
                timer: 1500
              })
        }).catch((err)=>{
            setloading(false)
            setErrors(err.response.data.data)
        })
    }
    function removeImg(img) {
        if(img === 'image_ar'){
            setvisionImg('')
        } else if(img === 'image_sec_ar'){
            setmanagerImg('')
        }else if(img === 'image_final_ar'){
            setreasonsImg('')
        }
        let x={...about}
        x[img]=''
        setabout(x)

    }
    return (
        <div>
            <form onSubmit={confirmHandler}>
                <div className="space-y-12">
                    <h2 className="formTitle">عن المجمع</h2>
                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        {/* description */}
                        <div className="col-span-full">
                            <label htmlFor="about_ar" className="block text-sm font-medium leading-6 text-gray-900">
                                الوصف (عربي)
                            </label>
                            <div className="mt-2">
                                <textarea
                                    required
                                    onChange={(e) => onChangeHandler(e, about, setabout, errors, setErrors)}
                                    id="about_ar"
                                    name="about_ar"
                                    rows={3}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    value={about?.about_ar}
                                />
                            </div>
                            <div className='errorText'>{errors?.about_ar}</div>
                        </div>
                        <div className="col-span-full">
                            <label htmlFor="about_en" className="block text-sm font-medium leading-6 text-gray-900">
                                الوصف (انجليزي)
                            </label>
                            <div className="mt-2">
                                <textarea
                                    onChange={(e) => onChangeHandler(e, about, setabout, errors, setErrors)}
                                    id="about_en"
                                    name="about_en"
                                    rows={3}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    value={about?.about_en}
                                />
                            </div>
                            <div className='errorText'>{errors?.about_en}</div>
                        </div>
                        {/* ******************vision , mission section ************** */}
                        <h2 className="formSubTitle col-span-full "> الرؤية و الرسالة</h2>
                        {/* vision */}
                        <div className="sm:col-span-3">
                            <label htmlFor="vision_ar" className="block text-sm font-medium leading-6 text-gray-900">
                                رؤيتنا (عربي)
                            </label>
                            <div className="mt-2">
                                <textarea
                                    required
                                    onChange={(e) => onChangeHandler(e, about, setabout, errors, setErrors)}
                                    id="vision_ar"
                                    name="vision_ar"
                                    rows={3}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    value={about?.vision_ar}
                                />
                            </div>
                            <div className='errorText'>{errors?.vision_ar}</div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor="vision_en" className="block text-sm font-medium leading-6 text-gray-900">
                                رؤيتنا (انجليزي)
                            </label>
                            <div className="mt-2">
                                <textarea
                                    onChange={(e) => onChangeHandler(e, about, setabout, errors, setErrors)}
                                    id="vision_en"
                                    name="vision_en"
                                    rows={3}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    value={about?.vision_en}
                                />
                            </div>
                            <div className='errorText'>{errors?.vision_en}</div>
                        </div>
                        {/* message */}
                        <div className="sm:col-span-3">
                            <label htmlFor="message_ar" className="block text-sm font-medium leading-6 text-gray-900">
                                رسالتنا (عربي)
                            </label>
                            <div className="mt-2">
                                <textarea
                                    required
                                    onChange={(e) => onChangeHandler(e, about, setabout, errors, setErrors)}
                                    id="message_ar"
                                    name="message_ar"
                                    rows={3}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    value={about?.message_ar}
                                />
                            </div>
                            <div className='errorText'>{errors?.message_ar}</div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor="message_en" className="block text-sm font-medium leading-6 text-gray-900">
                                رسالتنا (انجليزي)
                            </label>
                            <div className="mt-2">
                                <textarea
                                    onChange={(e) => onChangeHandler(e, about, setabout, errors, setErrors)}
                                    id="message_en"
                                    name="message_en"
                                    rows={3}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    value={about?.message_en}
                                />
                            </div>
                            <div className='errorText'>{errors?.message_en}</div>
                        </div>
                        {/*  our values*/}
                        <div className="col-span-full">
                            <label htmlFor="values_ar" className="block text-sm font-medium leading-6 text-gray-900">
                                قيمنا (عربي)
                            </label>
                            <div className="mt-2">
                                <textarea
                                    required
                                    onChange={(e) => onChangeHandler(e, about, setabout, errors, setErrors)}
                                    id="values_ar"
                                    name="values_ar"
                                    rows={5}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    value={about?.values_ar}
                                />
                            </div>
                            <div className='errorText'>{errors?.values_ar}</div>
                        </div>
                        <div className="col-span-full">
                            <label htmlFor="values_en" className="block text-sm font-medium leading-6 text-gray-900">
                                قيمنا (انجليزي)
                            </label>
                            <div className="mt-2">
                                <textarea
                                    onChange={(e) => onChangeHandler(e, about, setabout, errors, setErrors)}
                                    id="values_en"
                                    name="values_en"
                                    rows={5}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    value={about?.values_en}
                                />
                            </div>
                            <div className='errorText'>{errors?.values_en}</div>
                        </div>
                        <div className='sm:col-span-2'>
                            <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                                الصورة
                            </label>
                            {visionImg ?
                                <div className="mt-2 uploadedImg flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                    <XCircleIcon onClick={()=>removeImg('image_ar')} />
                                    <img src={visionImg} alt='' />
                                </div>
                                :
                                <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                    <div className="text-center">
                                        <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                                        <div className="mt-4 flex justify-center text-sm leading-6 text-gray-600">
                                            <label
                                                htmlFor="image"
                                                className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                            >
                                                <span>حمل الصورة</span>
                                                <input id="image" name="image_ar" type="file" className="sr-only"
                                                    onChange={(e) => onChangeHandler(e, about, setabout, errors, setErrors, setvisionImg)}
                                                />
                                            </label>
                                        </div>
                                        <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
                                    </div>
                                </div>
                            }
                        </div>
                        <hr className='col-span-full' />
                        {/* manager section */}
                        <h2 className="formSubTitle col-span-full "> المدير</h2>
                        <div className="sm:col-span-3">
                            <label htmlFor="dirct_name_ar" className="block text-sm font-medium leading-6 text-gray-900">
                                الاسم (العربي)
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, about, setabout, errors, setErrors)}
                                    required
                                    type="text"
                                    name="dirct_name_ar"
                                    id="dirct_name_ar"
                                    value={about?.dirct_name_ar}
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.dirct_name_ar}</div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor="dirct_name_en" className="block text-sm font-medium leading-6 text-gray-900">
                                الاسم (انجليزي)
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, about, setabout, errors, setErrors)}
                                    type="text"
                                    name="dirct_name_en"
                                    id="dirct_name_en"
                                    value={about?.dirct_name_en}
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.dirct_name_en}</div>
                        </div>
                        <div className="col-span-full">
                            <label htmlFor="head_mess_ar" className="block text-sm font-medium leading-6 text-gray-900">
                                كلمة المدير (عربي)
                            </label>
                            <div className="mt-2">
                                <textarea
                                    onChange={(e) => onChangeHandler(e, about, setabout, errors, setErrors)}
                                    id="head_mess_ar"
                                    name="head_mess_ar"
                                    rows={5}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    value={about?.head_mess_ar}
                                />
                            </div>
                            <div className='errorText'>{errors?.head_mess_ar}</div>
                        </div>
                        <div className="col-span-full">
                            <label htmlFor="head_mess_en" className="block text-sm font-medium leading-6 text-gray-900">
                                كلمة المدير (انجليزي)
                            </label>
                            <div className="mt-2">
                                <textarea
                                    onChange={(e) => onChangeHandler(e, about, setabout, errors, setErrors)}
                                    id="head_mess_en"
                                    name="head_mess_en"
                                    rows={5}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    value={about?.head_mess_en}
                                />
                            </div>
                            <div className='errorText'>{errors?.head_mess_en}</div>
                        </div>
                        <div className='sm:col-span-2'>
                            <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                                الصورة
                            </label>
                            {managerImg ?
                                <div className="mt-2 uploadedImg flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                    <XCircleIcon onClick={()=>removeImg('image_sec_ar')} />
                                    <img src={managerImg} alt='' />
                                </div>
                                :
                                <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                    <div className="text-center">
                                        <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                                        <div className="mt-4 flex justify-center text-sm leading-6 text-gray-600">
                                            <label
                                                htmlFor="image"
                                                className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                            >
                                                <span>حمل الصورة</span>
                                                <input id="image" name="image_sec_ar" type="file" className="sr-only"
                                                    onChange={(e) => onChangeHandler(e, about, setabout, errors, setErrors, setmanagerImg)}
                                                />
                                            </label>
                                        </div>
                                        <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
                                    </div>
                                </div>
                            }
                        </div>
                        <hr className='col-span-full' />
                        {/* why section */}
                        <h2 className="formSubTitle col-span-full "> لماذا المجمع؟</h2>

                        <div className="col-span-full">
                            <label htmlFor="reason_ar" className="block text-sm font-medium leading-6 text-gray-900">
                               الاسباب(عربي)
                            </label>
                            <div className="mt-2">
                                <textarea
                                    onChange={(e) => onChangeHandler(e, about, setabout, errors, setErrors)}
                                    id="reason_ar"
                                    name="reason_ar"
                                    rows={7}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    value={about?.reason_ar}
                                />
                            </div>
                            <div className='errorText'>{errors?.reason_ar}</div>
                        </div>
                        <div className="col-span-full">
                            <label htmlFor="reason_en" className="block text-sm font-medium leading-6 text-gray-900">
                               الاسباب(انجليزي)
                            </label>
                            <div className="mt-2">
                                <textarea
                                    onChange={(e) => onChangeHandler(e, about, setabout, errors, setErrors)}
                                    id="reason_en"
                                    name="reason_en"
                                    rows={7}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    value={about?.reason_en}
                                />
                            </div>
                            <div className='errorText'>{errors?.reason_en}</div>
                        </div>
                        <div className='sm:col-span-2'>
                            <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                                الصورة
                            </label>
                            {reasonsImg ?
                                <div className="mt-2 uploadedImg flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                    <XCircleIcon onClick={()=>removeImg('image_final_ar')} />
                                    <img src={reasonsImg} alt='' />
                                </div>
                                :
                                <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                    <div className="text-center">
                                        <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                                        <div className="mt-4 flex justify-center text-sm leading-6 text-gray-600">
                                            <label
                                                htmlFor="image"
                                                className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                            >
                                                <span>حمل الصورة</span>
                                                <input id="image" name="image_final_ar" type="file" className="sr-only"
                                                    onChange={(e) => onChangeHandler(e, about, setabout, errors, setErrors, setreasonsImg)}
                                                />
                                            </label>
                                        </div>
                                        <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
                                    </div>
                                </div>
                            }
                        </div>
                        <hr className='col-span-full' />
                    </div>
                </div>
                <div className="mt-6 flex items-center justify-end ">
                    {!loading ?
                        <button type="submit" className="submitBtn">
                            حفظ
                        </button>
                        :
                        <button type="button" class="submitBtn flex" disabled>
                            <CgSpinner className='animate-spin' />
                            جاري الحفظ
                        </button>
                    }
                </div>

            </form>
        </div>
    )
}
