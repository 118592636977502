import '../styles/Layouts/authLayoutstyle.scss';
import doctorimg from '../Assets/images/doctorImg.png';
// import logo from '../Assets/images/logo.png';
// import TextField from '@mui/material/TextField';
// import { Stack } from '@mui/material';
// import { authRoutes } from '../Routes/allRoutes';
import { Navigate, Route, Routes } from 'react-router-dom';
import Login from '../Pages/auth/login';
import Resetpassword from '../Pages/auth/resetpassword';
import { useContext } from 'react';
import authContext from '../Contexts/authContext';

export default function AuthLayout() {
    let { isAuthorized } = useContext(authContext);
    if (isAuthorized === 'authorized') {
        return <Navigate to="/dashboard" replace />;
      }
    else
    return (
        <div className="authLayout h-full">
            <div className="authWrapper">
                <div className='welcomeSection'>
                    <h1>اهلا بك في لوحة تحكم مجمع العالمية</h1>
                    <img src={doctorimg} alt='doctor' />
                </div>

                <Routes>
                    <Route path={`/`} element={<Login />} />
                    <Route path={`/login`} element={<Login />} />
                    <Route path={`/reset-password`} element={<Resetpassword />} />
                    <Route path={`/*`} element={<Login />} />
                </Routes>
                
            </div>

        </div>
    )
}