import { https } from './https';
import { admin_url } from './../Configuration/config';

//get surgeries list
export async function getSurgeries(page){
    return await https.get(`${admin_url}/surgeries?page=${page}`);
}
//add new surgeries 
export async function addSurgery(data){
    return await https.post(`${admin_url}/surgeries`,data);
}
//get single Departments 
export async function getSingleSurgery(slug){
    return await https.get(`${admin_url}/surgeries/${slug}`);
}

//add new surgeries 
export async function updateSurgery(id,data){
    return await https.post(`${admin_url}/surgeries/${id}?_method=PUT`,data);
}