export function seletChangeHandler(e, name, state, setState,errors, setErrors,setSelectState,notArr) {
    console.log(e);
    setSelectState(e)
    if(notArr){
        let stateClone = { ...state }
        stateClone[name] = e.value;
        setState(stateClone)
    }else{
        let arr = [];
        for (let i = 0; i < e.length; i++) {
            arr.push(e[i].value)
        }
        let stateClone = { ...state }
        stateClone[name] = arr;
        setState(stateClone)
        console.log(stateClone);
    }
    let errorClone = { ...errors }
    errorClone[name] = '';
    setErrors(errorClone)

}