import { https } from './https';
import { admin_url } from './../Configuration/config';

//get media list
export async function getMedia(){
    return await https.get(`${admin_url}/media`);
}
//get single media 
export async function getSingleMedia(slug){
    return await https.get(`${admin_url}/media/${slug}`);
}
//add new media 
export async function addnewmedia(data){
    return await https.post(`${admin_url}/media`,data);
}
//add new media 
export async function updateMedia(id,data){
    return await https.post(`${admin_url}/media/${id}?_method=PUT`,data);
}
