import { https } from './https';
import { admin_url } from './../Configuration/config';

//get Departments list
export async function getDepartments(page){
    return await https.get(`${admin_url}/departments?page=${page}`);
}
//get single Departments 
export async function getSingleDepartment(slug){
    return await https.get(`${admin_url}/departments/${slug}`);
}
//add new Departments 
export async function addDepartment(data){
    return await https.post(`${admin_url}/departments`,data);
}
//add new Departments 
export async function editDepartment(id,data){
    return await https.post(`${admin_url}/departments/${id}?_method=PUT`,data);
}
//delete
export async function deleteItem(deleteLink){
    return await https.delete(`${admin_url}/${deleteLink}`);
}