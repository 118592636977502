import React, { useEffect, useState } from 'react'
import { getDoctors } from '../../../Api/doctors';
import Select from 'react-select';
import { seletChangeHandler } from '../../../helpers/selectChange';
import { getAppointmentsDays, getAppointmentsPeriods, getSingleAppointments } from '../../../Api/appointments';
import { CgSpinner } from 'react-icons/cg';
import { confirmHandler } from '../../../helpers/confirmHandler';
import { useNavigate } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { onChangeHandler } from '../../../helpers/handleChange';

export default function Form(props) {
    let [doctors, setdoctors] = useState()
    let [appointment, setAppointment] = useState({status:2})
    let [errors, setErrors] = useState()
    let [selectedDoctor, setSelectedDoctor] = useState()
    let [selectedDay, setSelectedDay] = useState()
    let [selectedPeriod, setSelectedPeriod] = useState()
    let [days, setdays] = useState()
    let [periods, setperiods] = useState()
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null);
    const [loading, setloading] = useState(null);
    let navigate = useNavigate()
    
    useEffect(()=>{
        if(props.itemId){
            getSingleAppointments(props.itemId).then((res)=>{
                let x=res.data.data
                setSelectedDoctor({value:x?.doctor?.id,label:x?.doctor?.name_ar})
                setSelectedDay({value:x?.day?.id,label:x?.day?.name})
                setSelectedPeriod({value:x?.period?.id,label:x?.period?.name})
                setEndDate(x?.end_time)
                setStartDate(x?.start_time)

                x.doctor_id=x?.doctor?.id
                x.period=x?.period?.id
                x.day=x?.day?.id
                delete x.doctor;
                setAppointment(x)
            }).catch((err)=>{
                console.log(err);
            })
        }
    },[props.itemId])
    useEffect(() => {
        getDoctors().then((res) => {
            let data = res.data.data
            let options = []
            for (let i = 0; i < data.length; i++) {
                options.push({
                    value: data[i].id,
                    label: data[i].name_ar
                })
            }
            setdoctors(options);
        })
        getAppointmentsDays().then((res) => {
            let data = res.data.data
            let options = []
            for (let i = 0; i < data.length; i++) {
                options.push({
                    value: data[i].id,
                    label: data[i].name
                })
            }
            setdays(options);
        })
        getAppointmentsPeriods().then((res) => {
            let data = res.data.data
            let options = []
            for (let i = 0; i < data.length; i++) {
                options.push({
                    value: data[i].id,
                    label: data[i].name
                })
            }
            setperiods(options);
        })
    }, [])
    const onChangeDate = (type,date) => {
        let app = { ...appointment }
        if(type === 'start'){
            app['start_time'] = date.target.value
            setStartDate(date.target.value);
        }else{
            app['end_time'] =date.target.value 
            setEndDate(date.target.value);
        }
        setAppointment(app)
    };

    return (
        <div>
            <form onSubmit={(e) => confirmHandler(e, appointment, props, setErrors, navigate, setloading)}>
                <div className="space-y-12">
                    <h2 className="formTitle">{props?.title} </h2>
                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-1">
                            <label htmlFor="dept_id" className="block text-sm font-medium leading-6 text-gray-900">
                                الدكتور
                            </label>
                            <div className="mt-2">
                                <Select placeholder='اختر' value={selectedDoctor} options={doctors} onChange={(e) => seletChangeHandler(e, 'doctor_id', appointment, setAppointment, errors, setErrors, setSelectedDoctor,'notArr')} />
                            </div>
                            <div className='errorText	'>{errors && errors['doctor_id']}</div>
                        </div>
                        <div className="sm:col-span-1">
                            <label htmlFor="dept_id" className="block text-sm font-medium leading-6 text-gray-900">
                                اليوم
                            </label>
                            <div className="mt-2">
                                <Select placeholder='اختر' value={selectedDay} options={days} onChange={(e) => seletChangeHandler(e, 'day', appointment, setAppointment, errors, setErrors, setSelectedDay,'notArr')} />
                            </div>
                            <div className='errorText	'>{errors && errors['day']}</div>
                        </div>
                        <div className="sm:col-span-1">
                            <label htmlFor="dept_id" className="block text-sm font-medium leading-6 text-gray-900">
                                الفترة
                            </label>
                            <div className="mt-2">
                                <Select placeholder='اختر' value={selectedPeriod} options={periods} onChange={(e) => seletChangeHandler(e, 'period', appointment, setAppointment, errors, setErrors, setSelectedPeriod,'notArr')} />
                            </div>
                            <div className='errorText	'>{errors && errors['period']}</div>
                        </div>
                        <div className="sm:col-span-1">
                            <label htmlFor="slug" className="block text-sm font-medium leading-6 text-gray-900">
                                بداية الموعد
                            </label>
                            <div className="mt-2">
                                <input type='time'  value={startDate ?? ''}  onChange={(date) => {onChangeDate('start',date)}} className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                {/* <DatePicker
                                    selected={startDate}
                                    onChange={(date) => {onChangeDate('start',date)}}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeCaption="Time"
                                    dateFormat="h:mm aa"
                                    timeFormat='h:mm aa'
                                    timeIntervals={15}
                                /> */}
                            </div>
                            <div className='errorText'>{errors?.start_time}</div>
                        </div>
                        <div className="sm:col-span-1">
                            <label htmlFor="slug" className="block text-sm font-medium leading-6 text-gray-900">
                                نهاية الموعد
                            </label>
                            <div className="mt-2">
                                <input type='time'   value={endDate ?? ''} onChange={(date) => {onChangeDate('end',date)}} className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                {/* <DatePicker
                                    selected={endDate}
                                    onChange={(date) => {onChangeDate('end',date)}}
                                    showTimeSelect
                                    // minDate={startDate}
                                    maxTime={new Date('11:30')}
                                    minTime={startDate}
                                    showTimeSelectOnly
                                    timeFormat='h:mm aa'
                                    timeCaption="Time"
                                    dateFormat="h:mm aa"
                                    timeIntervals={15}
                                /> */}
                            </div>
                            <div className='errorText'>{errors?.end_time}</div>
                        </div>
                        <div className="sm:col-span-1">
                            <label htmlFor="slug" className="block text-sm font-medium leading-6 text-gray-900">
                                الحالة
                            </label>
                            <FormControlLabel control={<Switch name='status' checked={appointment?.status === 1 ? true : false} onChange={(e) => onChangeHandler(e, appointment, setAppointment, errors, setErrors, errors, setErrors)} />} label="فعال" />
                            <div className='errorText	'>{errors?.status}</div>
                        </div>
                    </div>
                </div>

                <div className="mt-6 flex items-center justify-end ">
                    {!loading ?
                        <button type="submit" className="submitBtn">
                            حفظ
                        </button>
                        :
                        <button type="button" className="submitBtn flex" disabled>
                            <CgSpinner className='animate-spin' />
                            جاري الحفظ
                        </button>
                    }
                </div>
            </form>
        </div>
    )
}
