import React, { useEffect, useState } from 'react'
import {  getSingleReservation } from '../../../Api/reservations'
import { getDoctors } from '../../../Api/doctors'
import { getClinics } from '../../../Api/clinics'
import { getOffers } from '../../../Api/offers'
import { getSurgeries } from '../../../Api/surgeries'
import { getvisits } from '../../../Api/visits'
import Select from 'react-select'
import DatePicker, { registerLocale } from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import ar from 'date-fns/locale/ar'
import moment from 'moment/moment';
import { CgSpinner } from 'react-icons/cg'
import { useNavigate } from 'react-router-dom'
registerLocale('ar', ar)
export default function Form(props) {
    const reserveOptions = [
        { value: 'doctor', label: 'دكتور' },
        { value: 'clinic', label: 'عيادة' },
        { value: 'surgery', label: 'عملية' },
        { value: 'visit', label: 'زيارة' },
        { value: 'offer', label: 'عرض' },
    ]
    let [reserveObj, setreserveObj] = useState()
    let [resDate, setresDate] = useState()
    let [confirmDate, setconfirmDate] = useState()
    let [loading, setLoading] = useState(false)
    let [errors, setErrors] = useState({})
    let [type, setType] = useState()
    let [listItems, setlistItems] = useState([])
    let [selectedItem, setselectedItem] = useState([])
    let [optionName, setoptionName] = useState([])
    // let reserveRef = useRef()
    let navigate = useNavigate()

    useEffect(() => {
        if (props.itemId) {
            getSingleReservation(props.itemId).then((res) => {
                let x = res.data.data
                for (let i = 0; i < reserveOptions.length; i++) {
                    if (x[reserveOptions[i].value] !== '') {
                        setType(reserveOptions[i])
                        let item = x[`${reserveOptions[i].value}`]
                        setselectedItem({ value: item?.id, label: item?.name_ar })
                    }
                    if (x[reserveOptions[i].value] === '') {
                        delete x[reserveOptions[i].value]
                    }
                }
                if (!x.approved_date) {
                    delete x.approved_date
                } else {
                    setconfirmDate(new Date(x?.approved_date))
                }
                setresDate(new Date(x?.res_date))
                setreserveObj(x)
            }).catch((err) => {
                console.log(err);
            })
        }
        //eslint-disable-next-line
    }, [props.itemId])

    function getListItems(data) {
        let options = []
        for (let i = 0; i < data.length; i++) {
            options.push({
                value: data[i].id,
                label: data[i].name_ar
            })
        }
        setlistItems(options)
    }
    useEffect(() => {
        if (type?.value === 'doctor') {
            setoptionName('doctor_id')
            getDoctors().then((res) => {
                let data = res.data.data
                getListItems(data)
            })
        } else if (type?.value === 'clinic') {
            setoptionName('clinic_id')
            getClinics().then((res) => {
                let data = res.data.data
                getListItems(data)
            })
        } else if (type?.value === 'offer') {
            setoptionName('offer_id')
            getOffers().then((res) => {
                let data = res.data.data
                getListItems(data)
            })
        } else if (type?.value === 'surgery') {
            setoptionName('surgery_id')
            getSurgeries().then((res) => {
                let data = res.data.data
                getListItems(data)
            })
        } else if (type?.value === 'visit') {
            setoptionName('visit_id')
            getvisits().then((res) => {
                let data = res.data.data
                getListItems(data)
            })
        }

    }, [type])

    function onChangeHandler(e) {
        let res = { ...reserveObj }
        res[e.target.name] = e.target.value
        setreserveObj(res)

        let err = { ...errors }
        err[e.target.name] = ''
        setErrors(err)
    }

    function selectChange(e) {
        setType(e)
    }
    function selectChangeItem(e) {
        setselectedItem(e)
    }

    async function reserveSubmit(e) {
        let res = { ...reserveObj }
        e.preventDefault()
        setLoading(true)
        if (selectedItem) {
            res[`${optionName}`] = selectedItem?.value
        }
        res['res_date'] = moment(resDate).format('YYYY/MM/DD')

        let submitProps;
        if (props.itemId) {
            if (confirmDate) {
                res['approved_date'] = moment(confirmDate).format('YYYY/MM/DD')
            }
            submitProps = await props.onSubmit(e, res?.id, res);
        } else {
            submitProps = await props.onSubmit(e, res);
        }
        if (submitProps === "done") {
            navigate(-1);
            setLoading(false)

        } else {
            setErrors(submitProps)
            setLoading(false)
        }
    }

    return (
        <div>
            <form onSubmit={reserveSubmit}>
                <div className="space-y-12">
                    <h2 className="formTitle">{props?.title} </h2>
                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                            <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                                الاسم
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, reserveObj, setreserveObj, errors, setErrors)}
                                    type="text"
                                    name="name"
                                    id="name"
                                    value={reserveObj?.name}
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.name}</div>
                        </div>
                        <div className="sm:col-span-4">
                            <label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900">
                                رقم الهاتف
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, reserveObj, setreserveObj, errors, setErrors)}
                                    type="text"
                                    name="phone"
                                    id="phone"
                                    value={reserveObj?.phone}
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.phone}</div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">
                                المدينة
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, reserveObj, setreserveObj, errors, setErrors)}
                                    type="text"
                                    name="city"
                                    id="city"
                                    value={reserveObj?.city}
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.city}</div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor="district" className="block text-sm font-medium leading-6 text-gray-900">
                                الحي
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, reserveObj, setreserveObj, errors, setErrors)}
                                    type="text"
                                    name="district"
                                    id="district"
                                    value={reserveObj?.district}
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.district}</div>
                        </div>
                        <div className="sm:col-span-4">
                            <label htmlFor="dept_id" className="block text-sm font-medium leading-6 text-gray-900">
                                نوع الحجز
                            </label>
                            <div className="mt-2">
                                <Select placeholder='اختر' value={type} options={reserveOptions} onChange={selectChange} />
                            </div>

                        </div>
                        {listItems && listItems.length ?
                            <div className="sm:col-span-4">
                                <label htmlFor="dept_id" className="block text-sm font-medium leading-6 text-gray-900">
                                    نوع الحجز
                                </label>
                                <div className="mt-2">
                                    <Select options={listItems} placeholder='اختر' value={selectedItem} isClearable={true} isSearchable={true} onChange={selectChangeItem} />
                                </div>
                            </div>
                            : ""}
                        <div className="sm:col-span-4">
                            <label htmlFor="slug" className="block text-sm font-medium leading-6 text-gray-900">
                                التاريخ
                            </label>
                            <div className="mt-2">
                                <DatePicker
                                    selected={resDate}
                                    onChange={(date) => setresDate(date)}
                                    minDate={new Date()}
                                    locale='ar'
                                    className='ring-gray-300'
                                    readOnly={props?.itemId ? true : false}
                                />
                            </div>
                            <div className='errorText'>{errors?.res_date}</div>
                        </div>
                        {props?.itemId ?
                            <div className="sm:col-span-4">
                                <label htmlFor="slug" className="block text-sm font-medium leading-6 text-gray-900">
                                    تاريخ التاكيد
                                </label>
                                <div className="mt-2">
                                    <DatePicker
                                        selected={confirmDate}
                                        onChange={(date) => setconfirmDate(date)}
                                        minDate={new Date()}
                                        locale='ar'
                                        className='ring-gray-300'
                                        required
                                    />
                                </div>
                                <div className='errorText'>{errors?.approved_date}</div>
                            </div>
                            : ""}
                    </div>
                </div>

                <div className="mt-6 flex items-center justify-end ">
                    {!loading ?
                        <button type="submit" className="submitBtn">
                            حفظ
                        </button>
                        :
                        <button type="button" class="submitBtn flex" disabled>
                            <CgSpinner className='animate-spin' />
                            جاري الحفظ
                        </button>
                    }
                </div>
            </form>
        </div>
    )
}
