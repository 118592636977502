import React, { useEffect, useState } from 'react'
import { onChangeHandler } from '../../helpers/handleChange'
import { XCircleIcon } from '@heroicons/react/24/solid'
import { PhotoIcon } from '@heroicons/react/24/solid'
import { getSingleSeo, updateSingleSeo } from '../../Api/seo'
import { CgSpinner } from 'react-icons/cg'
import Swal from 'sweetalert2'

export default function Seo({ objectType, objectId }) {
    let [seoObj, setseoObj] = useState({
        object_type: objectType,
        object_id: objectId
    })

    let [seoErrors, setseoErrors] = useState()
    let [seoImg, setseoImg] = useState()
    let [loadingSeo, setloadingSeo] = useState(false)
    useEffect(() => {
        getSingleSeo(objectType, objectId).then((res) => {
            let data = { ...seoObj }
            data = res.data.data
            data.object_id = objectId
            data.object_type = objectType
            // console.log(data);

            if (data?.image) { setseoImg(data?.image) }
            delete data?.image
            setseoObj(data)
        }).catch((err) => {
            console.log(err.response);
        })
        //eslint-disable-next-line
    }, [])
    function confirmHandler(e) {
        e.preventDefault();
        setloadingSeo(true)
        let formData = new FormData()
        for (const key in seoObj) {
            formData.append(key, seoObj[key]);
        }
        updateSingleSeo(formData).then((res) => {
            setloadingSeo(false)
            Swal.fire({
                icon: 'success',
                title: 'تم التعديل بنجاح',
                showConfirmButton: false,
                timer: 1500
            })
        }).catch((err) => {
            // console.log(err.response);
            setseoErrors(err.response.data.data)
            setloadingSeo(false)
        })
        // updateSingleSeo()

    }
    function removeImg() {

    }
    return (
        <form onSubmit={confirmHandler}>
            <hr class="col-span-full my-4"></hr>
            <div className='formSubTitle'>
                تحسين محرك البحث
            </div>
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-2">
                    <label htmlFor="title_ar" className="block text-sm font-medium leading-6 text-gray-900">
                        العنوان (العربي)
                    </label>
                    <div className="mt-2">
                        <input
                            onChange={(e) => onChangeHandler(e, seoObj, setseoObj, seoErrors, setseoErrors)}
                            type="text"
                            name="title_ar"
                            id="title_ar"
                            value={seoObj?.title_ar ?? ''}
                            autoComplete="given-name"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                    <div className='errorText'>{seoErrors?.title_ar}</div>
                </div>

                <div className="sm:col-span-2">
                    <label htmlFor="title_en" className="block text-sm font-medium leading-6 text-gray-900">
                        العنوان (الانجليزية)
                    </label>
                    <div className="mt-2">
                        <input
                            onChange={(e) => onChangeHandler(e, seoObj, setseoObj, seoErrors, setseoErrors)}
                            type="text"
                            name="title_en"
                            id="title_en"
                            value={seoObj?.title_en ?? ''}
                            autoComplete="family-name"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                    <div className='errorText'>{seoErrors?.title_en}</div>
                </div>
                <div className="sm:col-span-3">
                    <label htmlFor="desc_ar" className="block text-sm font-medium leading-6 text-gray-900">
                        الوصف (عربي)
                    </label>
                    <div className="mt-2">
                        <textarea
                            onChange={(e) => onChangeHandler(e, seoObj, setseoObj, seoErrors, setseoErrors)}
                            id="desc_ar"
                            name="desc_ar"
                            rows={3}
                            value={seoObj?.desc_ar ?? ''}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"

                        />
                    </div>
                    <div className='errorText'>{seoErrors?.desc_ar}</div>
                </div>
                <div className="sm:col-span-3">
                    <label htmlFor="desc_en" className="block text-sm font-medium leading-6 text-gray-900">
                        الوصف (الانجليزية)
                    </label>
                    <div className="mt-2">
                        <textarea
                            onChange={(e) => onChangeHandler(e, seoObj, setseoObj, seoErrors, setseoErrors)}
                            id="desc_en"
                            name="desc_en"
                            rows={3}
                            value={seoObj?.desc_en ?? ''}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                    <div className='errorText'>{seoErrors?.desc_en}</div>
                </div>
                {/* *******************author************** */}
                <div className="sm:col-span-2">
                    <label htmlFor="author_ar" className="block text-sm font-medium leading-6 text-gray-900">
                        المؤلف (العربي)
                    </label>
                    <div className="mt-2">
                        <input
                            onChange={(e) => onChangeHandler(e, seoObj, setseoObj, seoErrors, setseoErrors)}
                            type="text"
                            name="author_ar"
                            id="author_ar"
                            value={seoObj?.author_ar ?? ''}
                            autoComplete="given-name"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                    <div className='errorText'>{seoErrors?.author_ar}</div>
                </div>

                <div className="sm:col-span-2">
                    <label htmlFor="author_en" className="block text-sm font-medium leading-6 text-gray-900">
                        المؤلف (الانجليزية)
                    </label>
                    <div className="mt-2">
                        <input
                            onChange={(e) => onChangeHandler(e, seoObj, setseoObj, seoErrors, setseoErrors)}
                            type="text"
                            name="author_en"
                            id="author_en"
                            value={seoObj?.author_en ?? ''}
                            autoComplete="family-name"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                    <div className='errorText'>{seoErrors?.author_en}</div>
                </div>
                <div className="sm:col-span-1">
                    <label htmlFor="robots" className="block text-sm font-medium leading-6 text-gray-900">
                        الفهرسة
                    </label>
                    <select
                        onChange={(e) => onChangeHandler(e, seoObj, setseoObj, seoErrors, setseoErrors)}
                        required
                        id="robots"
                        name="robots"
                        value={seoObj?.robots ?? ''}
                        className="selectItem block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    >
                        <option value={' '}>الإفتراضي</option>
                        <option value={'nofollow'}>No Follow</option>
                        <option value={'noindex'}>No Index</option>

                    </select>
                    <div className='errorText'>{seoErrors?.robots}</div>
                </div>
                <div className="sm:col-span-1">
                    <label htmlFor="type" className="block text-sm font-medium leading-6 text-gray-900">
                        النوع
                    </label>
                    <select
                        onChange={(e) => onChangeHandler(e, seoObj, setseoObj, seoErrors, setseoErrors)}
                        required
                        id="type"
                        name="type"
                        value={seoObj?.type ?? ''}
                        className="selectItem block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    >
                        <option value={'website'}>صفحة</option>
                        <option value={'article'}>مقالة</option>

                    </select>
                    <div className='errorText'>{seoErrors?.robots}</div>
                </div>
                {/* *******************tags************** */}
                <div className="sm:col-span-3">
                    <label htmlFor="tags_ar" className="block text-sm font-medium leading-6 text-gray-900">
                        الكلمات المفتاحية (العربي)
                    </label>
                    <div className="mt-2">
                        <input
                            onChange={(e) => onChangeHandler(e, seoObj, setseoObj, seoErrors, setseoErrors)}
                            type="text"
                            name="tags_ar"
                            id="tags_ar"
                            value={seoObj?.tags_ar ?? ''}
                            autoComplete="given-name"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                    <div className='errorText'>{seoErrors?.tags_ar}</div>
                </div>

                <div className="sm:col-span-3">
                    <label htmlFor="tags_en" className="block text-sm font-medium leading-6 text-gray-900">
                        الكلمات المفتاحية (الانجليزية)
                    </label>
                    <div className="mt-2">
                        <input
                            onChange={(e) => onChangeHandler(e, seoObj, setseoObj, seoErrors, setseoErrors)}
                            type="text"
                            name="tags_en"
                            id="tags_en"
                            value={seoObj?.tags_en ?? ''}
                            autoComplete="family-name"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                    <div className='errorText'>{seoErrors?.tags_en}</div>
                </div>
                
                <div className='col-span-full'> </div>
                <div className='sm:col-span-2'>
                    <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                        الصورة
                    </label>
                    {seoImg ?
                        <div className="mt-2 uploadedImg flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                            <XCircleIcon onClick={removeImg} />
                            <img src={seoImg} alt='' />
                        </div>
                        :
                        <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                            <div className="text-center">
                                <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                                <div className="mt-4 flex justify-center text-sm leading-6 text-gray-600">
                                    <label
                                        htmlFor="image"
                                        className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                    >
                                        <span>حمل الصورة</span>
                                        <input id="image" name="image" type="file" className="sr-only"
                                            onChange={(e) => onChangeHandler(e, seoObj, setseoObj, seoErrors, setseoErrors, setseoImg)}
                                        />
                                    </label>
                                </div>
                                <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
                            </div>
                        </div>
                    }
                    <div className='errorText	'>{seoErrors && seoErrors['image']}</div>

                </div>
            </div>
            <div className="mt-6 flex items-center justify-end ">
                {!loadingSeo ?
                    <button type="submit" className="submitBtn">
                        حفظ
                    </button>
                    :
                    <button type="button" class="submitBtn flex" disabled>
                        <CgSpinner className='animate-spin' />
                        جاري الحفظ
                    </button>
                }
            </div>
        </form>
    )
}
