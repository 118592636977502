import React, { useEffect, useState } from 'react'
import { onChangeHandler } from '../../helpers/handleChange'
import { CgSpinner } from 'react-icons/cg'
import { adminProfile, updateProfile } from '../../Api/auth'
import openEye from '../../Assets/icons/closeeye.svg';
import closedEye from '../../Assets/icons/openeye.svg';
import Swal from 'sweetalert2';
export default function AdminProfile() {
    let [user, setuser] = useState({
        name:'',
        phone:"",
        old_password:"",
        new_password:'',
        new_password_confirmation:''
    })
    
    let [errors, setErrors] = useState()
    let [loading, setloading] = useState()
    let [passType, setpassType] = useState('password')
    let [newpassType, setnewpassType] = useState('password')
    let [confirmpassType, setconfirmpassType] = useState('password')

    useEffect(() => {
        adminProfile().then((res) => {
            setuser(res.data.data)
        })
    }, [])

    function showPasswordHandler(type) {
        if(type === 'old'){
            if (passType === 'password') {
                setpassType('text')
            } else {
                setpassType('password')
            }
        }else if(type === 'new'){
            if (newpassType === 'password') {
                setnewpassType('text')
            } else {
                setnewpassType('password')
            }
        }
        else if(type === 'confirm'){
            if (confirmpassType === 'password') {
                setconfirmpassType('text')
            } else {
                setconfirmpassType('password')
            }
        }
    }

    function confirmHandler(e) {
        e.preventDefault();
        setloading(true)
        updateProfile(user).then((res) => {
            setloading(false)
            Swal.fire({
                icon: 'success',
                title:  'تم التعديل بنجاح',
                showConfirmButton:false,
                timer: 1500
              })
        }).catch((err) => {
            setErrors(err.response.data.data)
            setloading(false)
        })
    }

    return (
        <div>
            <form onSubmit={confirmHandler}>
                <div className="space-y-12">
                    <h2 className="formTitle">حسابي</h2>
                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                            <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                                الاسم
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, user, setuser, errors, setErrors)}
                                    required
                                    type="text"
                                    name="name"
                                    id="name"
                                    value={user?.name}
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.name}</div>
                        </div>
                        <div className="sm:col-span-4">
                            <label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900">
                                رقم الهاتف
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, user, setuser, errors, setErrors)}
                                    required
                                    type="text"
                                    name="phone"
                                    id="phone"
                                    value={user?.phone}
                                    autoComplete="given-phone"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.phone}</div>
                        </div>
                        <div className="sm:col-span-4">
                            <label htmlFor="old_password" className="block text-sm font-medium leading-6 text-gray-900">
                                كلمة المرور القديمة
                            </label>
                            <div className="passwordformContainer mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, user, setuser, errors, setErrors)}
                                    
                                    type={passType}
                                    name="old_password"
                                    id="old_password"
                                    value={user?.old_password}
                                    autoComplete="given-old_password"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                                 <span
                                    onClick={()=>showPasswordHandler('old')}
                                    className={`eyeIcon`}
                                >
                                    {passType === "password" ? (
                                        <img src={openEye} alt='eye icon' />
                                    )
                                        :
                                        (
                                            <img src={closedEye} alt="eye icon" />
                                        )
                                    }
                                </span>
                            </div>
                            <div className='errorText'>{errors?.old_password}</div>
                        </div>
                        <div className="sm:col-span-4">
                            <label htmlFor="new_password" className="block text-sm font-medium leading-6 text-gray-900">
                                كلمة المرور الجديدة
                            </label>
                            <div className="passwordformContainer mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, user, setuser, errors, setErrors)}
                                    type={newpassType}
                                    name="new_password"
                                    id="new_password"
                                    value={user?.new_password}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />

                                <span
                                    onClick={()=>showPasswordHandler('new')}
                                    className={`eyeIcon`}
                                >
                                    {newpassType === "password" ? (
                                        <img src={openEye} alt='eye icon' />
                                    )
                                        :
                                        (
                                            <img src={closedEye} alt="eye icon" />
                                        )
                                    }
                                </span>
                            </div>
                            <div className='errorText'>{errors?.new_password}</div>
                        </div>
                        <div className="sm:col-span-4">
                            <label htmlFor="new_password_confirmation" className="block text-sm font-medium leading-6 text-gray-900">
                                تاكيد كلمة المرور الجديدة
                            </label>
                            <div className="passwordformContainer mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, user, setuser, errors, setErrors)}
                                    
                                    type={confirmpassType}
                                    name="new_password_confirmation"
                                    id="new_password_confirmation"
                                    value={user?.new_password_confirmation}
                                    autoComplete="given-new_password_confirmation"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                                 <span
                                    onClick={()=>showPasswordHandler('confirm')}
                                    className={`eyeIcon`}
                                >
                                    {confirmpassType === "password" ? (
                                        <img src={openEye} alt='eye icon' />
                                    )
                                        :
                                        (
                                            <img src={closedEye} alt="eye icon" />
                                        )
                                    }
                                </span>
                            </div>
                            <div className='errorText'>{errors?.new_password_confirmation}</div>
                        </div>
                    </div>
                </div>
                <div className="mt-6 flex items-center justify-end ">
                    {!loading ?
                        <button type="submit" className="submitBtn">
                            حفظ
                        </button>
                        :
                        <button type="button" className="submitBtn flex" disabled>
                            <CgSpinner className='animate-spin' />
                            جاري الحفظ
                        </button>
                    }
                </div>
            </form>
        </div>
    )
}
