import * as React from 'react';
import ListTable from '../../Components/listPages/list';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { getOffers } from '../../Api/offers';

const columns = [
  { id: 'image', label: 'الصورة', minWidth: 170 },
  { id: 'name_ar', label: 'الاسم', minWidth: 170 },
  {
    id: 'price',
    label: 'السعر',
    maxWidth:"170px"
   
  },
  {
    id: 'sale_price',
    label: 'سعر العرض',
    maxWidth:"170px"
   
  },
  {
    id: 'status',
    label: 'الحالة',
   
  },
  {
    id: 'rank',
    label: 'الترتيب',
   
  },
  {
    id: 'slug',
  },
  {id:'id'}

];

function createData(image, name_ar,price,sale_price, status, rank,slug,id) {
  return { image, name_ar,price,sale_price, status, rank,slug,id };
}



export default function OffersList() {
  let [rows, setRows] = React.useState([])
  let [itemLength, setitemLength] = React.useState([])
  let editLink='/dashboard/offers'
  let deleteLink='offers'

  function getAllOffers(page){
    let itemList = []
    getOffers(page).then((res) => {
      let items = res.data.data
      setitemLength(res.data.links.totalObjects)
      items.map((x) => {
        itemList.push(createData(x.image, x.name_ar,  x.price,x.sale_price, x.status, x.rank, x.slug,x.id))
        return ''
      })
      setRows(itemList)
    }).catch((err) => {
      console.log(err);
    })
  }
  React.useEffect(() => {
    getAllOffers(1)
    //eslint-disable-next-line
  }, [])

  return (
    <>
      <div className='flex justify-between	'>
        <h1 className='pagesTitle'>العروض</h1>
        <Link to='/dashboard/offers/add' className='addBtn'>
          <span>اضافة عرض</span>
          <PlusCircleIcon className='navBtn' />
        </Link>
      </div>
      <div className='tableContainer'>
        {rows && rows.length > 0 &&
          <ListTable rows={rows} setRows={setRows}  columns={columns} getItems={getAllOffers} editLink={editLink} deleteLink={deleteLink} itemLength={itemLength}/>
        }
      </div>
    </>
  );
}
