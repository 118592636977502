import { https } from './https';
import { admin_url } from './../Configuration/config';

//get banners list
export async function getBanners(){
    return await https.get(`${admin_url}/banners`);
}
//get banners types
export async function getBannerTypes(){
    return await https.get(`${admin_url}/banners/types`);
}
//get banners types objects
export async function getBannerTypesObjs(id){
    return await https.get(`${admin_url}/banners/types/${id}`);
}
//get single Banner
export async function getSingleBanner(slug){
    return await https.get(`${admin_url}/banners/${slug}`);
}
//add new banners 
export async function addBanner(data){
    return await https.post(`${admin_url}/banners`,data);
}
//edit banners 
export async function updateBanner(id,data){
    return await https.post(`${admin_url}/banners/${id}?_method=PUT`,data);
}

//get page
export async function getPage(page){
    return await https.get(`${admin_url}/pages/${page}`);
}
//edit page
export async function editPage(id,data){
    return await https.post(`${admin_url}/pages/${id}?_method=PUT`,data);
}
//get aboutus
export async function getAboutus(){
    return await https.get(`${admin_url}/about-us`);
}

//edit ABOUTUS
export async function editAboutus(data){
    return await https.post(`${admin_url}/about-us?_method=PUT`,data);
}
//get Settings
export async function getSettings(){
    return await https.get(`${admin_url}/settings`);
}
// updateSettings
export async function updateSettings(data){
    return await https.post(`${admin_url}/settings?_method=PUT`,data);
}
//get overview
export async function getOverview(){
    return await https.get(`${admin_url}/overview`);
}