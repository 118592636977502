import { useEffect, useState } from "react"
import { getOverview } from "../../Api/pages"
import { Link } from "react-router-dom"

export default function Overview() {
    // let [overview,setOverview]=useState()
    let [environment, setEnvironment] = useState()
    let [reservations, setreservations] = useState()
    useEffect(() => {
        getOverview().then((res) => {
            let data = res.data.data
            setEnvironment(data?.envoirnment)
            setreservations(data?.reservations)
        })
    }, [])
    return (
        <div >
            <h1 className='pagesTitle'>احصائيات عامة</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mb-5">
                <div>
                    <Link to={`/dashboard/blogs`} className='overViewCard h-100'>
                        <span className='details'>المقالات</span>
                        <span className='number'>{environment?.blogs}</span>
                    </Link>
                </div>
                <div >
                    <Link to={`/dashboard/departments`} className='overViewCard h-100'>
                        <span className='details'>الادارات</span>
                        <span className='number'>{environment?.departments}</span>
                    </Link>
                </div>
                <div>
                    <Link to={`/dashboard/clinics`} className='overViewCard h-100'>
                        <span className='details'>العيادات</span>
                        <span className='number'>{environment?.clinics}</span>
                    </Link>
                </div>
                <div>
                    <Link to={`/dashboard/doctors`} className='overViewCard h-100'>
                        <span className='details'>الدكاترة</span>
                        <span className='number'>{environment?.doctor}</span>
                    </Link>
                </div>
                <div>
                    <Link to={`/dashboard/offers`} className='overViewCard h-100'>
                        <span className='details'>العروض</span>
                        <span className='number'>{environment?.offers}</span>
                    </Link>
                </div>
                <div>
                    <Link to={`/dashboard/partners`} className='overViewCard h-100'>
                        <span className='details'>الشركاء</span>
                        <span className='number'>{environment?.partners}</span>
                    </Link>
                </div>
                <div>
                    <Link to={`/dashboard/services`} className='overViewCard h-100'>
                        <span className='details'>الخدمات</span>
                        <span className='number'>{environment?.services}</span>
                    </Link>
                </div>
                <div>
                    <Link to={`/dashboard/surgeries`} className='overViewCard h-100'>
                        <span className='details'>العمليات</span>
                        <span className='number'>{environment?.surgeries}</span>
                    </Link>
                </div>

            </div>
            <h1 className='pagesTitle'>احصائيات الحجز</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mb-5">
                <div>
                    <Link to={`/dashboard/reservations`} className='overViewCard h-100'>
                        <span className='details'>العيادات</span>
                        <span className='number'>{reservations?.clinics}</span>
                    </Link>
                </div>
                <div>
                    <Link to={`/dashboard/reservations`} className='overViewCard h-100'>
                        <span className='details'>الدكاترة</span>
                        <span className='number'>{reservations?.doctors}</span>
                    </Link>
                </div>
                <div>
                    <Link to={`/dashboard/reservations`} className='overViewCard h-100'>
                        <span className='details'>العروض</span>
                        <span className='number'>{reservations?.offers}</span>
                    </Link>
                </div>
                <div>
                    <Link to={`/dashboard/reservations`} className='overViewCard h-100'>
                        <span className='details'>العمليات</span>
                        <span className='number'>{reservations?.surgeries}</span>
                    </Link>
                </div>
            </div>
            <h4 className='formSubTitle'>الدكتور الاكثر حجزا </h4>
            {/* <div className="flex  overflow-hidden">
                <img
                    className="inline-block h-6 w-6 rounded-full ring-2 ring-white"
                    src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                />
                <h1 className="doctorName">d</h1>
            </div> */}


        </div>
    )
}