import React, { useEffect, useState } from 'react'
import { PhotoIcon } from '@heroicons/react/24/solid'
import { XCircleIcon } from '@heroicons/react/24/solid'
import { onChangeHandler } from '../../../helpers/handleChange'
import { useNavigate } from 'react-router-dom';
import { getSinglePartner } from '../../../Api/partners';
import { confirmHandler } from '../../../helpers/confirmHandler';

export default function Form(props) {
    let [partner, setpartner] = useState({
        name_ar: "",
        name_en: "",
        logo: "",
        website: ""
    })
    let [img, setImg] = useState()
    let [errors, setErrors] = useState()
    let navigate = useNavigate();
    
    useEffect(() => {
        if (props.itemId) {
            getSinglePartner(props.itemId).then((res) => {
                let x = res.data.data
                setImg(x.logo)
                x.logo = ''
                setpartner(x)
            }).catch((err) => {
                console.log(err);
            })
        }
    }, [props.itemId])
    // onsubmit

    function removeImg() {
        setImg()
        let cat = { ...partner }
        cat['icon'] = ''
        setpartner(cat)
    }

    return (
        <div>
            <form onSubmit={(e) => confirmHandler(e, partner, props, setErrors, navigate)}>
                <div className="space-y-12">
                    <h2 className="formTitle">{props?.title} </h2>
                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-3">
                            <label htmlFor="name_ar" className="block text-sm font-medium leading-6 text-gray-900">
                                الاسم (العربي)
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, partner, setpartner, errors, setErrors)}
                                    type="text"
                                    name="name_ar"
                                    id="name_ar"
                                    value={partner?.name_ar}
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.name_ar}</div>

                        </div>

                        <div className="sm:col-span-3">
                            <label htmlFor="name_en" className="block text-sm font-medium leading-6 text-gray-900">
                                الاسم (الانجليزية)
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, partner, setpartner, errors, setErrors)}
                                    type="text"
                                    name="name_en"
                                    id="name_en"
                                    value={partner?.name_en}
                                    autoComplete="family-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.name_en}</div>

                        </div>

                        <div className="col-span-full">
                            <label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-900">
                                الموقع الالكتروني
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, partner, setpartner, errors, setErrors)}
                                    type="text"
                                    name="website"
                                    id="website"
                                    autoComplete="given-name"
                                    value={partner?.website}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.website}</div>
                        </div>
                        <div className="sm:col-span-4">
                            <label htmlFor="rank" className="block text-sm font-medium leading-6 text-gray-900">
                                الترتيب
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, partner, setpartner, errors, setErrors, errors, setErrors)}
                                    id="rank"
                                    name="rank"
                                    type="number"
                                    value={partner.rank}
                                    className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                            </div>
                            <div className='errorText	'>{errors?.rank}</div>

                        </div>
                        <div className="col-span-full"></div>
                        <div className='sm:col-span-2'>
                            <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                                الصورة
                            </label>
                            {img ?
                                <div className="mt-2 uploadedImg flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                    <XCircleIcon onClick={removeImg} />
                                    <img src={img} alt='' />
                                </div>
                                :
                                <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                    <div className="text-center">
                                        <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                                        <div className="mt-4 flex justify-center text-sm leading-6 text-gray-600">
                                            <label
                                                htmlFor="logo"
                                                className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                            >
                                                <span>حمل الصورة</span>
                                                <input id="logo" name="logo" type="file" className="sr-only"
                                                    onChange={(e) => onChangeHandler(e, partner, setpartner, errors, setErrors, setImg)}
                                                />
                                            </label>
                                        </div>
                                        <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
                                    </div>
                                </div>
                            }
                            <div className='errorText'>{errors?.logo}</div>

                        </div>

                    </div>

                </div>

                <div className="mt-6 flex items-center justify-end ">

                    <button
                        type="submit"
                        className="submitBtn"
                    >
                        حفظ
                    </button>
                </div>
            </form>
        </div>
    )
}
