export const confirmHandler = async (e,obj,props,setErrors,navigate,setLoading) => {
    e.preventDefault();
    setLoading(true)
    console.log(obj);
    let formData = new FormData()
    for (const key in obj) {
        formData.append(key, obj[key]);
    }
    for (var pair of formData.entries()) {
        console.log(pair[0] + ', ' + pair[1]);
    }

    let submitProps;
    if (props.itemId) {
        submitProps = await props.onSubmit(e, obj?.id, formData);
    } else {
        submitProps = await props.onSubmit(e, formData);
    }
    if (submitProps === "done") {
        navigate(-1);
        setLoading(false)

    } else {
        setErrors(submitProps)
        setLoading(false)
        console.log(submitProps);
    }
}