import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
// import IconButton from '@mui/material/IconButton';
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
// import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { FiEdit } from 'react-icons/fi'
import { RiDeleteBin5Line } from 'react-icons/ri';
import DeleteDialog from '../popups/delete';
import { deleteItem } from '../../Api/departments';

export default function ListTable({ columns, rows, editLink, deleteLink, getItems, itemLength ,setRows}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  let [open, setOpen] = React.useState(false)
  let [deletedId, setDeletedId] = React.useState()
  let [deleteError, setdeleteError] = React.useState()

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getItems(newPage + 1)
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  function confirmDelete() {
    deleteItem(`${deleteLink}/${deletedId}`).then(() => {
      let deleted = rows.find((p) => p.id === deletedId)
      let index = rows.indexOf(deleted)
      rows.splice(index, 1)
      setRows(rows)
      getItems(page)
      setOpen(false)
    }).catch((err)=>{
      setdeleteError(err.response.data.message)
    })
  }

  

  return (
    <>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align='center'
                    style={{ minWidth: '20px' }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        if (column.id === 'slug') {
                          return (
                            <TableCell align='left' key={column.id+2}>
                              <div className='actionsCell'>
                                <Link to={`${editLink}/${(deleteLink === 'partners' || deleteLink === 'home-visits' || deleteLink === 'reservations' || deleteLink === 'appointments')
                                 ? row['id'] :value}`}><FiEdit /></Link>
                              </div>
                            </TableCell>
                          )
                        } else if (column.id === 'id') {
                          return (
                            <TableCell align='left' key={column.id+3}>
                              <div className='actionsCell'>
                                <div onClick={() => { setOpen(true); setDeletedId(value) }}><RiDeleteBin5Line /></div>
                              </div>
                            </TableCell>
                          )
                        }else if(column.id === 'day' || column.id === 'period' ){
                          return(
                          <TableCell align='center' key={column.id+1}>
                            {value?.name}
                          </TableCell>
                          )
                        }
                        else
                          return (
                            <TableCell align='center' key={column.id+4} >
                              {(column.id === 'image' || column.id === 'logo') ? <img src={value} className='tableImg' alt='' /> : value}
                            </TableCell>
                          );
                      })}

                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          // rowsPerPageOptions={[ 5, 10,15, 25, 100]}
          component="div"
          count={itemLength}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          dir='rtl'
          lang='ar'
        />
      </Paper>
      <DeleteDialog open={open} handleClose={() => {setOpen(false);setdeleteError('')}}  deleteError={deleteError}confirmDelete={confirmDelete}/>
    </>
  );
}