import * as React from 'react';
import ListTable from '../../Components/listPages/list';
import { getContacts } from '../../Api/contacts';

const columns = [
  { id: 'name', label: 'الاسم', minWidth: 170 },
  {
    id: 'phone',
    label: 'رقم الهاتف',
    maxWidth:"170px"
   
  },
  {
    id: 'subject',
    label: 'اسم الموضوع',
   
  },
  {
    id: 'message',
    label: 'الرسالة',
   
  },
  {id:'id'}

];

function createData(name,phone,subject,message,id) {
  return { name,phone,subject,message,id};
}



export default function ContactsList() {
  let [rows, setRows] = React.useState([])
  let [itemLength, setitemLength] = React.useState([])
  let editLink='/dashboard/contacts'
  let deleteLink='contacts'

  function getContactsList(page){
    let itemList = []
    getContacts(page).then((res) => {
      let items = res.data.data
      setitemLength(res.data.links.totalObjects)
      items.map((x) => {
        itemList.push(createData(x.name, x.phone,  x.subject, x.message,x.id))
        return ''
      })
      setRows(itemList)
    }).catch((err) => {
      console.log(err);
    })
  }
  React.useEffect(() => {
    getContactsList(1)
    //eslint-disable-next-line
  }, [])

  return (
    <>
      <div className='flex justify-between	'>
        <h1 className='pagesTitle'>الرسائل</h1>
      </div>
      <div className='tableContainer'>
        {rows && rows.length > 0 &&
          <ListTable rows={rows} setRows={setRows} columns={columns} editLink={editLink} getItems={getContactsList} itemLength={itemLength} deleteLink={deleteLink}/>
        }
      </div>
    </>
  );
}
