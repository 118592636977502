import React from 'react'
import Form from './Form'
import { addDepartment } from '../../../Api/departments';

export default function AddDepartment() {
    const onSubmit = async (e, data) => {
        e.preventDefault();
        //to send request
        return await addDepartment(data).then(res => {
            return 'done';          
        }).catch(err =>{
            return err.response.data.errors;
        })
    };
  return (
    <div ><Form title='اضافة ادارة' onSubmit={onSubmit}/></div>
  )
}
