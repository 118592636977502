import { https } from './https';
import { admin_url } from './../Configuration/config';

//get doctors list
export async function getDoctors(page){
    return await https.get(`${admin_url}/doctors?page=${page}`);
}
//get single doctors 
export async function getSingleDoctor(id){
    return await https.get(`${admin_url}/doctors/${id}`);
}
//add new doctors 
export async function addDoctor(data){
    return await https.post(`${admin_url}/doctors`,data);
}

// update Doctor 
export async function updateDoctor(id,data){
    return await https.post(`${admin_url}/doctors/${id}?_method=PUT`,data);
}