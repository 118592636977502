import React, { useState } from 'react';
import Route from './Routes';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import { AuthContextProvider } from './Contexts/authContext';
import { arSA } from '@mui/material/locale';

// Create rtl cache
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

const theme = createTheme({
  direction: 'rtl', 
  
},arSA);
function App() {
  return (
    <React.Fragment>
      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={theme}>
          <AuthContextProvider>
          <Route />
          </AuthContextProvider>
        </ThemeProvider>
      </CacheProvider>
    </React.Fragment>

  );
}

export default App;