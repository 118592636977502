import React, { useEffect, useState } from 'react'
import { PhotoIcon } from '@heroicons/react/24/solid'
import { onChangeHandler } from '../../../helpers/handleChange'
import { XCircleIcon } from '@heroicons/react/24/solid'
import { useNavigate } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { getSingleDepartment } from '../../../Api/departments';
import { getCategoryTypes } from '../../../Api/categories';
import Seo from '../../../Components/seo/seo';

export default function Form(props) {
    let [category, setCategory] = useState({
        name_ar: "",
        name_en: "",
        desc_ar: "",
        desc_en: "",
        slug: "",
        status: 2,
        featured: 0,
        rank: "",
        type: "",
        image: ""
    })
    let [img, setImg] = useState()
    let [errors, setErrors] = useState()
    let [types, settypes] = useState()
    let navigate = useNavigate();

    useEffect(() => {
        if (props.itemId) {
            getSingleDepartment(props.itemId).then((res) => {
                let x = res.data.data
                setImg(x.image)
                x.image = ''
                setCategory(x)
            }).catch((err) => {
                console.log(err);
            })
        }
    }, [props.itemId])

    useEffect(() => {
        getCategoryTypes().then((res) => {
            settypes(res.data.data)
        })
    }, [])

    // onsubmit
    const confirmHandler = async (e) => {
        e.preventDefault();
        let formData = new FormData()
        for (const key in category) {
            formData.append(key, category[key]);
        }
    
        let submitProps;
        if (props.itemId) {
            submitProps = await props.onSubmit(e, category?.id, formData);
        } else {
            submitProps = await props.onSubmit(e, formData);
        }
        if (submitProps === "done") {
            navigate(-1);
        } else {
            setErrors(submitProps);
        }
    }

    function removeImg() {
        setImg()
        let cat = { ...category }
        cat['icon'] = ''
        setCategory(cat)
    }
    return (
        <div>
            <form onSubmit={confirmHandler}>
                <div className="space-y-12">
                    <h2 className="formTitle">{props?.title} </h2>
                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-3">
                            <label htmlFor="name_ar" className="block text-sm font-medium leading-6 text-gray-900">
                                الاسم (العربي)
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, category, setCategory, errors, setErrors)}
                                    type="text"
                                    name="name_ar"
                                    id="name_ar"
                                    value={category?.name_ar}
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.name_ar}</div>
                        </div>

                        <div className="sm:col-span-3">
                            <label htmlFor="name_en" className="block text-sm font-medium leading-6 text-gray-900">
                                الاسم (الانجليزية)
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, category, setCategory, errors, setErrors)}
                                    type="text"
                                    name="name_en"
                                    id="name_en"
                                    value={category?.name_en}
                                    autoComplete="family-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.name_en}</div>
                        </div>
                        <div className="col-span-full">
                            <label htmlFor="desc_ar" className="block text-sm font-medium leading-6 text-gray-900">
                                الوصف (عربي)
                            </label>
                            <div className="mt-2">
                                <textarea
                                    onChange={(e) => onChangeHandler(e, category, setCategory, errors, setErrors)}
                                    id="desc_ar"
                                    name="desc_ar"
                                    rows={3}
                                    value={category?.desc_ar}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"

                                />
                            </div>
                            <div className='errorText'>{errors?.desc_ar}</div>
                        </div>
                        <div className="col-span-full">
                            <label htmlFor="desc_en" className="block text-sm font-medium leading-6 text-gray-900">
                                الوصف (الانجليزية)
                            </label>
                            <div className="mt-2">
                                <textarea
                                    onChange={(e) => onChangeHandler(e, category, setCategory, errors, setErrors)}
                                    id="desc_en"
                                    name="desc_en"
                                    rows={3}
                                    value={category?.desc_en}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.desc_en}</div>
                        </div>

                        <div className="sm:col-span-4">
                            <label htmlFor="slug" className="block text-sm font-medium leading-6 text-gray-900">
                                slug
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, category, setCategory, errors, setErrors)}
                                    id="slug"
                                    name="slug"
                                    type="slug"
                                    value={category?.slug}
                                    className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                            </div>
                            <div className='errorText'>{errors?.slug}</div>
                        </div>
                        <div className="sm:col-span-4">
                            <label htmlFor="rank" className="block text-sm font-medium leading-6 text-gray-900">
                                الترتيب
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, category, setCategory, errors, setErrors)}
                                    id="rank"
                                    name="rank"
                                    type="number"
                                    value={category?.rank}
                                    className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                            </div>
                            <div className='errorText'>{errors?.rank}</div>
                        </div>
                        <div className="sm:col-span-3">
                            <FormControlLabel control={<Switch name='featured' checked={category?.featured === 1 ? true : false} onChange={(e) => onChangeHandler(e, category, setCategory, errors, setErrors)} />} label="متميز" />
                            <div className='errorText'>{errors?.featured}</div>
                        </div>
                        <div className="sm:col-span-3">
                            <FormControlLabel control={<Switch name='status' checked={category?.status === 1 ? true : false} onChange={(e) => onChangeHandler(e, category, setCategory, errors, setErrors)} />} label="فعال" />
                            <div className='errorText'>{errors?.status}</div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor="type" className="block text-sm font-medium leading-6 text-gray-900">
                                النوع
                            </label>
                            <div className="mt-2">
                                <select
                                    onChange={(e) => onChangeHandler(e, category, setCategory, errors, setErrors)}
                                    id="type"
                                    name="type"
                                    value={category?.type}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                >
                                    {types?.length ? types.map((type) => {
                                        return (
                                            <option value={type?.id}>{type?.name}</option>
                                        )
                                    }) : ""}
                                </select>
                            </div>
                            <div className='errorText'>{errors?.type}</div>
                        </div>

                        <div className="col-span-full">

                        </div>


                        <div className='sm:col-span-2'>
                            <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                                الصورة
                            </label>
                            {img ?
                                <div className="mt-2 uploadedImg flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                    <XCircleIcon onClick={removeImg} />
                                    <img src={img} alt='' />
                                </div>
                                :
                                <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                    <div className="text-center">
                                        <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                                        <div className="mt-4 flex justify-center text-sm leading-6 text-gray-600">
                                            <label
                                                htmlFor="image"
                                                className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                            >
                                                <span>حمل الصورة</span>
                                                <input id="image" name="image" type="file" className="sr-only"
                                                    onChange={(e) => onChangeHandler(e, category, setCategory, errors, setErrors, setImg)}
                                                />
                                            </label>
                                        </div>
                                        <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
                                    </div>
                                </div>
                            }
                            <div className='errorText'>{errors?.image}</div>

                        </div>
                    </div>

                </div>

                <div className="mt-6 flex items-center justify-end ">

                    <button
                        type="submit"
                        className="submitBtn"
                    >
                        حفظ
                    </button>
                </div>
            </form>
            {props?.itemId && category && category.id ?
                <Seo objectType={5} objectId={category?.id} />
                : ''}
        </div>
    )
}
