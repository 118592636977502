import { PlusCircleIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import BlogCard from '../../Components/listPages/blogCard'
import { getBlogs } from '../../Api/blogs'
import DeleteDialog from '../../Components/popups/delete'
import { deleteItem } from '../../Api/departments'

export default function NewsList() {
    let [blogs,setBlogs]=useState()
    let [open, setOpen] = useState()
    let [deletedId, setDeletedId] = useState()
    let [deleteError, setdeleteError] = useState()
    useEffect(() => {
        getBlogs().then((res) => {
          let items = res.data.data
          setBlogs(items)
        }).catch((err) => {
          console.log(err);
        })
        //eslint-disable-next-line
      }, [])
      function deleteBlog(){
        deleteItem(`blogs/${deletedId}`).then((res) => {
        }).catch((err) => {
            setdeleteError(err.response.data.message)
        })
      }
    
    return (
        <>
            <div className='flex justify-between	'>
                <h1 className='pagesTitle'>الاخبار</h1>
                <Link to='/dashboard/news/add' className='addBtn'>
                    <span>اضافة خبر</span>
                    <PlusCircleIcon className='navBtn' />
                </Link>
            </div>
            <div className='tableContainer'>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                    {blogs && blogs.map((blog,i)=>{
                        if(blog.type ==='News')
                        return(
                            <div key={blog.id}><BlogCard blog={blog} setDeletedId={setDeletedId} setOpen={setOpen} deleteError={deleteError}  type='news'/></div>
                        )
                        return ''
                    })}
                </div>
            </div>
            <DeleteDialog open={open} handleClose={() =>{ setOpen(false);setdeleteError('')}} confirmDelete={deleteBlog} deleteError={deleteError} />
        
        </>
    )
}
