import React, { useEffect, useState } from 'react'
import { PhotoIcon } from '@heroicons/react/24/solid'
import { onChangeHandler } from '../../../helpers/handleChange'
import { XCircleIcon } from '@heroicons/react/24/solid'
import { useNavigate } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { getCategories } from '../../../Api/categories';
import DatePicker, { registerLocale } from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import ar from 'date-fns/locale/ar'
import moment from 'moment/moment';
import { getSingleOffer } from '../../../Api/offers';
import Seo from '../../../Components/seo/seo';
registerLocale('ar', ar)
export default function Form(props) {
    let [offer, setoffer] = useState({
        name_ar: "",
        name_en: "",
        desc_ar: "",
        desc_en: "",
        price: '',
        sale_price: '',
        slug: "",
        status: 2,
        rank: "",
        featured: 0,
        image: ""
    })
    let [img, setImg] = useState()
    let [errors, setErrors] = useState()
    let [categories, setcategories] = useState()
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);
    let navigate = useNavigate();
    useEffect(() => {
        if (props.itemId) {
            getSingleOffer(props.itemId).then((res) => {
                let x = res.data.data
                setStartDate(new Date(x.date_from))
                setEndDate(new Date(x.date_to))
                setImg(x.image)
                x.image = ''
                setoffer(x)
            }).catch((err) => {
                console.log(err);
            })
        }
    }, [props.itemId])
    useEffect(() => {
        getCategories().then((res) => {
            setcategories(res.data.data)
        })
    }, [])

    // onsubmit
    const confirmHandler = async (e) => {
        e.preventDefault();
        let formData = new FormData()
        for (const key in offer) {
            formData.append(key, offer[key]);
        }
        // for (var pair of formData.entries()) {
        //     console.log(pair[0] + ', ' + pair[1]);
        // }
        let submitProps;
        if (props.itemId) {
            submitProps = await props.onSubmit(e, offer?.id, formData);
        } else {
            submitProps = await props.onSubmit(e, formData);
        }
        if (submitProps === "done") {
            navigate(-1);

        } else {
            setErrors(submitProps)
        }
    }

    function removeImg() {
        setImg()
        let cat = { ...offer }
        cat['icon'] = ''
        setoffer(cat)
    }
 

    const onChangeDate = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);

        let cat = { ...offer }
        cat['date_from'] = moment(start).format('YYYY-MM-DD')
        cat['date_to'] = moment(end).format('YYYY-MM-DD')
        setoffer(cat)
    };
    return (
        <div>
            <form onSubmit={confirmHandler}>
                <div className="space-y-12">
                    <h2 className="formTitle">{props?.title} </h2>
                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-3">
                            <label htmlFor="name_ar" className="block text-sm font-medium leading-6 text-gray-900">
                                الاسم (العربي)
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, offer, setoffer, errors, setErrors)}
                                    required
                                    type="text"
                                    name="name_ar"
                                    id="name_ar"
                                    autoComplete="given-name"
                                    value={offer?.name_ar}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.name_ar}</div>
                        </div>

                        <div className="sm:col-span-3">
                            <label htmlFor="name_en" className="block text-sm font-medium leading-6 text-gray-900">
                                الاسم (الانجليزية)
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, offer, setoffer, errors, setErrors)}
                                    required
                                    type="text"
                                    name="name_en"
                                    id="name_en"
                                    autoComplete="family-name"
                                    value={offer?.name_en}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.name_en}</div>
                        </div>
                        <div className="col-span-full">
                            <label htmlFor="desc_ar" className="block text-sm font-medium leading-6 text-gray-900">
                                الوصف (عربي)
                            </label>
                            <div className="mt-2">
                                <textarea
                                    onChange={(e) => onChangeHandler(e, offer, setoffer, errors, setErrors)}
                                    id="desc_ar"
                                    name="desc_ar"
                                    value={offer?.desc_ar}
                                    rows={3}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    defaultValue={''}
                                />
                            </div>
                            <div className='errorText'>{errors?.desc_ar}</div>
                        </div>
                        <div className="col-span-full">
                            <label htmlFor="desc_en" className="block text-sm font-medium leading-6 text-gray-900">
                                الوصف (الانجليزية)
                            </label>
                            <div className="mt-2">
                                <textarea
                                    onChange={(e) => onChangeHandler(e, offer, setoffer, errors, setErrors)}
                                    id="desc_en"
                                    name="desc_en"
                                    value={offer?.desc_en}
                                    rows={3}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    defaultValue={''}
                                />
                            </div>
                            <div className='errorText'>{errors?.desc_en}</div>

                        </div>

                        <div className="sm:col-span-4">
                            <label htmlFor="slug" className="block text-sm font-medium leading-6 text-gray-900">
                                slug
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, offer, setoffer, errors, setErrors)}
                                    required
                                    id="slug"
                                    name="slug"
                                    type="slug"
                                    value={offer?.slug}

                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.slug}</div>
                        </div>
                        <div className="sm:col-span-4">
                            <label htmlFor="slug" className="block text-sm font-medium leading-6 text-gray-900">
                                التاريخ
                            </label>
                            <div className="mt-2">
                                <DatePicker
                                    selected={startDate}
                                    onChange={onChangeDate}
                                    minDate={new Date()}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectsRange
                                    locale='ar'
                                    className='ring-gray-300'
                                />
                            </div>
                            <div className='errorText'>{errors?.date_from}</div>
                            <div className='errorText'>{errors?.date_to}</div>
                        </div>


                        <div className="sm:col-span-3">
                            <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
                                السعر
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, offer, setoffer, errors, setErrors)}
                                    required
                                    type="number"
                                    name="price"
                                    id="price"
                                    value={offer?.price}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.price}</div>

                        </div>

                        <div className="sm:col-span-3">
                            <label htmlFor="sale_price" className="block text-sm font-medium leading-6 text-gray-900">
                                سعر البيع
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, offer, setoffer, errors, setErrors)}
                                    type="number"
                                    name="sale_price"
                                    id="sale_price"
                                    value={offer?.sale_price}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.sale_price}</div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor="cat_id" className="block text-sm font-medium leading-6 text-gray-900">
                                القسم
                            </label>
                            <div className="mt-2">
                                <select
                                    onChange={(e) => onChangeHandler(e, offer, setoffer, errors, setErrors)}
                                    required
                                    id="cat_id"
                                    name="cat_id"
                                    value={offer?.cat_id}
                                    className="selectItem block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                >
                                    {categories && categories.map((cat) => {
                                        return (
                                            <option key={cat.id} value={cat.id} >{cat?.name_ar}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            <div className='errorText'>{errors?.cat_id}</div>
                        </div>

                        <div className="sm:col-span-4">
                            <label htmlFor="rank" className="block text-sm font-medium leading-6 text-gray-900">
                                الترتيب
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, offer, setoffer, errors, setErrors, errors, setErrors)}
                                    id="rank"
                                    name="rank"
                                    type="number"
                                    value={offer?.rank}
                                    className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                            </div>
                            <div className='errorText'>{errors?.rank}</div>

                        </div>
                        <div className="sm:col-span-3">
                            <FormControlLabel control={<Switch name='featured' checked={offer?.featured === 1 ? true : false} onChange={(e) => onChangeHandler(e, offer, setoffer, errors, setErrors, errors, setErrors)} />} label="متميز" />
                            <div className='errorText'>{errors?.featured}</div>
                        </div>
                        <div className="sm:col-span-3">
                            <FormControlLabel control={<Switch name='status'  checked={offer?.status === 1 ? true : false} onChange={(e) => onChangeHandler(e, offer, setoffer, errors, setErrors, errors, setErrors)} />} label="فعال" />
                            <div className='errorText'>{errors?.status}</div>
                        </div>

                        <div className="col-span-full">

                        </div>


                        <div className='sm:col-span-2'>
                            <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                                الصورة
                            </label>
                            {img ?
                                <div className="mt-2 uploadedImg flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                    <XCircleIcon onClick={removeImg} />
                                    <img src={img} alt='' />
                                </div>
                                :
                                <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                    <div className="text-center">
                                        <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                                        <div className="mt-4 flex justify-center text-sm leading-6 text-gray-600">
                                            <label
                                                htmlFor="image"
                                                className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                            >
                                                <span>حمل الصورة</span>
                                                <input id="image" name="image" type="file" className="sr-only"
                                                    onChange={(e) => onChangeHandler(e, offer, setoffer, errors, setErrors, setImg)}
                                                />
                                            </label>
                                        </div>
                                        <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className='errorText'>{errors?.image}</div>

                    </div>

                </div>

                <div className="mt-6 flex items-center justify-end ">

                    <button
                        type="submit"
                        className="submitBtn"
                    >
                        حفظ
                    </button>
                </div>
            </form>
            {props?.itemId  && offer && offer.id ?
                <Seo objectType={4} objectId={offer?.id} />
                : ''}
        </div>
    )
}
