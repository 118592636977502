import React, { useEffect, useState } from 'react'
import { confirmHandler } from '../../../../helpers/confirmHandler'
import { useNavigate } from 'react-router-dom'
import { onChangeHandler } from '../../../../helpers/handleChange'
import { XCircleIcon } from '@heroicons/react/24/solid'
import { PhotoIcon } from '@heroicons/react/24/solid'
import { getBannerTypes, getBannerTypesObjs, getSingleBanner } from '../../../../Api/pages'
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { CgSpinner } from 'react-icons/cg';

export default function Form(props) {
    let [errors, setErrors] = useState()
    let [banner, setbanner] = useState()
    let [loading, setLoading] = useState()
    let [bannerTypes, setbannerTypes] = useState()
    let [selectedType, setselectedType] = useState()
    let [linktypes, setlinktypes] = useState()
    let [img, setImg] = useState()
    let navigate = useNavigate()

    useEffect(()=>{
        if(props.itemId){
            getSingleBanner(props.itemId).then((res) => {
                let x = res.data.data
                setImg(x.image_ar)
                if(x.link_type){
                    setselectedType(x.link_type)
                }
                setbanner(x)
            }).catch((err) => {
                console.log(err);
            })
        }
    },[props.itemId])

    useEffect(() => {
        getBannerTypes().then((res) => {
            setbannerTypes(res.data.data)
        })
    }, [])

    useEffect(() => {
        if(selectedType){
            let x = { ...banner }
            x['link'] = ''
            setbanner(x)
            getBannerTypesObjs(selectedType).then((res) => {
                setlinktypes(res.data.data)
            })
        }
    //eslint-disable-next-line
    }, [selectedType])
    
    function removeImg() {
        setImg()
        let x = { ...banner }
        x['image_ar'] = ''
        setbanner(x)
    }
    return (
        <div>
            <form onSubmit={(e) => confirmHandler(e, banner, props, setErrors, navigate, setLoading)} >
                <div className="space-y-12">
                    <h2 className="formTitle">{props?.title} </h2>
                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        {/* ******************** name ********************  */}
                        <div className="sm:col-span-4">
                            <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                                الاسم (العربي)
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, banner, setbanner, errors, setErrors)}
                                    required
                                    type="text"
                                    name="name"
                                    id="name"
                                    value={banner?.name}
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.name}</div>
                        </div>
                        {/* ********************title ********************  */}
                        <div className="sm:col-span-3">
                            <label htmlFor="title_ar" className="block text-sm font-medium leading-6 text-gray-900">
                                العنوان (العربي)
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, banner, setbanner, errors, setErrors)}
                                    required
                                    type="text"
                                    name="title_ar"
                                    id="title_ar"
                                    value={banner?.title_ar}
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.title_ar}</div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor="title_en" className="block text-sm font-medium leading-6 text-gray-900">
                                العنوان (انجليزي)
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, banner, setbanner, errors, setErrors)}
                                    required
                                    type="text"
                                    name="title_en"
                                    id="title_en"
                                    value={banner?.title_en}
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.title_en}</div>
                        </div>
                        {/* ********************sub title ********************  */}
                        <div className="sm:col-span-3">
                            <label htmlFor="sec_title_ar" className="block text-sm font-medium leading-6 text-gray-900">
                                العنوان الثاني (العربي)
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, banner, setbanner, errors, setErrors)}
                                    required
                                    type="text"
                                    name="sec_title_ar"
                                    id="sec_title_ar"
                                    value={banner?.sec_title_ar}
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.sec_title_ar}</div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor="sec_title_en" className="block text-sm font-medium leading-6 text-gray-900">
                                العنوان الثاني (انجليزي)
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, banner, setbanner, errors, setErrors)}
                                    required
                                    type="text"
                                    name="sec_title_en"
                                    id="sec_title_en"
                                    value={banner?.sec_title_en}
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.sec_title_en}</div>
                        </div>
                        {/* ********************description ********************  */}

                        <div className="col-span-full">
                            <label htmlFor="desc_ar" className="block text-sm font-medium leading-6 text-gray-900">
                                الوصف (العربي)
                            </label>
                            <div className="mt-2">
                                <textarea
                                    onChange={(e) => onChangeHandler(e, banner, setbanner, errors, setErrors)}
                                    id="desc_ar"
                                    name="desc_ar"
                                    rows={3}
                                    value={banner?.desc_ar}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.desc_ar}</div>
                        </div>
                        <div className="col-span-full">
                            <label htmlFor="desc_en" className="block text-sm font-medium leading-6 text-gray-900">
                                الوصف (انجليزي)
                            </label>
                            <div className="mt-2">
                                <textarea
                                    onChange={(e) => onChangeHandler(e, banner, setbanner, errors, setErrors)}
                                    id="desc_en"
                                    name="desc_en"
                                    rows={3}
                                    value={banner?.desc_en}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.desc_en}</div>
                        </div>

                        <div className="sm:col-span-3">
                            <label htmlFor="link_type" className="block text-sm font-medium leading-6 text-gray-900">
                                القسم
                            </label>
                            <div className="mt-2">
                                <select
                                    onChange={(e) => { onChangeHandler(e, banner, setbanner, errors, setErrors); setselectedType(e.target.value) }}
                                    required
                                    id="link_type"
                                    name="link_type"
                                    value={banner?.link_type ?? ''}
                                    className="selectItem block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                >
                                    {bannerTypes && bannerTypes.map((banner) => {
                                        return (
                                            <option key={banner.id} value={banner.id}>{banner?.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            <div className='errorText'>{errors?.link_type}</div>
                        </div>
                        {selectedType ?
                            <div className="sm:col-span-3">
                                <label htmlFor="link" className="block text-sm font-medium leading-6 text-gray-900">
                                    نوع الرابط
                                </label>
                                <div className="mt-2">
                                    <select
                                        onChange={(e) => onChangeHandler(e, banner, setbanner, errors, setErrors)}
                                        required
                                        id="link"
                                        name="link"
                                        value={banner?.link ?? ''}
                                        className="selectItem block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                    >
                                        {linktypes && linktypes.map((banner) => {
                                            return (
                                                <option key={banner.id} value={banner.id}>{banner?.name_ar}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className='errorText'>{errors?.link}</div>
                            </div>
                            : ''}
                            <div className='col-span-full'></div>
                        {/* ******************** Image ********************  */}
                        <div className='sm:col-span-2'>
                            <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                                الصورة
                            </label>
                            {img ?
                                <div className="mt-2 uploadedImg flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                    <XCircleIcon onClick={removeImg} />
                                    <img src={img} alt='' />
                                </div>
                                :
                                <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                    <div className="text-center">
                                        <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                                        <div className="mt-4 flex justify-center text-sm leading-6 text-gray-600">
                                            <label
                                                htmlFor="image"
                                                className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                            >
                                                <span>حمل الصورة</span>
                                                <input id="image" name="image_ar" type="file" className="sr-only"
                                                    onChange={(e) => onChangeHandler(e, banner, setbanner, errors, setErrors, setImg)}
                                                />
                                            </label>
                                        </div>
                                        <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
                                    </div>
                                </div>
                            }
                            <div className='errorText	'>{errors && errors['image']}</div>

                        </div>
                        <div className='col-span-full'></div>
                        <div className="sm:col-span-2">
                            <FormControlLabel control={<Switch name='status' checked={banner?.status === 1 ? true : false} onChange={(e) => onChangeHandler(e, banner, setbanner, errors, setErrors)} />} label="فعال" />
                            <div className='errorText	'>{errors?.status}</div>
                        </div>
                    </div>
                </div>
                <div className="mt-6 flex items-center justify-end ">
                    {!loading ?
                        <button type="submit" className="submitBtn">
                            حفظ
                        </button>
                        :
                        <button type="button" className="submitBtn flex" disabled>
                            <CgSpinner className='animate-spin' />
                            جاري الحفظ
                        </button>
                    }
                </div>
            </form>
        </div>
    )
}
