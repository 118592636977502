import { PlusCircleIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getBanners } from '../../../Api/pages'
import { deleteItem } from '../../../Api/departments'
import DeleteDialog from '../../../Components/popups/delete'
import BannerCard from '../../../Components/listPages/bannerCard'

export default function HomeBannerList() {
    let [banners,setbanners]=useState()
    let [open, setOpen] = useState(false)
    let [deletedId, setDeletedId] = useState()
    let [deleteError, setdeleteError] = useState()
    useEffect(() => {
        getBanners().then((res) => {
          let items = res.data.data
          setbanners(items)
        }).catch((err) => {
          console.log(err);
        })
        //eslint-disable-next-line
      }, [])
      function deleteBlog(){
        deleteItem(`banners/${deletedId}`).then((res) => {
        }).catch((err) => {
            setdeleteError(err.response.data.message)
        })
      }
    
    
    return (
        <>
            <div className='flex justify-between'>
                <h1 className='pagesTitle'>لافتة الصفحة الرئيسية</h1>
                <Link to='/dashboard/home/banners/add' className='addBtn'>
                    <span>اضافة لافتة</span>
                    <PlusCircleIcon className='navBtn' />
                </Link>
            </div>
            <div className='tableContainer'>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                    {banners && banners.map((banner,i)=>{
                        return(
                            <div key={banner.id}><BannerCard banner={banner} setDeletedId={setDeletedId} setOpen={setOpen} deleteError={deleteError}  /></div>
                        )
                    })}
                </div>
            </div>
            <DeleteDialog open={open} handleClose={() =>{ setOpen(false);setdeleteError('')}} confirmDelete={deleteBlog} deleteError={deleteError} />
        </>
    )
}
