import { useContext } from "react";
import authContext from "../Contexts/authContext";
import { Navigate, Route, Routes } from "react-router-dom";
import { useState } from 'react';

import '../styles/Layouts/dashboard.scss'
import '../styles/pages/overview.scss'
import Overview from "../Pages/dashboard/overview";
import Sidebar from "../Components/sidebar";
import Header from "../Components/header";
import CategoriesList from "../Pages/Categories/list";
import Add from "../Pages/Categories/categoriesForm/Add";
import DepartmentsList from "../Pages/Departments/list";
import AddDepartment from "../Pages/Departments/departmentFrom/Add";
import EditCategory from "../Pages/Categories/categoriesForm/Edit";
import ServicesList from "../Pages/Services/list";
import AddService from "../Pages/Services/serviceForm/Add";
import ClinicsList from "../Pages/Clinics/list";
import DoctorsList from "../Pages/Doctors/list";
import OffersList from "../Pages/Offers/list";
import SurgeriesList from "../Pages/Surgeries/list";
import PartnersList from "../Pages/Partners/list";
import BlogsList from "../Pages/Blogs/list";
import NewsList from "../Pages/News/list";
import EditDepartment from "../Pages/Departments/departmentFrom/Edit";
import AddClinic from "../Pages/Clinics/clinicForm/Add";
import AddNewSurgery from "../Pages/Surgeries/surgeriesForm/Add";
import AddNewOffer from "../Pages/Offers/offersForm/Add";
import AddNewPartner from "../Pages/Partners/partnerForm/Add";
import AddBlog from "../Pages/Blogs/blogForm/Add";
import AddNews from "../Pages/News/newsForm/Add";
import VisitsList from "../Pages/HomeVisits/list";
import AddNewVisit from "../Pages/HomeVisits/visitsForm/Add";
import EditOffer from "../Pages/Offers/offersForm/Edit";
import EditDoctor from "../Pages/Doctors/doctorForm/Edit";
import AddDoctor from "../Pages/Doctors/doctorForm/Add";
import EditSurgery from "../Pages/Surgeries/surgeriesForm/Edit";
import EditService from "../Pages/Services/serviceForm/Edit";
import EditBlog from "../Pages/Blogs/blogForm/Edit";
import EditNews from "../Pages/News/newsForm/Edit";
import EditClinic from "../Pages/Clinics/clinicForm/Edit";
import EditPartner from "../Pages/Partners/partnerForm/Edit";
import EditVisit from "../Pages/HomeVisits/visitsForm/Edit";
import MediaList from "../Pages/Media/list";
import AddMedia from "../Pages/Media/mediaForm/Add";
import EditMedia from "../Pages/Media/mediaForm/Edit";
import ReservationsList from "../Pages/Reservations/list";
import AddNewReservation from "../Pages/Reservations/reservationForm/Add";
import EditReservation from "../Pages/Reservations/reservationForm/Edit";
import AppointmentsList from "../Pages/Appointments/list";
import AddAppointment from "../Pages/Appointments/appointmentsForm/Add";
import EditAppointment from "../Pages/Appointments/appointmentsForm/Edit";
import HomeBannerList from "../Pages/Home/banner/list";
import AddBanner from "../Pages/Home/banner/bannerForm/Add";
import EditBanner from "../Pages/Home/banner/bannerForm/Edit";
import TermsAndConditions from "../Pages/staticPages/termsAndConditions";
import PrivacyPolicy from "../Pages/staticPages/privacyPolicy";
import AboutUs from "../Pages/staticPages/aboutUs";
import AdminProfile from "../Pages/staticPages/AdminProfile";
import Settings from "../Pages/Settings/settings";
import ContactsList from "../Pages/Contacts/list";

export default function DashboardLayout() {
    let { isAuthorized } = useContext(authContext);
    const [sidebarOpen, setSidebarOpen] = useState(false);

    if (!isAuthorized) {
        return <Navigate to="/login" replace />;
    }

    return (
        <>
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            <div className="lg:pr-72">
                <Header setSidebarOpen={setSidebarOpen} />
                <main className="py-10">
                    <div className="px-4 sm:px-6 lg:px-8">
                        <Routes>
                            <Route path="overview" element={<Overview />} />
                            <Route path='categories/' >
                                <Route path='' element={<CategoriesList />} />
                                <Route path='add' element={<Add />} />
                                <Route path=':id' element={<EditCategory />} />
                            </Route>
                            <Route path='departments/' >
                                <Route path='' element={<DepartmentsList />} />
                                <Route path='add' element={<AddDepartment />} />
                                <Route path=':id' element={<EditDepartment />} />
                            </Route>
                            <Route path='services/' >
                                <Route path='' element={<ServicesList />} />
                                <Route path='add' element={<AddService />} />
                                <Route path=':id' element={<EditService />} />
                            </Route>
                            <Route path='clinics/' >
                                <Route path='' element={<ClinicsList />} />
                                <Route path='add' element={<AddClinic />} />
                                <Route path=':id' element={<EditClinic />} />
                            </Route>
                            <Route path='doctors/' >
                                <Route path='' element={<DoctorsList />} />
                                <Route path='add' element={<AddDoctor />} />
                                <Route path=':id' element={<EditDoctor />} />
                            </Route>
                            <Route path='offers/' >
                                <Route path='' element={<OffersList />} />
                                <Route path='add' element={<AddNewOffer />} />
                                <Route path=':id' element={<EditOffer />} />
                            </Route>
                            <Route path='surgeries/' >
                                <Route path='' element={<SurgeriesList />} />
                                <Route path='add' element={<AddNewSurgery />} />
                                <Route path=':id' element={<EditSurgery />} />
                            </Route>
                            <Route path='partners/' >
                                <Route path='' element={<PartnersList />} />
                                <Route path='add' element={<AddNewPartner />} />
                                <Route path=':id' element={<EditPartner />} />
                            </Route>
                            <Route path='blogs/' >
                                <Route path='' element={<BlogsList />} />
                                <Route path='add' element={<AddBlog />} />
                                <Route path=':id' element={<EditBlog />} />
                            </Route>
                            <Route path='news/' >
                                <Route path='' element={<NewsList />} />
                                <Route path='add' element={<AddNews />} />
                                <Route path=':id' element={<EditNews />} />
                            </Route>
                            <Route path='media/' >
                                <Route path='' element={<MediaList />} />
                                <Route path='add' element={<AddMedia />} />
                                <Route path=':id' element={<EditMedia />} />
                            </Route>
                            <Route path='reservations/' >
                                <Route path='' element={<ReservationsList />} />
                                <Route path='add' element={<AddNewReservation />} />
                                <Route path=':id' element={<EditReservation />} />
                            </Route>
                            <Route path='appointments/' >
                                <Route path='' element={<AppointmentsList />} />
                                <Route path='add' element={<AddAppointment />} />
                                <Route path=':id' element={<EditAppointment />} />
                            </Route>
                            <Route path='home/' >
                                <Route path='' element={<HomeBannerList />} />
                                <Route path='banners/'>
                                    <Route path='add' element={<AddBanner />} />
                                    <Route path=':id' element={<EditBanner />} />
                                </Route>
                            </Route>
                            <Route path='pages'>
                                <Route path='terms-and-conditions/' element={<TermsAndConditions />} />
                                <Route path='privacy-policy/' element={<PrivacyPolicy />} />
                                <Route path='about-us/' element={<AboutUs />} />
                            </Route>
                            <Route path='profile' element={<AdminProfile />} />
                            <Route path='settings' element={<Settings />} />
                            <Route path='contacts' element={<ContactsList />} />

                            <Route path='home-visits/' >
                                <Route path='' element={<VisitsList />} />
                                <Route path='add' element={<AddNewVisit />} />
                                <Route path=':id' element={<EditVisit />} />
                            </Route>
                        </Routes>
                    </div>
                </main>
            </div>
        </>
    );
}




