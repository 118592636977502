import { createContext, useEffect } from "react";
import { useState } from "react";
import { adminProfile } from "../Api/auth";
// import { useNavigate } from "react-router-dom";

let authContext = createContext('');
export function AuthContextProvider(props){ 
    let[isAuthorized, setisAuthorized] = useState('');
    let[authUser, setauthUser] = useState('');
    // let Navigate=useNavigate()
    function setAuthorization(x) {
        setisAuthorized(x);
        //console.log(permissions);
    }
    useEffect(()=>{
       let x= localStorage.getItem('apc_admin_token')
        if (x){
            // Navigate('/dashboard/overview')
            setisAuthorized('authorized')
            adminProfile().then((res)=>{
                setauthUser(res.data.data)
            })
        }else{
            setisAuthorized('not-authorized')
        }
    },[])

    return (
        <authContext.Provider value={{isAuthorized,setAuthorization,authUser, setauthUser }}>
            {props.children}
        </authContext.Provider>
    )
}
export default authContext;