import { PlusCircleIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getMedia } from '../../Api/media'
import MediaCard from '../../Components/listPages/mediaCard'
import { deleteItem } from '../../Api/departments'
import DeleteDialog from '../../Components/popups/delete'

export default function MediaList() {
    let [media, setmedia] = useState()
    let [open, setOpen] = useState()
    let [deletedId, setDeletedId] = useState()
    let [deleteError, setdeleteError] = useState()
    useEffect(() => {
        getMedia().then((res) => {
            let items = res.data.data
            setmedia(items)
        }).catch((err) => {
            console.log(err);
        })
        //eslint-disable-next-line
    }, [])
    function deleteMedia() {
        deleteItem(`media/${deletedId}`).then((res) => {
        }).catch((err) => {
            setdeleteError(err.response.data.message)
        })
    }

    return (
        <>
            <div className='flex justify-between	'>
                <h1 className='pagesTitle'>الوسائط</h1>
                <Link to='/dashboard/media/add' className='addBtn'>
                    <span>اضافة وسائط</span>
                    <PlusCircleIcon className='navBtn' />
                </Link>
            </div>
            <div className='tableContainer'>
                <div className="grid grid-cols-4 gap-4">
                    {media && media.map((media, i) => {
                        return (
                            <div key={media.id}><MediaCard media={media} setOpen={setOpen} setDeletedId={setDeletedId} /></div>
                        )
                    })}
                </div>
            </div>
            <DeleteDialog open={open} handleClose={() =>{ setOpen(false);setdeleteError('')}} confirmDelete={deleteMedia} deleteError={deleteError} />

        </>
    )
}
