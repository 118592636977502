import { https } from './https';
import { admin_url } from './../Configuration/config';

//get home visits list
export async function getvisits(page){
    return await https.get(`${admin_url}/home-visits?page=${page}`);
}
//add new home-visits 
export async function addVisit(data){
    return await https.post(`${admin_url}/home-visits`,data);
}
//get single visit
export async function getSingleVisit(id){
    return await https.get(`${admin_url}/home-visits/${id}`);
}

//update single partner
export async function updateVisit(id,data){
    return await https.post(`${admin_url}/home-visits/${id}?_method=PUT`,data);
}