import { https } from './https';
import { admin_url } from './../Configuration/config';

//get Clinics list
export async function getClinics(page){
    return await https.get(`${admin_url}/clinics?page=${page}`);
}
//add new clinics 
export async function addClinic(data){
    return await https.post(`${admin_url}/clinics`,data);
}
//get single clinic
export async function getSingleClinic(slug){
    return await https.get(`${admin_url}/clinics/${slug}`);
}
//add new clinics 
export async function updateClinic(id,data){
    return await https.post(`${admin_url}/clinics/${id}?_method=PUT`,data);
}