import { https } from './https';
import { admin_url } from './../Configuration/config';

//get reservations list
export async function getReservations(page){
    return await https.get(`${admin_url}/reservations?page=${page}`);
}
//add new reservations 
export async function addReservation(data){
    return await https.post(`${admin_url}/reservations`,data);
}
//get single Departments 
export async function getSingleReservation(slug){
    return await https.get(`${admin_url}/reservations/${slug}`);
}
//add new reservations 
export async function updateReservation(id,data){
    return await https.post(`${admin_url}/reservations/${id}?_method=PUT`,data);
}