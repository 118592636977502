import React, { useEffect, useState } from 'react'
import { PhotoIcon } from '@heroicons/react/24/solid'
import { onChangeHandler } from '../../../helpers/handleChange'
import { XCircleIcon } from '@heroicons/react/24/solid'
import { useNavigate } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { getSingleDoctor } from '../../../Api/doctors';
import Select from 'react-select';
import { seletChangeHandler } from '../../../helpers/selectChange';
import { getOffers } from '../../../Api/offers';
import { getServices } from '../../../Api/services';
import { getCategories } from '../../../Api/categories';
// import { EditorState, ContentState, convertFromHTML } from 'draft-js';
// import { Editor } from 'react-draft-wysiwyg';
// import htmlToDraft from 'draftjs-to-html';
// import { convertContentToHTML } from '../../../helpers/convertTohtml';
import { CgSpinner } from 'react-icons/cg';
import Seo from '../../../Components/seo/seo';

export default function Form(props) {
    let [doctor, setdoctor] = useState({
        name_ar: "",
        name_en: "",
        jobtitle: "",
        exp_years: "",
        slug: "",
        price: 0,
        sale_price: 0,
        status: 2,
        featured: 2,
        rank: "",
        image: "",
        'lang_ids[]': [],
        'offers_ids[]': [],
        'services_ids[]': [],
        cat_id: '',
        insurance: 2,
        phone: '',
        phone2: '',
        whatsapp: '',
        email: "",
        facebook: "",
        twitter: '',
        linkedin: '',
        instagram: '',
        offers: []
    })
    // let [qualifies, setQualifies] = useState(EditorState.createEmpty())
    // let [studies, setStudies] = useState(EditorState.createEmpty())
    // let [conts, setConts] = useState(EditorState.createEmpty())
    let [errors, setErrors] = useState({})
    let [img, setImg] = useState()
    let navigate = useNavigate();
    let [categories, setcategories] = useState()
    let [offers, setoffers] = useState()
    let [services, setServices] = useState()
    const languages = [{ value: '1', label: 'العربية' }, { value: '2', label: 'الانجليزية' }]
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getCategories().then((res) => {
            setcategories(res.data.data)
        })
        getOffers().then((res) => {
            let data = res.data.data
            let options = []
            for (let i = 0; i < data.length; i++) {
                options.push({
                    value: data[i].id,
                    label: data[i].name_ar
                })
            }
            setoffers(options);
        })
        getServices().then((res) => {
            let data = res.data.data
            let options = []
            for (let i = 0; i < data.length; i++) {
                options.push({
                    value: data[i].id,
                    label: data[i].name_ar
                })
            }
            setServices(options);
        })
    }, [])

    let [offersIds, setoffersIds] = useState([])
    let [servicesIds, setservicesIds] = useState([])
    let [langsIds, setlangsIds] = useState([])

    useEffect(() => {
        if (props.itemId) {
            getSingleDoctor(props.itemId).then((res) => {
                let x = res.data.data
                setImg(x.image)
                x.image = ''
           
                // offers for select 
                let offer = []
                let offer_ids = []
                for (let i = 0; i < x?.offers?.length; i++) {
                    offer.push({
                        value: x.offers[i].id,
                        label: x.offers[i].name_ar
                    })
                    offer_ids.push(x.offers[i].id)
                }
                x['offers_ids[]'] = offer_ids
                setoffersIds(offer);
                // services for select 
                let services = []
                let services_ids = []
                for (let i = 0; i < x?.services?.length; i++) {
                    services.push({
                        value: x.services[i].id,
                        label: x.services[i].name_ar
                    })
                    services_ids.push(x.services[i].id)
                }
                x['services_ids[]'] = services_ids
                setservicesIds(services);
                // services for select 
                let langs = []
                let langs_ids = []
                for (let i = 0; i < x?.languages?.length; i++) {
                    langs.push({
                        value: x.languages[i].id,
                        label: x.languages[i].name_ar
                    })
                    langs_ids.push(x.languages[i].id)
                }
                x['langs_ids[]'] = langs_ids
                setlangsIds(langs);
                setdoctor(x)
            }).catch((err) => {
                console.log(err);
            })
        }
    }, [props.itemId])

    // onsubmit
    const confirmHandler = async (e) => {
        e.preventDefault();
        let formData = new FormData()
        let doc = { ...doctor }
        delete doc.services
        delete doc.offers
        delete doc.languages
        delete doc.image

        for (const key in doc) {
            formData.append(key, doc[key]);
        }
        // for (var pair of formData.entries()) {
        //     console.log(pair[0] + ':' + pair[1]);
        // }
        setLoading(true)
        let submitProps;
        if (props.itemId) {
            submitProps = await props.onSubmit(e, doctor?.id, formData);
        } else {
            submitProps = await props.onSubmit(e, formData);
        }
        if (submitProps === "done") {
            navigate(-1);
            setLoading(false)

        } else {
            setLoading(false)

            setErrors(submitProps);
        }
    }

    function removeImg() {
        setImg()
        let cat = { ...doctor }
        cat['icon'] = ''
        setdoctor(cat)
    }
    return (
        doctor &&
        <div>
            <form onSubmit={confirmHandler}>
                <div className="space-y-12">
                    <h2 className="formTitle">{props?.title} </h2>
                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-3">
                            <label htmlFor="name_ar" className="block text-sm font-medium leading-6 text-gray-900">
                                الاسم (العربي)
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, doctor, setdoctor, errors, setErrors)}
                                    required
                                    type="text"
                                    name="name_ar"
                                    id="name_ar"
                                    value={doctor?.name_ar ?? ''}
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.name_ar}</div>
                        </div>

                        <div className="sm:col-span-3">
                            <label htmlFor="name_en" className="block text-sm font-medium leading-6 text-gray-900">
                                الاسم (الانجليزية)
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, doctor, setdoctor, errors, setErrors)}
                                    required
                                    type="text"
                                    name="name_en"
                                    id="name_en"
                                    value={doctor?.name_en ?? ''}
                                    autoComplete="family-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText	'>{errors?.name_en}</div>
                        </div>
                        <div className="col-span-full">
                            <label htmlFor="jobtitle" className="block text-sm font-medium leading-6 text-gray-900">
                                المسمى الوظيفي
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, doctor, setdoctor, errors, setErrors)}
                                    required
                                    type="text"
                                    name="jobtitle"
                                    id="jobtitle"
                                    value={doctor?.jobtitle ?? ''}
                                    autoComplete="family-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText	'>{errors?.jobtitle}</div>
                        </div>
                        <div className="sm:col-span-4">
                            <label htmlFor="slug" className="block text-sm font-medium leading-6 text-gray-900">
                                slug
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, doctor, setdoctor, errors, setErrors)}
                                    required
                                    id="slug"
                                    name="slug"
                                    type="slug"
                                    value={doctor?.slug ?? ''}
                                    className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                            </div>
                            <div className='errorText	'>{errors?.slug}</div>
                        </div>
                        <div className="col-span-full">
                            <label htmlFor="qualifies" className="block text-sm font-medium leading-6 text-gray-900">
                                المؤهلات
                            </label>
                            <div className="mt-2">
                                <textarea
                                    onChange={(e) => onChangeHandler(e, doctor, setdoctor, errors, setErrors)}
                                    id="qualifies"
                                    name="qualifies"
                                    rows={3}
                                    value={doctor?.qualifies}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.qualifies}</div>
                        </div>
                        <div className="col-span-full">
                            <label htmlFor="studies" className="block text-sm font-medium leading-6 text-gray-900">
                                الدراسات
                            </label>
                            <div className="mt-2">
                                <textarea
                                    onChange={(e) => onChangeHandler(e, doctor, setdoctor, errors, setErrors)}
                                    id="studies"
                                    name="studies"
                                    rows={3}
                                    value={doctor?.studies}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.studies}</div>
                        </div>
                        <div className="col-span-full">
                            <label htmlFor="conts" className="block text-sm font-medium leading-6 text-gray-900">
                                المساهمات
                            </label>
                            <div className="mt-2">
                                <textarea
                                    onChange={(e) => onChangeHandler(e, doctor, setdoctor, errors, setErrors)}
                                    id="conts"
                                    name="conts"
                                    rows={3}
                                    value={doctor?.conts}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.conts}</div>
                        </div>

                        <div className="sm:col-span-6">
                            <label htmlFor="rank" className="block text-sm font-medium leading-6 text-gray-900">
                                الترتيب
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, doctor, setdoctor, errors, setErrors)}
                                    id="rank"
                                    name="rank"
                                    type="number"
                                    value={doctor?.rank ?? ''}
                                    className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                            </div>
                            <div className='errorText'>{errors?.rank}</div>

                        </div>
                        <div className="sm:col-span-2">
                            <FormControlLabel control={<Switch name='insurance' checked={doctor?.insurance === 1 ? true : false} onChange={(e) => onChangeHandler(e, doctor, setdoctor, errors, setErrors)} />} label="تامين" />
                            <div className='errorText	'>{errors?.insurance}</div>
                        </div>
                        <div className="sm:col-span-2">
                            <FormControlLabel control={<Switch name='featured' checked={doctor?.featured === 1 ? true : false} onChange={(e) => onChangeHandler(e, doctor, setdoctor, errors, setErrors)} />} label="متميز" />
                            <div className='errorText	'>{errors?.featured}</div>
                        </div>
                        <div className="sm:col-span-2">
                            <FormControlLabel control={<Switch name='status' checked={doctor?.status === 1 ? true : false} onChange={(e) => onChangeHandler(e, doctor, setdoctor, errors, setErrors)} />} label="فعال" />
                            <div className='errorText	'>{errors?.status}</div>
                        </div>

                        <div className="sm:col-span-3">
                            <label htmlFor="cat_id" className="block text-sm font-medium leading-6 text-gray-900">
                                القسم
                            </label>
                            <div className="mt-2">
                                <select
                                    onChange={(e) => onChangeHandler(e, doctor, setdoctor, errors, setErrors)}
                                    required
                                    id="cat_id"
                                    name="cat_id"
                                    value={doctor?.cat_id ?? ''}
                                    className="selectItem block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                >
                                    {categories && categories.map((cat) => {
                                        return (
                                            <option key={cat.id} value={cat.id}>{cat?.name_ar}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            <div className='errorText'>{errors?.cat_id}</div>
                        </div>

                        <div className="sm:col-span-4">
                            <label htmlFor="lang_id" className="block text-sm font-medium leading-6 text-gray-900">
                                اللغات
                            </label>
                            <div className="mt-2">
                                <Select placeholder='اختر' value={langsIds} isMulti options={languages} onChange={(e) => seletChangeHandler(e, 'lang_ids[]', doctor, setdoctor, errors, setErrors, setlangsIds)} />
                            </div>
                            <div className='errorText'>{errors && errors['lang_ids[]']}</div>
                        </div>
                        <div className="sm:col-span-4">
                            <label htmlFor="dept_id" className="block text-sm font-medium leading-6 text-gray-900">
                                العروض
                            </label>
                            <div className="mt-2">
                                <Select placeholder='اختر' value={offersIds} isMulti options={offers} onChange={(e) => seletChangeHandler(e, 'offers_ids[]', doctor, setdoctor, errors, setErrors, setoffersIds)} />
                            </div>
                            <div className='errorText'>{errors && errors['offers_ids[]']}</div>
                        </div>
                        <div className="sm:col-span-4">
                            <label htmlFor="dept_id" className="block text-sm font-medium leading-6 text-gray-900">
                                الخدمات
                            </label>
                            <div className="mt-2">
                                <Select placeholder='اختر' value={servicesIds} isMulti options={services} onChange={(e) => seletChangeHandler(e, 'services_ids[]', doctor, setdoctor, errors, setErrors, setservicesIds)} />
                            </div>
                            <div className='errorText'>{errors && errors['services_ids[]']}</div>

                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor="exp_years" className="block text-sm font-medium leading-6 text-gray-900">
                                سنين الخبرة
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, doctor, setdoctor, errors, setErrors)}
                                    required
                                    type="number"
                                    name="exp_years"
                                    id="exp_years"
                                    value={doctor?.exp_years}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.exp_years}</div>

                        </div>
                        <div className="col-span-full"></div>
                        <div className="sm:col-span-3">
                            <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
                                السعر
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, doctor, setdoctor, errors, setErrors)}
                                    required
                                    type="number"
                                    name="price"
                                    id="price"
                                    value={doctor?.price}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.price}</div>

                        </div>

                        <div className="sm:col-span-3">
                            <label htmlFor="sale_price" className="block text-sm font-medium leading-6 text-gray-900">
                                سعر البيع
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, doctor, setdoctor, errors, setErrors)}
                                    type="number"
                                    name="sale_price"
                                    id="sale_price"
                                    value={doctor?.sale_price}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.sale_price}</div>
                        </div>
                        <div className='sm:col-span-2'>
                            <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                                الصورة
                            </label>
                            {img ?
                                <div className="mt-2 uploadedImg flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                    <XCircleIcon onClick={removeImg} />
                                    <img src={img} alt='' />
                                </div>
                                :
                                <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                    <div className="text-center">
                                        <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                                        <div className="mt-4 flex justify-center text-sm leading-6 text-gray-600">
                                            <label
                                                htmlFor="image"
                                                className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                            >
                                                <span>حمل الصورة</span>
                                                <input id="image" name="image" type="file" className="sr-only"
                                                    onChange={(e) => onChangeHandler(e, doctor, setdoctor, errors, setErrors, setImg)}
                                                />
                                            </label>
                                        </div>
                                        <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
                                    </div>
                                </div>
                            }
                            <div className='errorText	'>{errors && errors['image']}</div>

                        </div>
                        <div className="col-span-full">

                        </div>
                        <h2 className=" col-span-full formSubTitle">وسائل التواصل </h2>
                        <div className='sm:col-span-2'>
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                البريد الالكتروني
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, doctor, setdoctor, errors, setErrors)}
                                    type="text"
                                    value={doctor?.email}
                                    name="email"
                                    id="email"
                                    autoComplete="family-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText	'>{errors?.email}</div>
                        </div>
                        <div className='sm:col-span-2'>
                            <label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900">
                                رقم الهاتف 1 *
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, doctor, setdoctor, errors, setErrors)}
                                    required
                                    value={doctor?.phone}
                                    type="text"
                                    name="phone"
                                    id="phone"
                                    autoComplete="family-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText	'>{errors?.phone}</div>
                        </div>
                        <div className='sm:col-span-2'>
                            <label htmlFor="phone2" className="block text-sm font-medium leading-6 text-gray-900">
                                رقم الهاتف 2
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, doctor, setdoctor, errors, setErrors)}
                                    value={doctor?.phone2}
                                    type="text"
                                    name="phone2"
                                    id="phone2"
                                    autoComplete="family-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText	'>{errors?.phone2}</div>
                        </div>
                        <div className='sm:col-span-2'>
                            <label htmlFor="whatsapp" className="block text-sm font-medium leading-6 text-gray-900">
                                الواتساب
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, doctor, setdoctor, errors, setErrors)}
                                    value={doctor?.whatsapp}
                                    type="text"
                                    name="whatsapp"
                                    id="whatsapp"
                                    autoComplete="family-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText	'>{errors?.whatsapp}</div>
                        </div>
                        <div className='sm:col-span-2'>
                            <label htmlFor="facebook" className="block text-sm font-medium leading-6 text-gray-900">
                                فيسبوك
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, doctor, setdoctor, errors, setErrors)}
                                    value={doctor?.facebook}
                                    type="text"
                                    name="facebook"
                                    id="facebook"
                                    autoComplete="family-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText	'>{errors?.facebook}</div>
                        </div>
                        <div className='sm:col-span-2'>
                            <label htmlFor="twitter" className="block text-sm font-medium leading-6 text-gray-900">
                                تويتر
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, doctor, setdoctor, errors, setErrors)}
                                    value={doctor?.twitter}
                                    type="text"
                                    name="twitter"
                                    id="twitter"
                                    autoComplete="family-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText	'>{errors?.twitter}</div>
                        </div>
                        <div className='sm:col-span-2'>
                            <label htmlFor="linkedin" className="block text-sm font-medium leading-6 text-gray-900">
                                لينكدان
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, doctor, setdoctor, errors, setErrors)}
                                    value={doctor?.linkedin}
                                    type="text"
                                    name="linkedin"
                                    id="linkedin"
                                    autoComplete="family-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText	'>{errors?.linkedin}</div>
                        </div>
                        <div className='sm:col-span-2'>
                            <label htmlFor="instagram" className="block text-sm font-medium leading-6 text-gray-900">
                                انستجرام
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, doctor, setdoctor, errors, setErrors)}
                                    value={doctor?.instagram ?? ''}
                                    type="text"
                                    name="instagram"
                                    id="instagram"
                                    autoComplete="family-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.instagram}</div>
                        </div>

                    </div>

                </div>

                <div className="mt-6 flex items-center justify-end ">
                    {!loading ?
                        <button type="submit" className="submitBtn">
                            حفظ
                        </button>
                        :
                        <button type="button" class="submitBtn flex" disabled>
                            <CgSpinner className='animate-spin' />
                            جاري الحفظ
                        </button>
                    }
                </div>
            </form>
            {props?.itemId  && doctor && doctor.id ?
                <Seo objectType={1} objectId={doctor?.id} />
                : ''}
        </div>
    )
}
