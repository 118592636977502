import React from 'react'
import { RiDeleteBin6Fill, RiEditBoxFill } from 'react-icons/ri'
import { Link } from 'react-router-dom'

export default function BannerCard({ banner, type, setOpen, setDeletedId }) {
    return (
        <div className='blogCard' style={{ background: `linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${ banner?.image_ar})` }}>
          
            <Link to={`/dashboard/home/banners/${banner.id}`}>
                <RiEditBoxFill className='blogIcon blogEditIcon' />
            </Link>
                <div className='blogIcon blogDeleteIcon' onClick={() => { setDeletedId(banner?.id); setOpen(true) }}> <RiDeleteBin6Fill /></div>
        </div>
    )
}
