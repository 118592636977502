import React, { useEffect, useState } from 'react'
import { editPage, getPage } from '../../Api/pages'
import { CgSpinner } from 'react-icons/cg'
import { onChangeHandler } from '../../helpers/handleChange'
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertContentToHTML } from '../../helpers/convertTohtml';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Swal from 'sweetalert2';
import Seo from '../../Components/seo/seo';

export default function TermsAndConditions() {
    let [obj, setobj] = useState()
    let [loading, setloading] = useState()
    let [errors, setErrors] = useState()
    let [editorStateAr, setEditorStateAr] = useState(EditorState.createEmpty())
    let [editorStateEn, setEditorStateEn] = useState(EditorState.createEmpty())

    useEffect(() => {
        getPage('terms-conditions').then((res) => {
            let data = res.data.data
            const arState = ContentState.createFromBlockArray(convertFromHTML(`${data.content_ar}`));
            const arEditorState = EditorState.createWithContent(arState);
            setEditorStateAr(arEditorState)
            const enState = ContentState.createFromBlockArray(convertFromHTML(`${data.content_en}`));
            const enEditorState = EditorState.createWithContent(enState);
            setEditorStateEn(enEditorState)
            data.status = 1
            setobj(res.data.data)
        })
    }, [])

    function onEditorChange(x, type) {
        let objClone = { ...obj }
        if (type === 'ar') {
            objClone['content_ar'] = convertContentToHTML(x)
            setEditorStateAr(x)
        } else {
            objClone['content_en'] = convertContentToHTML(x)
            setEditorStateEn(x)
        }
        setobj(objClone)
    }
    
    function confirmHandler(e) {
        e.preventDefault();
        setloading(true)

        editPage(obj?.id, obj).then((res) => {
            Swal.fire({
                icon: 'success',
                title:  'تم التعديل بنجاح',
                showConfirmButton:false,
                timer: 1500
              })
            setloading(false)
        }).catch((err) => {
            setloading(false)
        })



    }
    return (
        <div>
            <form onSubmit={confirmHandler}>
                <div className="space-y-12">
                    <h2 className="formTitle">الشروط و الاحكام </h2>
                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-3">
                            <label htmlFor="title_ar" className="block text-sm font-medium leading-6 text-gray-900">
                                العنوان (العربي)
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, obj, setobj, errors, setErrors)}
                                    required
                                    type="text"
                                    name="title_ar"
                                    id="title_ar"
                                    value={obj?.title_ar}
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.title_ar}</div>
                        </div>

                        <div className="sm:col-span-3">
                            <label htmlFor="title_en" className="block text-sm font-medium leading-6 text-gray-900">
                                العنوان (الانجليزية)
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, obj, setobj, errors, setErrors)}
                                    required
                                    type="text"
                                    name="title_en"
                                    id="title_en"
                                    value={obj?.title_en}
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.title_en}</div>
                        </div>
                        <div className="col-span-full">
                            <label htmlFor="content_ar" className="block text-sm font-medium leading-6 text-gray-900">
                                الوصف (عربي)
                            </label>
                            <div className="mt-2">
                                <Editor
                                    editorState={editorStateAr}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={(x) => onEditorChange(x, 'ar')}
                                />
                            </div>
                            <div className='errorText'>{errors?.content_ar}</div>
                        </div>
                        <div className="col-span-full">
                            <label htmlFor="content_en" className="block text-sm font-medium leading-6 text-gray-900">
                                الوصف (الانجليزية)
                            </label>
                            <div className="mt-2">
                                <Editor
                                    editorState={editorStateEn}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={(x) => onEditorChange(x, 'en')}
                                />
                            </div>
                            <div className='errorText'>{errors?.content_en}</div>
                        </div>
                        <div className="sm:col-span-3">
                            <FormControlLabel control={<Switch name='status' checked={obj?.status === 1 ? true : false} onChange={(e) => onChangeHandler(e, obj, setobj, errors, setErrors, errors, setErrors)} />} label="فعال" />
                            <div className='errorText	'>{errors?.status}</div>
                        </div>
                    </div>
                </div>
                <div className="mt-6 flex items-center justify-end ">
                    {!loading ?
                        <button type="submit" className="submitBtn">
                            حفظ
                        </button>
                        :
                        <button type="button" class="submitBtn flex" disabled>
                            <CgSpinner className='animate-spin' />
                            جاري الحفظ
                        </button>
                    }
                </div>
            </form>
            {obj?.id ?
                <Seo objectType={7} objectId={obj?.id} />
                : ''} 
        </div>
    )
}
