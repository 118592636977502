import React from 'react'
import BlogForm from './Form'
import { addBlogs } from '../../../Api/blogs';

export default function AddBlog() {
    const onSubmit = async (e, data) => {
        e.preventDefault();
        //to send request
        return await addBlogs(data).then(res => {
            // props.getAll();
            return 'done';          
        }).catch(err =>{
            return err.response.data.data;
        })
    };
  return (
    <div>
        <BlogForm onSubmit={onSubmit}/>
    </div>
  )
}
