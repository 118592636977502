import React from 'react'
import Form from './Form';
import { useParams } from 'react-router-dom';
import { updateDoctor } from '../../../Api/doctors';

export default function EditDoctor() {
    const { id } = useParams();
    const onSubmit = async (e, id,data) => {
        e.preventDefault();
        //to send request
        return await updateDoctor(id,data).then(res => {
            // props.getAll();
            return 'done';          
        }).catch(err =>{
            return err.response.data.data;
        })
    };
  return (
    <div ><Form title='اضافة دكتور' itemId={id} onSubmit={onSubmit}/></div>
  )
}
