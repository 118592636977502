import React, { useEffect, useState } from 'react'
import { onChangeHandler } from '../../../helpers/handleChange'
import { useNavigate } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { getCategories } from '../../../Api/categories';
import { confirmHandler } from '../../../helpers/confirmHandler';
import { getSingleMedia } from '../../../Api/media';
import { XCircleIcon } from '@heroicons/react/24/solid'
import { PhotoIcon } from '@heroicons/react/24/solid'
import { BiSolidVideos } from 'react-icons/bi';
import { CgSpinner } from 'react-icons/cg';

export default function Form(props) {
    let [media, setmedia] = useState({
        name_ar: "",
        name_en: "",
        status: 2,
        rank: "",
        cat_id: '',
        type: ''
    })
    let [errors, setErrors] = useState()
    let [categories, setcategories] = useState()
    let [mediaType, setmediaType] = useState()
    let [img, setImg] = useState()
    let [video, setVideo] = useState()
    let [videoImg, setVideoImg] = useState()

    let navigate = useNavigate();
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        if (props.itemId) {
            getSingleMedia(props.itemId).then((res) => {
                let x = res.data.data
                if (x.type === 'Image') {
                    setImg(x.url)
                    x.type = 1
                    setmediaType(1)
                } else if (x.type === 'Video') {
                    x.type = 2
                    setVideo(x.url)
                    setVideoImg(x.placeholder)
                    setmediaType(2)
                }
                x.url = ''
               delete x.placeholder
                setmedia(x)

            }).catch((err) => {
                console.log(err);
            })
        }
    }, [props.itemId])

    useEffect(() => {
        getCategories().then((res) => {
            setcategories(res.data.data)
        })
    }, [])

    function removeMedia(type) {
        let med = { ...media }
        if (type === 1) {
            setImg('')
            med['url'] = ''

        } else if (type === 2) {
            setVideo('')
            med['url'] = ''
        } else if (type === 3) {
            setVideoImg('')
            med['placeholder'] = ''
        }
        setmedia(med)
    }


    return (
        <div>
            <form onSubmit={(e) => confirmHandler(e, media, props, setErrors, navigate, setLoading)} >
                <div className="space-y-12">
                    <h2 className="formTitle">{props?.title} </h2>
                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-3">
                            <label htmlFor="name_ar" className="block text-sm font-medium leading-6 text-gray-900">
                                الاسم (العربي)
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, media, setmedia, errors, setErrors)}
                                    required
                                    type="text"
                                    name="name_ar"
                                    id="name_ar"
                                    value={media?.name_ar}
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.name_ar}</div>
                        </div>

                        <div className="sm:col-span-3">
                            <label htmlFor="name_en" className="block text-sm font-medium leading-6 text-gray-900">
                                الاسم (الانجليزية)
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, media, setmedia, errors, setErrors)}
                                    required
                                    type="text"
                                    name="name_en"
                                    id="name_en"
                                    value={media?.name_en}
                                    autoComplete="family-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.name_en}</div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor="cat_id" className="block text-sm font-medium leading-6 text-gray-900">
                                القسم
                            </label>
                            <div className="mt-2">
                                <select
                                    onChange={(e) => onChangeHandler(e, media, setmedia, errors, setErrors)}
                                    required
                                    id="cat_id"
                                    name="cat_id"
                                    value={media?.cat_id ?? ''}
                                    className="selectItem block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                >
                                    {categories && categories.map((cat) => {
                                        return (
                                            <option key={cat.id} value={cat.id}>{cat?.name_ar}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            <div className='errorText	'>{errors?.cat_id}</div>
                        </div>
                        <div className='col-span-full'></div>

                        <div className="sm:col-span-3">
                            <label htmlFor="type" className="block text-sm font-medium leading-6 text-gray-900">
                                النوع
                            </label>
                            <div className="mt-2">
                                <select
                                    onChange={(e) => { onChangeHandler(e, media, setmedia, errors, setErrors); setmediaType(e.target.value) }}
                                    required
                                    id="type"
                                    name="type"
                                    value={media?.type ?? ''}
                                    className="selectItem block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                >
                                    <option value={1} >صورة</option>
                                    <option value={2}>فيديو</option>

                                </select>
                            </div>
                            <div className='errorText'>{errors?.type}</div>
                        </div>

                        {mediaType == 1 ?
                            <div className='sm:col-span-2'>
                                <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                                    الصورة
                                </label>
                                {img ?
                                    <div className="mt-2 uploadedImg flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                        <XCircleIcon onClick={() => removeMedia(1)} />
                                        <img src={img} alt='' />
                                    </div>
                                    :
                                    <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                        <div className="text-center">
                                            <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                                            <div className="mt-4 flex justify-center text-sm leading-6 text-gray-600">
                                                <label
                                                    htmlFor="image"
                                                    className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                                >
                                                    <span>حمل الصورة</span>
                                                    <input id="image" name="url" type="file" className="sr-only"
                                                        onChange={(e) => onChangeHandler(e, media, setmedia, errors, setErrors, setImg)}
                                                    />
                                                </label>
                                            </div>
                                            <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
                                        </div>
                                    </div>
                                }
                            </div>
                            : mediaType == 2 ?
                                <div className='sm:col-span-2'>
                                    <div>
                                        <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                                            فيديو
                                        </label>
                                        {video ?
                                            <div className="mt-2 uploadedImg flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                                <XCircleIcon onClick={() => removeMedia(2)} />
                                                <video src={img} controls />
                                            </div>
                                            :
                                            <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                                <div className="text-center">
                                                    <BiSolidVideos className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                                                    <div className="mt-4 flex justify-center text-sm leading-6 text-gray-600">
                                                        <label
                                                            htmlFor="image"
                                                            className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                                        >
                                                            <span>حمل فيديو</span>
                                                            <input id="image" name="url" type="file" className="sr-only"
                                                                onChange={(e) => onChangeHandler(e, media, setmedia, errors, setErrors, setVideo)}
                                                            />
                                                        </label>
                                                    </div>
                                                    <p className="text-xs leading-5 text-gray-600"> MP4 , AVI up to 10MB</p>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div>
                                        <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                                            راية الفيديو
                                        </label>
                                        {videoImg ?
                                            <div className="mt-2 uploadedImg flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                                <XCircleIcon onClick={() => removeMedia(3)} />
                                                <img src={videoImg} alt='' />
                                            </div>
                                            :
                                            <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                                <div className="text-center">
                                                    <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                                                    <div className="mt-4 flex justify-center text-sm leading-6 text-gray-600">
                                                        <label
                                                            htmlFor="placeholder"
                                                            className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                                        >
                                                            <span>حمل الصورة</span>
                                                            <input id="placeholder" name="placeholder" type="file" className="sr-only"
                                                                onChange={(e) => onChangeHandler(e, media, setmedia, errors, setErrors, setVideoImg)}
                                                            />
                                                        </label>
                                                    </div>
                                                    <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
                                                </div>
                                            </div>
                                        }
                            <div className='errorText	'>{errors?.placeholder}</div>

                                    </div>
                            <div className='errorText	'>{errors?.url}</div>

                                </div>
                                : ''}

                        <div className='col-span-full'></div>
                        <div className="sm:col-span-4">
                            <label htmlFor="rank" className="block text-sm font-medium leading-6 text-gray-900">
                                الترتيب
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, media, setmedia, errors, setErrors, errors, setErrors)}
                                    id="rank"
                                    name="rank"
                                    type="number"
                                    required
                                    value={media?.rank}
                                    className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                            </div>
                            <div className='errorText	'>{errors?.rank}</div>

                        </div>

                        <div className="sm:col-span-3">
                            <FormControlLabel control={<Switch name='status' checked={media?.status === 1 ? true : false} onChange={(e) => onChangeHandler(e, media, setmedia, errors, setErrors, errors, setErrors)} />} label="فعال" />
                            <div className='errorText	'>{errors?.status}</div>
                        </div>


                    </div>

                </div>

                <div className="mt-6 flex items-center justify-end ">

                    {!loading ?
                        <button type="submit" className="submitBtn">
                            حفظ
                        </button>
                        :
                        <button type="button" class="submitBtn flex" disabled>
                            <CgSpinner className='animate-spin' />
                            جاري الحفظ
                        </button>
                    }
                </div>
            </form>
        </div>
    )
}
