import * as React from 'react';
import ListTable from '../../Components/listPages/list';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { getAppointments } from '../../Api/appointments';

const columns = [
  { id: 'day', label: 'اليوم' },
  { id: 'period', label: 'الفترة' },
  { id: 'start_time', label: 'بداية الموعد' },
  { id: 'end_time', label: 'نهاية الموعد' },
  { id: 'slug' },
  { id: 'id' }

];

export default function AppointmentsList() {
  let [itemLength] = React.useState([])
  let editLink = '/dashboard/appointments'
  let deleteLink = 'appointments'
  let [doctors, setDoctors] = React.useState([])

  function getAppointmentsList(page) {
    getAppointments(page).then((res) => {
      let items = res.data.data
      setDoctors(items)
      
    }).catch((err) => {
      console.log(err);
    })
  }
  React.useEffect(() => {
    getAppointmentsList(1)
    //eslint-disable-next-line
  }, [])

  return (
    <div className='appointmentsPage'>
      <div className='flex justify-between	'>
        <h1 className='pagesTitle'>مواعيد الدكاترة</h1>
        <Link to='/dashboard/Appointments/add' className='addBtn'>
          <span>اضافة ميعاد</span>
          <PlusCircleIcon className='navBtn' />
        </Link>
      </div>
      <div className='tableContainer'>
        {doctors && doctors?.length ? doctors?.map((doctor) => {
          return (
            <React.Fragment key={doctor?.doctor_id} >
              <div className='doctorName'>{doctor?.doctor_name}</div>
              {doctor?.appointments && doctor?.appointments.length > 0 ?
              <div>
                <ListTable rows={doctor?.appointments} columns={columns} editLink={editLink} getItems={getAppointmentsList} itemLength={itemLength} deleteLink={deleteLink} />
              </div>
              :""}
            </React.Fragment>
          )
        }):''}

      </div>
    </div>
  );
}
