import { https } from './https';
import { admin_url } from './../Configuration/config';

//get offers list
export async function getOffers(page){
    return await https.get(`${admin_url}/offers?page=${page}`);
}
//get single offers 
export async function getSingleOffer(id){
    return await https.get(`${admin_url}/offers/${id}`);
}
//add new offers 
export async function addOffer(data){
    return await https.post(`${admin_url}/offers`,data);
}
//add new offers 
export async function updateOffer(id,data){
    return await https.post(`${admin_url}/offers/${id}?_method=PUT`,data);
}