import React, { useState } from 'react'
import BlogNewsForm from '../../../Components/blogs/blogForm'
import { useNavigate } from 'react-router-dom';
import { confirmHandler } from '../../../helpers/confirmHandler';

export default function NewsForm(props) {

    let [blog, setblog] = useState({
        title_ar: '',
        title_en: '',
        desc_ar: '',
        desc_en: '',
        slug: '',
        image_2: '',
        image_3: '',
        type: 1,
        status: 1,
        featured: 0,
        rank: 2
    })

    let [errors, setErrors] = useState()
    let [loading, setloading] = useState()
    let navigate = useNavigate();

    return (
        <div>
            <BlogNewsForm  blog={blog} setblog={setblog}  itemId={props.itemId} title='اضافة خبر' loading={loading} errors={errors} setErrors={setErrors} confirmHandler={(e) => confirmHandler(e, blog, props, setErrors, navigate,setloading)} />
        </div>
    )
}
