import React, { useState } from 'react'
import BlogNewsForm from '../../../Components/blogs/blogForm'
import { useNavigate } from 'react-router-dom';
import { confirmHandler } from '../../../helpers/confirmHandler';

export default function BlogForm(props) {
    let [blog, setblog] = useState({
        title_ar: '',
        title_en: '',
        desc_ar: '',
        desc_en: '',
        slug: '',
        type: 2,
        status: 1,
        featured: 0,
        rank: 2,
        'images[]':[]
    })
    let [errors, setErrors] = useState()
    let [loading, setloading] = useState()
    let navigate = useNavigate();

    // onsubmit

    return (
        <div>
            <BlogNewsForm blog={blog} itemId={props.itemId} setblog={setblog} title='اضافة مقالة' errors={errors} loading={loading} setErrors={setErrors} confirmHandler={(e) => confirmHandler(e, blog, props, setErrors, navigate,setloading)} />
        </div>
    )
}
