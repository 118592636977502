import React from 'react'
import { BsCamera, BsCameraVideo } from 'react-icons/bs'
import { RiDeleteBin6Fill, RiEditBoxFill } from 'react-icons/ri'
import { Link } from 'react-router-dom'
export default function MediaCard({ media,deleteMedia ,setOpen,setDeletedId}) {
    return (
        <div className='blogCard' style={{ background: `linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${media?.url})` }}>

            <Link className='blogIcon blogEditIcon' to={`/dashboard/media/${media.id}`}>
                <RiEditBoxFill  />
            </Link>
            <div className='blogIcon blogDeleteIcon' onClick={()=>{setDeletedId(media?.id);setOpen(true)}}> <RiDeleteBin6Fill/></div>

            {media?.type === 'Image' ?
                <div className='imgTag'>
                    <BsCamera />
                    <span>صورة</span>
                </div>
                : <div className='imgTag'>
                    <BsCameraVideo />
                    <span>فيديو</span>
                </div>
            }
        </div>
    )
}
