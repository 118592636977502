import { https } from './https';
import { admin_url } from './../Configuration/config';

//get appointments list
export async function getAppointments(){
    return await https.get(`${admin_url}/appointments`);
}
//get appointments days
export async function getAppointmentsDays(){
    return await https.get(`${admin_url}/appointments/days`);
}
//get appointments periods
export async function getAppointmentsPeriods(){
    return await https.get(`${admin_url}/appointments/periods`);
}
//get single appointment
export async function getSingleAppointments(slug){
    return await https.get(`${admin_url}/appointments/${slug}`);
}
//add new appointments 
export async function addAppointments(data){
    return await https.post(`${admin_url}/appointments`,data);
}
//update appointments 
export async function updateAppointments(id,data){
    return await https.post(`${admin_url}/appointments/${id}?_method=PUT`,data);
}