import { https } from './https';
import { admin_url } from './../Configuration/config';

//get categories list
export async function getCategories(page){
    return await https.get(`${admin_url}/categories?page=${page}`);
}
//get single category
export async function getSingleCategory(slug){
    return await https.get(`${admin_url}/categories/${slug}`);
}
//add new categories 
export async function addCategory(data){
    return await https.post(`${admin_url}/categories`,data);
}
//edit categories 
export async function editCategory(id,data){
    return await https.post(`${admin_url}/categories/${id}?_method=PUT`,data);
}
//get single category
export async function getCategoryTypes(){
    return await https.get(`${admin_url}/categories/types`);
}