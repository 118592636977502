import React, { useEffect, useState } from 'react'
import { onChangeHandler } from '../../../helpers/handleChange'
import { useNavigate } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { getCategories } from '../../../Api/categories';
import { confirmHandler } from '../../../helpers/confirmHandler';
import { getSingleVisit } from '../../../Api/visits';
import { CgSpinner } from 'react-icons/cg';

export default function Form(props) {
    let [visit, setvisit] = useState({
        name_ar: "",
        name_en: "",
        desc_ar: "",
        desc_en: "",
        price: '',
        sale_price: '',
        slug: "",
        status: 2,
        rank: "",
        featured: 0,
        cat_id: ''
    })
    let [errors, setErrors] = useState()
    let [categories, setcategories] = useState()
    let [loading, setloading] = useState(false)

    let navigate = useNavigate();
    useEffect(() => {
        if (props.itemId) {
            getSingleVisit(props.itemId).then((res) => {
                let x = res.data.data
                setvisit(x)
            }).catch((err) => {
                console.log(err);
            })
        }
    }, [props.itemId])

    useEffect(() => {
        getCategories().then((res) => {
            setcategories(res.data.data)
        })
    }, [])




    return (
        <div>
            <form onSubmit={(e) => confirmHandler(e, visit, props, setErrors, navigate,setloading)} >
                <div className="space-y-12">
                    <h2 className="formTitle">{props?.title} </h2>
                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-3">
                            <label htmlFor="name_ar" className="block text-sm font-medium leading-6 text-gray-900">
                                الاسم (العربي)
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, visit, setvisit, errors, setErrors)}
                                    required
                                    type="text"
                                    name="name_ar"
                                    id="name_ar"
                                    value={visit?.name_ar}
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.name_ar}</div>
                        </div>

                        <div className="sm:col-span-3">
                            <label htmlFor="name_en" className="block text-sm font-medium leading-6 text-gray-900">
                                الاسم (الانجليزية)
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, visit, setvisit, errors, setErrors)}
                                    required
                                    type="text"
                                    name="name_en"
                                    id="name_en"
                                    value={visit?.name_en}
                                    autoComplete="family-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.name_en}</div>
                        </div>
                        <div className="col-span-full">
                            <label htmlFor="desc_ar" className="block text-sm font-medium leading-6 text-gray-900">
                                الوصف (عربي)
                            </label>
                            <div className="mt-2">
                                <textarea
                                    onChange={(e) => onChangeHandler(e, visit, setvisit, errors, setErrors)}
                                    id="desc_ar"
                                    name="desc_ar"
                                    rows={3}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    value={visit?.desc_ar}
                                />
                            </div>
                            <div className='errorText'>{errors?.desc_ar}</div>
                        </div>
                        <div className="col-span-full">
                            <label htmlFor="desc_en" className="block text-sm font-medium leading-6 text-gray-900">
                                الوصف (الانجليزية)
                            </label>
                            <div className="mt-2">
                                <textarea
                                    onChange={(e) => onChangeHandler(e, visit, setvisit, errors, setErrors)}
                                    id="desc_en"
                                    name="desc_en"
                                    rows={3}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    value={visit?.desc_en}
                                />
                            </div>
                            <div className='errorText'>{errors?.desc_en}</div>

                        </div>

                        <div className="sm:col-span-3">
                            <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
                                السعر
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, visit, setvisit, errors, setErrors)}
                                    required
                                    type="number"
                                    name="price"
                                    id="price"
                                    autoComplete="given-name"
                                    value={visit?.price}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.price}</div>

                        </div>

                        <div className="sm:col-span-3">
                            <label htmlFor="sale_price" className="block text-sm font-medium leading-6 text-gray-900">
                                سعر البيع
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, visit, setvisit, errors, setErrors)}
                                    type="number"
                                    name="sale_price"
                                    id="sale_price"
                                    value={visit?.sale_price}
                                    autoComplete="family-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.sale_price}</div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor="cat_id" className="block text-sm font-medium leading-6 text-gray-900">
                                القسم
                            </label>
                            <div className="mt-2">
                                <select
                                    onChange={(e) => onChangeHandler(e, visit, setvisit, errors, setErrors)}
                                    required
                                    id="cat_id"
                                    name="cat_id"
                                    value={visit?.cat_id ?? ''}
                                    className="selectItem block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                >
                                    {categories && categories.map((cat) => {
                                        return (
                                            <option key={cat.id} value={cat.id}>{cat?.name_ar}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            <div className='errorText	'>{errors?.cat_id}</div>
                        </div>

                        <div className="sm:col-span-4">
                            <label htmlFor="rank" className="block text-sm font-medium leading-6 text-gray-900">
                                الترتيب
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, visit, setvisit, errors, setErrors, errors, setErrors)}
                                    id="rank"
                                    name="rank"
                                    type="number"
                                    required
                                    value={visit?.rank}
                                    className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                            </div>
                            <div className='errorText	'>{errors?.rank}</div>

                        </div>
                        <div className="sm:col-span-3">
                            <FormControlLabel control={<Switch name='featured' checked={visit?.featured === 1 ? true : false} onChange={(e) => onChangeHandler(e, visit, setvisit, errors, setErrors, errors, setErrors)} />} label="متميز" />
                            <div className='errorText	'>{errors?.featured}</div>
                        </div>
                        <div className="sm:col-span-3">
                            <FormControlLabel control={<Switch name='status' checked={visit?.status === 1 ? true : false} onChange={(e) => onChangeHandler(e, visit, setvisit, errors, setErrors, errors, setErrors)} />} label="فعال" />
                            <div className='errorText	'>{errors?.status}</div>
                        </div>

                        <div className="col-span-full">

                        </div>



                    </div>

                </div>
                <div className="mt-6 flex items-center justify-end ">
                    {!loading ?
                        <button type="submit" className="submitBtn">
                            حفظ
                        </button>
                        :
                        <button type="button" class="submitBtn flex" disabled>
                            <CgSpinner className='animate-spin' />
                            جاري الحفظ
                        </button>
                    }
                </div>
            </form>
        </div>
    )
}
