import { http,https } from './https';
import { api_url,admin_url } from './../Configuration/config';

//login
export async function adminLogin(data){
    return await http.post(`${api_url}/login`, data);
}
//logout
export async function adminLogout(data){
    return await http.post(`${api_url}/logout`, data);
}
//Profile
export async function adminProfile(){
    return await https.get(`${admin_url}/user-get`);
}
//Profile
export async function updateProfile(data){
    return await https.post(`${admin_url}/user-update?_method=PUT`,data);
}