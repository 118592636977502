import React from 'react'
import Form from './Form'
import { addCategory } from '../../../Api/categories';

export default function AddCategory() {
    const onSubmit = async (e, data) => {
        e.preventDefault();
        //to send request
        return await addCategory(data).then(res => {
            return 'done';          
        }).catch(err =>{
            return err.response.data.data;
        })
    };
  return (
    <div ><Form title='اضافة قسم' onSubmit={onSubmit}/></div>
  )
}
