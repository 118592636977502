import React, { useEffect, useState } from 'react'
import { PhotoIcon } from '@heroicons/react/24/solid'
import { onChangeHandler } from '../../../helpers/handleChange'
import { XCircleIcon } from '@heroicons/react/24/solid'
import { useNavigate } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { getDepartments } from '../../../Api/departments';
import { getDoctors } from '../../../Api/doctors';
import Select from 'react-select';
import { seletChangeHandler } from '../../../helpers/selectChange';
import { getOffers } from '../../../Api/offers';
import { getServices } from '../../../Api/services';
import { getSingleClinic } from '../../../Api/clinics';
import { confirmHandler } from '../../../helpers/confirmHandler';
import { CgSpinner } from 'react-icons/cg';
import Seo from '../../../Components/seo/seo';
export default function Form(props) {
    let [clinic, setclinic] = useState({
        name_ar: "",
        name_en: "",
        desc_ar: "",
        desc_en: "",
        slug: "",
        status: 0,
        featured: 0,
        rank: "",
        type: "",
        image: "",
        'doctors_ids[]': [],
        'offers_ids[]': [],
        'services_ids[]': [],
        dept_id: ''
    })
    let [errors, setErrors] = useState({})
    let [img, setImg] = useState()
    let navigate = useNavigate();
  
    let [departments, setDepartments] = useState()
    let [doctors, setdoctors] = useState()
    let [offers, setoffers] = useState()
    let [services, setServices] = useState()

    let [offersIds, setoffersIds] = useState([])
    let [servicesIds, setservicesIds] = useState([])
    let [doctorsIds, setdoctorsIds] = useState([])
    let [loading, setloading] = useState(false)

    
    useEffect(() => {
        if (props.itemId) {
            getSingleClinic(props.itemId).then((res) => {
                let x = res.data.data
                setImg(x.image)
                x.image = ''
                // offers for select 
                let offer = []
                let offer_ids = []
                for (let i = 0; i < x?.offers?.length; i++) {
                    offer.push({
                        value: x.offers[i].id,
                        label: x.offers[i].name_ar
                    })
                    offer_ids.push(x.offers[i].id)
                }
                x['offers_ids[]'] = offer_ids
                setoffersIds(offer);
                // services for select 
                let services = []
                let services_ids = []
                for (let i = 0; i < x?.services?.length; i++) {
                    services.push({
                        value: x.services[i].id,
                        label: x.services[i].name_ar
                    })
                    services_ids.push(x.services[i].id)
                }
                x['services_ids[]'] = services_ids
                setservicesIds(services);
                // services for select 
                let doctors = []
                let doctors_ids = []
                for (let i = 0; i < x?.languages?.length; i++) {
                    doctors.push({
                        value: x.languages[i].id,
                        label: x.languages[i].name_ar
                    })
                    doctors_ids.push(x.languages[i].id)
                }
                x['doctors_ids[]'] = doctors_ids
                setdoctorsIds(doctors);
                setclinic(x)
            }).catch((err) => {
                console.log(err);
            })
        }
    }, [props.itemId])


    useEffect(() => {
        getDepartments().then((res) => {
            setDepartments(res.data.data)
        })
        getDoctors().then((res) => {
            let data = res.data.data
            let options = []
            for (let i = 0; i < data.length; i++) {
                options.push({
                    value: data[i].id,
                    label: data[i].name_ar
                })
            }
            setdoctors(options);
        })

        getOffers().then((res) => {
            let data = res.data.data
            let options = []
            for (let i = 0; i < data.length; i++) {
                options.push({
                    value: data[i].id,
                    label: data[i].name_ar
                })
            }
            setoffers(options);
        })
        getServices().then((res) => {
            let data = res.data.data
            let options = []
            for (let i = 0; i < data.length; i++) {
                options.push({
                    value: data[i].id,
                    label: data[i].name_ar
                })
            }
            setServices(options);
        })
    }, [])

    function removeImg() {
        setImg()
        let cat = { ...clinic }
        cat['icon'] = ''
        setclinic(cat)
    }
    return (
        <div>
            <form onSubmit={(e)=>confirmHandler(e, clinic, props, setErrors, navigate,setloading)}>
                <div className="space-y-12">
                    <h2 className="formTitle">{props?.title} </h2>
                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-3">
                            <label htmlFor="name_ar" className="block text-sm font-medium leading-6 text-gray-900">
                                الاسم (العربي)
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, clinic, setclinic, errors, setErrors)}
                                    required
                                    type="text"
                                    name="name_ar"
                                    id="name_ar"
                                    value={clinic?.name_ar}
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText'>{errors?.name_ar}</div>
                        </div>

                        <div className="sm:col-span-3">
                            <label htmlFor="name_en" className="block text-sm font-medium leading-6 text-gray-900">
                                الاسم (الانجليزية)
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, clinic, setclinic, errors, setErrors)}
                                    required
                                    type="text"
                                    name="name_en"
                                    id="name_en"
                                    value={clinic?.name_en}
                                    autoComplete="family-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div className='errorText	'>{errors?.name_en}</div>
                        </div>
                        <div className="col-span-full">
                            <label htmlFor="desc_ar" className="block text-sm font-medium leading-6 text-gray-900">
                                الوصف (عربي)
                            </label>
                            <div className="mt-2">
                                <textarea
                                    onChange={(e) => onChangeHandler(e, clinic, setclinic, errors, setErrors)}
                                    id="desc_ar"
                                    name="desc_ar"
                                    rows={3}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    value={clinic?.desc_ar}
                                />
                            </div>
                            <div className='errorText	'>{errors?.desc_ar}</div>

                        </div>
                        <div className="col-span-full">
                            <label htmlFor="desc_en" className="block text-sm font-medium leading-6 text-gray-900">
                                الوصف (الانجليزية)
                            </label>
                            <div className="mt-2">
                                <textarea
                                    onChange={(e) => onChangeHandler(e, clinic, setclinic, errors, setErrors)}
                                    id="desc_en"
                                    name="desc_en"
                                    rows={3}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    value={clinic?.desc_en}
                                />
                            </div>
                            <div className='errorText	'>{errors?.name_en}</div>
                        </div>

                        <div className="sm:col-span-4">
                            <label htmlFor="slug" className="block text-sm font-medium leading-6 text-gray-900">
                                slug
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, clinic, setclinic, errors, setErrors)}
                                    required
                                    id="slug"
                                    name="slug"
                                    value={clinic?.slug}
                                    type="slug"
                                    className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                            </div>
                            <div className='errorText	'>{errors?.slug}</div>

                        </div>
                        <div className="sm:col-span-4">
                            <label htmlFor="rank" className="block text-sm font-medium leading-6 text-gray-900">
                                الترتيب
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => onChangeHandler(e, clinic, setclinic, errors, setErrors)}
                                    id="rank"
                                    name="rank"
                                    type="number"
                                    value={clinic?.rank}
                                    className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                            </div>
                            <div className='errorText	'>{errors?.rank}</div>

                        </div>
                        <div className="sm:col-span-3">
                            <FormControlLabel control={<Switch name='featured'  checked={clinic?.featured === 1 ? true : false} onChange={(e) => onChangeHandler(e, clinic, setclinic, errors, setErrors)} />} label="متميز" />
                            <div className='errorText	'>{errors?.featured}</div>
                        </div>
                        <div className="sm:col-span-3">
                            <FormControlLabel control={<Switch name='status'  checked={clinic?.status === 1 ? true : false} onChange={(e) => onChangeHandler(e, clinic, setclinic, errors, setErrors)} />} label="فعال" />
                            <div className='errorText	'>{errors?.status}</div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor="type" className="block text-sm font-medium leading-6 text-gray-900">
                                النوع
                            </label>
                            <div className="mt-2">
                                <select
                                    onChange={(e) => onChangeHandler(e, clinic, setclinic, errors, setErrors)}
                                    id="type"
                                    name="type"
                                    className=" selectItem block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                >
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                </select>
                            </div>
                            <div className='errorText	'>{errors?.type}</div>

                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor="dept_id" className="block text-sm font-medium leading-6 text-gray-900">
                                القسم
                            </label>
                            <div className="mt-2">
                                <select
                                    onChange={(e) => onChangeHandler(e, clinic, setclinic, errors, setErrors)}
                                    required
                                    id="dept_id"
                                    name="dept_id"
                                    value={clinic?.dept_id ?? ''}
                                    className="selectItem block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                >
                                    {departments && departments.map((dept) => {
                                        return (
                                            <option key={dept.id} value={dept.id}>{dept?.name_ar}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            <div className='errorText	'>{errors?.dept_id}</div>
                        </div>
                        
                        <div className="sm:col-span-4">
                            <label htmlFor="dept_id" className="block text-sm font-medium leading-6 text-gray-900">
                                الدكاترة
                            </label>
                            <div className="mt-2">
                                <Select placeholder='اختر' value={doctorsIds} isMulti options={doctors} onChange={(e) => seletChangeHandler(e, 'doctors_ids[]', clinic, setclinic, errors, setErrors,setdoctorsIds)} />
                            </div>
                            <div className='errorText	'>{errors && errors['doctors_ids[]']}</div>
                        </div>
                        <div className="sm:col-span-4">
                            <label htmlFor="dept_id" className="block text-sm font-medium leading-6 text-gray-900">
                                العروض
                            </label>
                            <div className="mt-2">
                                <Select placeholder='اختر' value={offersIds} isMulti options={offers} onChange={(e) => seletChangeHandler(e, 'offers_ids[]', clinic, setclinic, errors, setErrors,setoffersIds)} />
                            </div>
                            <div className='errorText'>{errors && errors['offers_ids[]']}</div>

                        </div>
                        <div className="sm:col-span-4">
                            <label htmlFor="dept_id" className="block text-sm font-medium leading-6 text-gray-900">
                                الخدمات
                            </label>
                            <div className="mt-2">
                                <Select placeholder='اختر' value={servicesIds} isMulti options={services} onChange={(e) => seletChangeHandler(e, 'services_ids[]', clinic, setclinic, errors, setErrors,setservicesIds)} />
                            </div>
                            <div className='errorText'>{errors && errors['services_ids[]']}</div>

                        </div>
                        <div className="col-span-full"></div>

                        <div className='sm:col-span-2'>
                            <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                                الصورة
                            </label>
                            {img ?
                                <div className="mt-2 uploadedImg flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                    <XCircleIcon onClick={removeImg} />
                                    <img src={img} alt='' />
                                </div>
                                :
                                <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                    <div className="text-center">
                                        <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                                        <div className="mt-4 flex justify-center text-sm leading-6 text-gray-600">
                                            <label
                                                htmlFor="image"
                                                className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                            >
                                                <span>حمل الصورة</span>
                                                <input id="image" name="image" type="file" className="sr-only"
                                                    onChange={(e) => onChangeHandler(e, clinic, setclinic, errors, setErrors, setImg)}
                                                />
                                            </label>
                                        </div>
                                        <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
                                    </div>
                                </div>
                            }
                            <div className='errorText	'>{errors && errors['image']}</div>

                        </div>
                    </div>

                </div>

                <div className="mt-6 flex items-center justify-end ">
                    {!loading ?
                        <button type="submit" className="submitBtn">
                            حفظ
                        </button>
                        :
                        <button type="button" class="submitBtn flex" disabled>
                            <CgSpinner className='animate-spin' />
                            جاري الحفظ
                        </button>
                    }
                </div>
            </form>
            {props?.itemId  && clinic && clinic.id ?
                <Seo objectType={2} objectId={clinic?.id} />
                : ''}
        </div>
    )
}
