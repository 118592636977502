export function onChangeHandler(e, state, setState,errors,setErrors, setImg) {
    let stateClone = { ...state }
    if (e.target.type === 'file') {
        setImg(URL.createObjectURL(e.target.files[0]))
        stateClone[e.target.name] = e.target.files[0];

    } else if (e.target.type === 'checkbox') {
        if (e.target.checked) {
            stateClone[e.target.name] = 1
        } else {
            stateClone[e.target.name] = 2
        }

    } else {
        stateClone[e.target.name] = e.target.value;
    }
    setState(stateClone)
    console.log(stateClone);
    let err ={...errors}
    err[e.target.name]=''
    setErrors(err)

}