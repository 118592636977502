import React from 'react'
import Form from './Form';
import { addPartner } from '../../../Api/partners';

export default function AddNewPartner() {
    const onSubmit = async (e, data) => {
        e.preventDefault();
        //to send request
        return await addPartner(data).then(res => {
            return 'done';          
        }).catch(err =>{
            return err.response.data.data;
        })
    };
  return (
    <div ><Form title='اضافة شريك' onSubmit={onSubmit}/></div>
  )
}
