import React from 'react'
import Form from './Form'
import { addClinic } from '../../../Api/clinics';

export default function AddClinic() {
    const onSubmit = async (e, data) => {
        e.preventDefault();
        //to send request
        return await addClinic(data).then(res => {
            // props.getAll();
            return 'done';          
        }).catch(err =>{
            return err.response.data.data;
        })
    };
  return (
    <div ><Form title='اضافة عيادة' onSubmit={onSubmit}/></div>
  )
}
